import { getMergedState } from '@Common/State'

const sessionState = {
  notesForm: '',
  cruiseForm: [],
  flightForm: [],
  hotelForm: [],
  trainForm: [],
  serviceForm: [],
  carForm: [],
  moneyForm: [],
  tourSelected: {}
}
const storedState = {
}

const state = getMergedState(sessionState, storedState)

export default state
