import React from 'react'
import { Button } from '@Common/Components'

const StatusIcon = ({
  document,
  synchronizeDocumentAtl,
  uploadSinglDocOnAwsHandligStatus,
  deleteDocument
}) => {
  const { status, atl } = document

  const firstOnClick = atl
    ? synchronizeDocumentAtl(document, +!status)
    : uploadSinglDocOnAwsHandligStatus(document, +!status)

  const binClick = async () => {
    if (status) await uploadSinglDocOnAwsHandligStatus(document, 0)()
    deleteDocument(document)()
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: 220,
        justifyContent: atl ? 'center' : 'space-between'
      }}
    >
      <Button
        iconColor={status ? '#d02f24' : ''}
        icon={status ? 'close' : 'up-round'}
        rich
        round
        onClick={firstOnClick}
        tooltip={status ? 'Desincronizza documento' : 'Sincronizza documento'}
      />
      {!atl && <Button icon='bin' rich round onClick={binClick} tooltip='Cancella documento' />}
    </div>
  )
}

export default StatusIcon
