import React from 'react'
import AllMessages from './Pages/AllMessages'
import { Navigate, Route } from 'react-router-dom'
import Conversations from './Pages/Conversations'
import NewMessage from './Pages/NewMessage'
import Broadcast from './Pages/Broadcast'

export const Messages = (
  <>
    <Route path='/messages/*' element={<Navigate to='/messages/chat' />} />
    <Route path='/messages/:selectedConversation' element={<Conversations />} />
    <Route path='/messages/all' element={<AllMessages />} />
    <Route path='/messages/new' element={<NewMessage />} />
    <Route path='/messages/broadcast' element={<Broadcast />} />
  </>

)
