import React, { useState, useEffect } from 'react'
import { Tabs, TabHeader, Spinner } from '@Common/Components'
import ListAtl from '../Components/ListAtl'
import { getUrlParams } from '@Common/Services'

const TABS = [
  { icon: 'sincronize-siap', title: 'PRATICHE', content: <ListAtl pra label='pratica' /> },
  { icon: 'ticket', title: 'BIGLIETTI', content: <ListAtl bgt label='biglietto' /> },
  { icon: 'paperwork-2', title: 'VOUCHER', content: <ListAtl vou label='voucher' /> }
]
const TabsAtl = (props) => {
  const [loading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    const tab = parseInt(getUrlParams('tab'), 10)
    if (tab && selectedTab !== tab) {
      setSelectedTab(tab)
    }
  }, [selectedTab, window.location.href])

  // RENDER
  return loading
    ? <Spinner />
    : (
      <Tabs
        onTabSelection={setSelectedTab}
        currentTab={selectedTab}
      >
        {TABS?.filter(({ disabled }) => !disabled).map((tab, index) => (
          <TabHeader
            title={tab?.title}
            key={index}
            index={index}
            icon={tab?.icon}
          >
            {tab?.content}
          </TabHeader>
        ))}
      </Tabs>
      )
}

export default TabsAtl
