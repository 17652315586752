import React from 'react'
import { Tabs, TabHeader, Spinner } from '@Common/Components'
import { getUrlParams, useHandleNavigate } from '@Common/Services'
import { useCInfiniteQuery } from '../Services'
import { TabTours } from '../Components'

const TABS = (all, published, drafted) => [
  { icon: 'paperwork-2', title: 'VIAGGI', content: <TabTours tours={all} /> },
  { icon: 'import', title: 'BOZZE SALVATE', content: <TabTours tours={drafted} /> },
  { icon: 'sincronize-phone', title: 'SINCRONIZZATI', content: <TabTours tours={published} /> }
]

const ToursList = () => {
  const navigate = useHandleNavigate()
  const { data: tours = [], isSuccess: isSuccessTours } = useCInfiniteQuery('infiniteTours')
  const allTours = tours.filter((tour) => tour._id.includes('@APP-'))
  const publishedTours = allTours.filter((ele) => ele.status === 1)
  const draftedTours = allTours.filter((ele) => ele.status === 0)
  const tab = getUrlParams('tab')
  const currentTab = parseInt(tab) || 0

  if (!isSuccessTours) return <Spinner />
  return (

    <Tabs
      onTabSelection={(e) => navigate('/tours/list?tab=' + e)}
      currentTab={currentTab}
    >
      {TABS(allTours, publishedTours, draftedTours)?.filter(({ disabled }) => !disabled).map((tab, index) => (
        <TabHeader
          title={tab?.title}
          key={index}
          index={index}
          icon={tab?.icon}
        >
          {tab?.content}
        </TabHeader>
      ))}
    </Tabs>

  )
}
export default ToursList
