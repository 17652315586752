import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import { useWindowSize } from 'react-use'

import { Flex, Button, Modal } from '@Common/Components'
import { FC, useHandleNavigate } from '@Common/Services'
import { showGrowl, getUpdatedLogs } from '../../Functions'
import { useCQuery, invalidateQuery } from '../../Services'

export default function Table ({ offers, selectedOffers, setSelectedOffers, showDeleteAll }) {
  const { width: screenWidth } = useWindowSize()

  const { data: user = {} } = useCQuery('user')

  const navigate = useHandleNavigate()

  const onClickEdit = (offer) => (e) => {
    e.stopPropagation()
    setSelectedOffers([offer])
    const foundOffer = offers?.find((o) => o._id === offer?._id)
    if (!foundOffer?._id) showGrowl('error', 'Attenzione', 'Si è verificato un errore. Ricarca la pagina e riprova.')
    else navigate('/offers/' + foundOffer?._id)
  }

  const onClickDelete = (offer) => (ev) => {
    ev.stopPropagation()
    setSelectedOffers([offer])
    showDeleteAll?.current?.show()
  }

  const deleteSelected = async () => {
    try {
      for (const o of selectedOffers) {
        await FC.service('offers').patch(o._id, { deleted: true, logs: getUpdatedLogs(user, 'Ha cancellato l\'offerta', o?.logs) })
      }
      invalidateQuery('offers')
      setSelectedOffers([])
    } catch (error) {
      showGrowl('error', 'Attenzione, errore, impossibile eliminare offerta/e selezionate', 'Riprovare')
      setSelectedOffers([])
    }
  }

  const columnProps = (field, header, filterPlaceholder) => {
    return {
      ...(field ? { field } : {}),
      header,
      filterPlaceholder,
      filter: true,
      showClearButton: false,
      showFilterMenu: false,
      filterMatchMode: 'contains',
      sortable: true,
      style: { textAlign: 'center', border: 'none' }
    }
  }

  return (
    <>
      <Modal
        ref={showDeleteAll}
        header={`Sicuro di voler cancellare l${selectedOffers.length === 1 ? '\'' : 'e '}offert${selectedOffers.length === 1 ? 'a' : 'e'
          } selezionat${selectedOffers.length === 1 ? 'a' : 'e'}?`}
        action={deleteSelected}
      />

      <DataTable
        value={offers}
        paginator
        rows={10}
        responsiveLayout='scroll'
        emptyMessage='Nessuna pratica da visualizzare'
        className='TableList'
        selection={selectedOffers}
        onSelectionChange={(e) => setSelectedOffers(e.value)}
        filterDisplay='row'
      >
        <Column selectionMode='multiple' style={{ width: '5px', height: '5px', marginTop: '10px' }} />
        <Column {...columnProps('title', 'Titolo', '🔍   Titolo')} />
        <Column {...columnProps('price', 'Prezzo', '🔍   Prezzo')} />
        <Column body={(offer) => moment(offer.publish).format('DD/MM/YYYY')} {...columnProps('publish', 'Pubblicata', '🔍   Pubblicata')} />
        <Column body={(offer) => moment(offer.deadline).format('DD/MM/YYYY')} {...columnProps('deadline', 'Scadenza', '🔍   Scadenza')} />
        <Column {...columnProps('operator', screenWidth > 1138 ? 'Modificato da' : 'Modificato', '🔍   modificato da')} />
        <Column
          body={(offer) => (
            <Flex row style={{ width: 140 }}>
              <Button
                icon='edit'
                round
                tooltip='Modifica'
                onClick={onClickEdit(offer)}
                style={{ height: 30, marginRight: 5 }}
              />
              <Button
                icon='bin'
                round
                tooltip='Elimina'
                onClick={onClickDelete(offer)}
                style={{ height: 30, marginLeft: 5 }}
              />
            </Flex>)}
          style={{
            border: 'none',
            width: '120px',
            height: '15px'
          }}
        />
      </DataTable>
    </>
  )
}
