import React from 'react'
import DisplayHeaderAtlModern from './HeaderComponent/DisplayHeaderAtlModern'
import InfoTour from './InfoTour'

function HeaderAtl ({
  published,
  handleChangeSlider,
  handleChangeFinance,
  syncronizeOnAppTour,
  financeData,
  atlData,
  isOnAppTour,
  uploadFilesOnServer,
  qrCodeLink,
  resolver
}) {
  const typeAtlData = (type) => {
    const config = {
      PRA: { shortName: 'pra', longName: 'Pratica' },
      'BGT-A': { shortName: 'bgt', longName: 'Biglietto' },
      'BGT-V': { shortName: 'bgt', longName: 'Biglietto' },
      VOU: { shortName: 'vou', longName: 'Voucher' },
      VIP: { shortName: 'pra', longName: 'Pratica' },
      TC: { shortName: 'tc', longName: 'Pratica' }
    }
    return config[type] || { shortName: '', longName: '' }
  }
  return (
    <div
      style={{
        marginTop: 10,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column', // screenWidth > 1500 ? 'row' : 'column'
        justifyContent: 'space-between'
      }}
    >
      <DisplayHeaderAtlModern type={typeAtlData(atlData.type).shortName} atlData={atlData} />
      <InfoTour
        type={typeAtlData(atlData.type).longName}
        atlData={atlData}
        financeData={financeData}
        handleChangeFinance={handleChangeFinance}
        isOnAppTour={isOnAppTour}
        syncronizeOnAppTour={syncronizeOnAppTour}
        published={published}
        handleChangeSlider={handleChangeSlider}
        qrCodeLink={qrCodeLink}
        resolver={resolver}
      />
    </div>
  )
}

export default HeaderAtl
