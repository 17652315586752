import React from 'react'

import { Card } from '@Common/Components'
import state from '../../../State'
import { Train } from './Elements'
import PlusButton from '../PlusButton'
import { updateService, deleteService } from '../../../Functions'

export default function TrainForm () {
  const [trainForm] = state.trainForm.useState()

  if (!trainForm?.length) return null

  return (
    <Card
      key='train'
      title='TRENO'
      collapsable={trainForm.length > 0}
      style={{ marginBottom: '20px', width: '100%' }}
    >
      <PlusButton type='trainForm' />
      {trainForm.map((item, index) => (
        <Train
          key={index}
          index={index}
          item={item}
          onUpdate={updateService}
          onDelete={deleteService}
        />
      ))}
    </Card>
  )
}
