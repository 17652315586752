import React from 'react'
import { Flex, Hover, Icon } from '@Common/Components'
import state from '../State'
const iconSize = 35

const BarBottom = ({ action, hidePassengers = false, hideMoneyForm = false }) => {
  const { hover, circle } = styles
  return (
    <Flex row style={{ marginBottom: 35, marginTop: 35 }}>
      <Hover style={hover}>
        <Icon
          name='hotel' text='Hotel' style={circle} textStyle={styles.textStyle} badgeSize={30}
          badge='+' size={iconSize} onClick={() => action('hotelForm')}
        />
      </Hover>
      <Hover style={hover}>
        <Icon
          name='plane' text='Voli' style={circle} badge='+' badgeSize={30}
          size={iconSize} onClick={() => action('flightForm')} textStyle={styles.textStyle}
        />
      </Hover>
      <Hover style={hover}>
        <Icon
          name='train' text='Treni' style={circle} badge='+' badgeSize={30}
          size={iconSize} onClick={() => action('trainForm')} textStyle={styles.textStyle}
        />
      </Hover>
      <Hover style={hover}>
        <Icon
          name='car' text='Auto' style={circle} badge='+' badgeSize={30}
          size={iconSize} onClick={() => action('carForm')} textStyle={styles.textStyle}
        />
      </Hover>
      <Hover style={hover}>
        <Icon
          name='ticket' text='Altro' style={circle} badge='+' badgeSize={30}
          size={iconSize} onClick={() => action('serviceForm')} textStyle={styles.textStyle}
        />
      </Hover>
      <Hover style={hover}>
        <Icon
          name='cruise' text='Nave' style={circle} badge='+' badgeSize={30}
          size={iconSize} onClick={() => action('cruiseForm')} textStyle={styles.textStyle}
        />
      </Hover>
      {!hidePassengers && (
        <Hover style={hover}>
          <Icon
            name='add-person' text='Passeggeri' style={circle} badge='+' badgeSize={30}
            size={iconSize} onClick={() => action('passengers')} textStyle={{ ...styles.textStyle, color: 'white' }}
          />
        </Hover>
      )}
      {state.moneyForm.state.length < 1 && !hideMoneyForm && (
        <Hover style={hover}>
          <Icon
            name='money' text='Contabilità' style={circle} badge='+' badgeSize={30}
            size={iconSize} onClick={() => action('moneyForm')} textStyle={{ ...styles.textStyle, color: 'white' }}
          />
        </Hover>
      )}
    </Flex>
  )
}

const styles = {
  hover: {
    width: 80,
    height: 80,
    margin: 10,
    marginBottom: 30
  },
  circle: {
    borderRadius: '50%',
    width: 70,
    height: 70,
    backgroundColor: 'white',
    color: 'white'
  },
  textStyle: {
    marginLeft: -10,
    color: 'white'
  }
}

export default BarBottom
