import React, { useState } from 'react'
import moment from 'moment'
import { Input, Button } from '@Common/Components'
import state from '../../State'

const GRID_NORMAL = [
  `'startPort startPort startPort endPort endPort endPort code code'
    'endDate endDate endDate endHour endHour endHour company company'
  `,
  '\'startDate end description description\''
]
const GRID_SMALL = [
  `'startPort startPort startPort startPort endPort endPort endPort endPort  '
  'endDate endDate endDate endDate endHour endHour endHour endHour'
  'code code code code company company company company'
`,
  `'startDate startDate startDate'
 'end end end'
 'description description description'
`
]

const Cruise = ({ item = {}, onDelete, index, onUpdate }) => {
  const [showOptional, setShowOptional] = useState(false)

  const updateItem = (update) => {
    const { _id = '' } = item
    onUpdate('cruiseForm', { _id, ...update })
  }

  const { main, grid } = styles
  const config = state.config.state
  const { screenWidth } = 1500

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {index > 0 && (
        <hr
          style={{
            height: 2,
            width: '100%',
            backgroundColor: 'white',
            border: 'none'
          }}
        />
      )}
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <div style={main}>
          <div
            style={{
              display: 'grid',
              width: '100%',
              gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL[1] : GRID_SMALL[1],
              gridGap: '9px'
            }}
          >
            <Input
              date
              id='startDate'
              label='Data Partenza:'
              style={{ width: '100%', marginRight: 5, gridArea: 'startDate' }}
              onChange={(e) => updateItem(e)}
              value={item.startDate ? moment(item.startDate).toDate() : ''}
            />
            <Input
              time
              id='startHour'
              label='Ora:'
              style={{ width: '100%', marginRight: 5, gridArea: 'end' }}
              onChange={(e) =>
                updateItem({
                  startHour: e.startHour !== null ? moment(e.startHour).format('HH:mm') : null
                })}
              value={
                item.startHour
                  ? item.startHour.length > 5
                      ? moment(item.startHour).toDate()
                      : moment(item.startHour, 'HH:mm').toDate()
                  : ''
              }
            />
            <Input
              id='description'
              label='Descrizione:'
              style={{
                gridArea: 'description',
                marginLeft: screenWidth > config.mediumScreen + 250 ? 5 : 0
              }}
              onChange={(e) => updateItem(e)}
              value={item.description || ''}
            />
          </div>
          <div
            style={
              showOptional
                ? {
                    ...grid,
                    gridTemplateAreas:
                    screenWidth > config.smallScreen + 450 ? GRID_NORMAL[0] : GRID_SMALL[0]
                  }
                : { display: 'none' }
            }
          >
            <Input
              id='startPort'
              label='Porto di partenza:'
              style={{ height: 34, gridArea: 'startPort' }}
              onChange={(e) => updateItem(e)}
              value={item.startPort || ''}
            />
            <Input
              id='endPort'
              label='Porto di arrivo:'
              style={{ height: 34, gridArea: 'endPort' }}
              onChange={(e) => updateItem(e)}
              value={item.endPort || ''}
            />
            <Input
              date
              id='endDate'
              label='Data arrivo:'
              style={{ height: 34, gridArea: 'endDate' }}
              onChange={(e) => updateItem(e)}
              value={item.endDate ? moment(item.endDate).toDate() : ''}
            />
            <Input
              time
              id='endHour'
              label='Ora arrivo:'
              style={{ height: 34, gridArea: 'endHour' }}
              onChange={(e) =>
                updateItem({ endHour: e.endHour !== null ? moment(e.endHour).format('HH:mm') : null })}
              value={
                item.endHour
                  ? item.endHour.length > 5
                      ? moment(item.endHour).toDate()
                      : moment(item.endHour, 'HH:mm').toDate()
                  : ''
              }
            />

            <Input
              id='code'
              label='Codice nave:'
              style={{ height: 34, gridArea: 'code' }}
              onChange={(e) => updateItem(e)}
              value={item.code || ''}
            />
            <Input
              id='company'
              label='Compagnia navale:'
              style={{ height: 34, gridArea: 'company' }}
              onChange={(e) => updateItem(e)}
              value={item.company || ''}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            marginLeft: 15
          }}
        >
          <Button
            rich
            icon='bin'
            round
            style={{ margin: 3 }}
            width={30}
            onClick={() => onDelete('cruiseForm', item._id)}
          />
          <Button
            rich
            icon='details'
            round
            style={{ margin: 3 }}
            width={30}
            onClick={() => setShowOptional(!showOptional)}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: 5,
    display: 'grid',
    gridGap: '9px 9px',
    width: '100%',
    height: 'max-content'
  }
}

export default Cruise
