import React from 'react'
import moment from 'moment'
import { Text } from '@Common/Components'

function SearchDataRenderVou ({ data }) {
  return (
    <>
      {data.type
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Tipo pratica:' style={{ marginRight: 5 }} />
            <Text bold value={data.type} />
          </div>)
        : null}
      {data.year
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Anno: ' style={{ marginRight: 5 }} />
            <Text bold value={data.year} />
          </div>)
        : null}
      {data.number
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Numero: ' style={{ marginRight: 5 }} />
            <Text bold value={data.number} />
          </div>)
        : null}
      {data.dataIn1
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Partenza da: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.dataIn1, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
      {data.dataIn2
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Partenza a: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.dataIn2, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
      {data.regDate1
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Registrazione da: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.regDate1, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
      {data.regDate2
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Registrazione a: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.regDate2, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
    </>
  )
}

export default SearchDataRenderVou
