import _ from 'lodash'

import state from '../State'

export const addService = (type) => {
  state?.[type]?.setState([...state?.[type].state, { _id: (_.max(state?.[type].state?.map(i => parseInt(i._id))) + 1 || 1).toString() }])
  state.tourActions.setState({ ...state.tourActions.state, saved: false })
}

export const deleteService = (type, id) => {
  if (!state?.[type]?.state) return false
  state?.[type].setState(state?.[type].state?.filter((item) => item._id !== id))
  state.tourActions.setState({ ...state.tourActions.state, saved: false })
}

export const updateService = (type, { _id, ...update }) => {
  if (!state?.[type].state || update?.mount) return false
  delete update.mount

  state.tourActions.setState({ ...state.tourActions.state, saved: false })
  state?.[type].setState(state?.[type].state?.map((item) => (item._id === _id ? { ...item, ...update } : item)))
  // state.tourActions.setState({ ...state.tourActions.state, saved: false })
}
