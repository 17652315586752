import React, { useState } from 'react'
import moment from 'moment'
import { useMount } from 'react-use'
import { Input, Button, NumberPrefix, FadeIn } from '@Common/Components'
import state from '../../../../State'
import { motion } from 'framer-motion'

const GRID_NORMAL = [
  `'name name name name name name startHour startHour'
    'address address address address address address num num'
    'city city city province province province cap cap'
    'email email email phone phone phone phone phone'
    'coordinates coordinates coordinates coordinates webSite webSite webSite webSite'
  `,
  '\'startDate endDate description description\''
]

const GRID_SMALL = [
  `'name name name startHour '
    'address address address address'
    'num num num num'
    'city city city city'
    'province province province province'
    'cap cap cap cap'
    'email email email email'
    'phone phone phone phone'
    'coordinates coordinates coordinates coordinates'
    'webSite webSite webSite webSite'
  `,
  `
  'startDate startDate startDate'
  'endDate endDate endDate'
  'description description description'
 `
]
const Hotel = ({ item = {}, onDelete, index, onUpdate, price }) => {
  const [showOptional, setShowOptional] = useState(false)
  const [prefixCountry, setPrefixCountry] = useState({
    label: 'Italy',
    prefix: '39'
  })

  const { _id = '' } = item
  const updateItem = (update) => {
    onUpdate('hotelForm', { _id, ...update })
  }

  useMount(() => {
    onUpdate('hotelForm', { _id, prefixCountry, mount: true })
  })

  const changeSelectedCountry = (data) => {
    const { _id = '' } = item
    setPrefixCountry(data)
    onUpdate('hotelForm', {
      _id,
      prefixCountry: { label: data.label, prefix: data.prefix }
    })
  }

  const { main, grid } = styles
  const screenWidth = 1500
  const config = state.config.state

  const renderContent = (index) => (
    <>
      <div style={main}>
        <div
          style={{
            display: 'grid',
            width: '100%',
            gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL[1] : GRID_SMALL[1],
            gridGap: '9px'
          }}
        >
          <Input
            date
            id='startDate'
            label='Data Inizio'
            style={{ width: '100%', marginRight: 5, gridArea: 'startDate' }}
            onChange={(e) => updateItem(e)}
            value={item.startDate ? moment(item.startDate).toDate() : ''}
            maxDate={item.endDate ? moment(item.endDate).toDate() : null}
          />
          <Input
            date
            id='endDate'
            label='Data Fine'
            style={{
              width: '100%',
              marginRight: 5,
              gridArea: 'endDate',
              marginLeft: screenWidth > config.mediumScreen + 250 ? 5 : 0
            }}
            onChange={(e) => updateItem(e)}
            value={item.endDate ? moment(item.endDate).toDate() : ''}
            minDate={item.startDate ? moment(item.startDate).toDate() : null}
          />
          {price &&
            (
              <Input
                keyfilter='Money'
                label='Prezzo'
                id='price'
                style={{
                  width: '100%',
                  gridArea: 'price',
                  marginLeft: screenWidth > config.mediumScreen + 250 ? 5 : 0
                }}
                value={item.price || ''}
                onChange={(e) => updateItem(e)}
              />
            )}

          <Input
            id='description'
            label='Descrizione'
            style={{
              width: '100%',
              gridArea: 'description',
              marginLeft: screenWidth > config.mediumScreen + 250 ? 5 : 0
            }}
            onChange={(e) => updateItem(e)}
            value={item.description || ''}
          />
        </div>
        <motion.div
          id={`motion-${index}`}
          variants={{
            start: { display: 'none', opacity: 0, height: 0, transition: { default: { duration: 0.5 } } },
            end: { display: 'grid', opacity: 1, height: 'auto', transition: { duration: 0.5 } }
          }}
          initial='start' animate={showOptional ? 'end' : 'start'}
          style={{ ...grid, gridTemplateAreas: screenWidth > config.smallScreen + 450 ? GRID_NORMAL[0] : GRID_SMALL[0] }}
        >
          <Input
            id='name'
            label='Nome Hotel'
            style={{ gridArea: 'name' }}
            onChange={(e) => updateItem(e)}
            value={item.name || ''}
          />
          <Input
            time
            id='startHour'
            label='Check-in'
            style={{ gridArea: 'startHour' }}
            onChange={(e) =>
              updateItem({
                startHour: e.startHour !== null ? moment(e.startHour).format('HH:mm') : null
              })}
            value={
              item.startHour
                ? item.startHour.length > 5
                    ? moment(item.startHour).toDate()
                    : moment(item.startHour, 'HH:mm').toDate()
                : ''
            }
          />
          <Input
            id='address'
            label='Indirizzo'
            style={{ gridArea: 'address' }}
            onChange={(e) => updateItem(e)}
            value={item.address || ''}
          />
          <Input
            id='num'
            label='Num.'
            style={{ gridArea: 'num' }}
            onChange={(e) => updateItem(e)}
            value={item.num || ''}
          />
          <Input
            id='city'
            label='Citta'
            style={{ gridArea: 'city' }}
            onChange={(e) => updateItem(e)}
            value={item.city || ''}
          />
          <Input
            id='cap'
            label='Cap'
            style={{ gridArea: 'cap' }}
            onChange={(e) => updateItem(e)}
            value={item.cap || ''}
          />
          <Input
            id='province'
            label='Provincia'
            style={{ gridArea: 'province' }}
            onChange={(e) => updateItem(e)}
            value={item.province || ''}
          />
          <div
            style={{
              gridArea: 'phone',
              display: 'flex',
              flexDirection: screenWidth > config.smallScreen + 450 ? 'row' : 'column'
            }}
          >
            <NumberPrefix
              style={{ marginRight: 5 }}
              onChangeSelectedCountry={(update) => changeSelectedCountry(update)}
              prefixCountry={prefixCountry}
            />
            <Input
              id='phone'
              label='Telefono'
              style={{ width: '100%' }}
              onChange={(e) => updateItem(e)}
              value={item.phone || ''}
            />
          </div>
          <Input
            id='email'
            label='Email'
            style={{ gridArea: 'email' }}
            onChange={(e) => updateItem(e)}
            value={item.email || ''}
          />
          <Input
            id='coordinates'
            label='Coordinate'
            style={{ gridArea: 'coordinates' }}
            onChange={(e) => updateItem(e)}
            value={item.coordinates || ''}
          />
          <Input
            id='webSite'
            label='Sito Web'
            style={{ gridArea: 'webSite' }}
            onChange={(e) => updateItem(e)}
            value={item.webSite || ''}
          />
        </motion.div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          marginLeft: 15
        }}
      >
        <Button
          rich
          icon='bin'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => onDelete('hotelForm', item._id)}
        />
        <Button
          rich
          icon='details'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => setShowOptional(!showOptional)}
        />
      </div>
    </>)

  return (
    <div id={`content-${index}`} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {index > 0 && (
        <hr
          style={{
            height: 2,
            width: '100%',
            backgroundColor: 'white',
            border: 'none'
          }}
        />
      )}
      {index > 0
        ? (
          <FadeIn style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent(index)}
          </FadeIn>)
        : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent(index)}
          </div>)}
    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    marginTop: 0,
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: 5,
    display: 'grid',
    gridGap: '9px 9px',
    width: '100%',
    height: 'max-content'
  }
}

export default Hotel
