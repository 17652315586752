import React, { useRef } from 'react'
import { Button, Modal } from '@Common/Components'
import { FC, invalidateQuery } from '@Common/Services'

const DeleteChat = ({ style, conversation }) => {
  const modalDelete = useRef(null)

  const handleDeleteChat = async () => {
    if (!conversation?._id) return
    await FC.service('info').create({ type: 'deleteConversationMessages', conversationId: conversation._id.toString() })
    await FC.service('messages').create({ conversationId: conversation._id.toString(), text: 'I messaggi precedenti sono stati cancellati', createdAt: new Date(), messageType: 'text', user: conversation.agency })
    invalidateQuery(['messages', 'conversations'])
  }

  return (
    <>
      <Modal
        ref={modalDelete} action={handleDeleteChat}
        header={'Sicuro di voler cancellare tutti i messaggi di questa conversazione? L\'operazione è irreversibile'}
      />
      <Button
        icon='bin'
        label='Elimina Chat'
        style={{
          marginLeft: 20,
          padding: 10,
          ...style
        }}
        onClick={() => modalDelete.current ? modalDelete.current.show() : modalDelete.show()}
      />
    </>
  )
}

export default DeleteChat
