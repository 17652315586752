import React, { useState } from 'react'
import moment from 'moment'
import { RadioButton } from 'primereact/radiobutton'
import { Input, Button, NumberPrefix, Text, FadeIn } from '@Common/Components'
import state from '../../../../State'
import { useMount } from 'react-use'
import { motion } from 'framer-motion'

const GRID_NORMAL = [
  `
  'company company company company phone phone phone phone'
    'startHour startHour city city endHour endHour cityDropOff cityDropOff'
  `,
  '\'startDate endDate description description\''
]
const GRID_SMALL = [
  `
    'company company company company'
    'phone phone phone phone'
    'startHour startHour startHour startHour'
    'city city city city'
    'endHour endHour endHour endHour'
    'cityDropOff cityDropOff cityDropOff cityDropOff'
  `,
  `
  'startDate startDate startDate'
  'endDate endDate endDate'
  'description description description'
 `
]

const Car = ({ item = {}, onUpdate, onDelete, index }) => {
  const [showOptional, setShowOptional] = useState(false)
  const [prefixCountry, setPrefixCountry] = useState({
    label: 'Italy',
    prefix: '39'
  })

  useMount(() => {
    const { _id = '' } = item
    onUpdate('carForm', { _id, prefixCountry, mount: true })
  })

  const updateItem = (update) => {
    const { _id = '' } = item
    onUpdate('carForm', { _id, ...update })
  }

  const changeSelectedCountry = (data) => {
    const { _id = '' } = item
    setPrefixCountry(data)
    onUpdate('carForm', {
      _id,
      prefixCountry: { label: data.label, prefix: data.prefix }
    })
  }

  const renderRadiobuttonCar = ({ checkTypeCar = '' }) => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
        <Text value='Tipologia servizio auto' style={{ width: '50%' }} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <RadioButton
            inputId='rb1'
            name='checkTypeCar'
            value='TRS'
            onChange={(e) => updateItem({ checkTypeCar: e.value })}
            checked={checkTypeCar === 'TRS' || !checkTypeCar}
          />
          <Text size={18} value='Trasferta' style={{ marginLeft: 5, marginTop: -5 }} />
          <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }}>
            <RadioButton
              inputId='rb2'
              name='checkTypeCar'
              value='NOL'
              onChange={(e) => updateItem({ checkTypeCar: e.value })}
              checked={checkTypeCar === 'NOL'}
            />
            <Text size={18} value='Noleggio' style={{ marginLeft: 5, marginTop: -5 }} />
          </div>
          <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }}>
            <RadioButton
              inputId='rb3'
              name='checkTypeCar'
              value='NCC'
              onChange={(e) => updateItem({ checkTypeCar: e.value })}
              checked={checkTypeCar === 'NCC'}
            />
            <Text size={18} value='Veicolo a disposizione' style={{ marginLeft: 5, marginTop: -5 }} />
          </div>
        </div>
      </div>
    )
  }

  const { main, grid } = styles
  const config = state.config.state
  const screenWidth = 1500

  const renderContent = () => (
    <>
      <div style={main}>
        {renderRadiobuttonCar(item)}
        <div
          style={{
            display: 'grid',
            width: '100%',
            gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL[1] : GRID_SMALL[1],
            gridGap: '9px'
          }}
        >
          <Input
            date
            id='startDate'
            label={item.checkRent ? 'Inizio noleggio:' : 'Inizio trasferimento'}
            style={{ width: '100%', marginRight: 5, gridArea: 'startDate' }}
            onChange={(e) => updateItem(e)}
            value={item.startDate ? moment(item.startDate).toDate() : ''}
            maxDate={item.endDate ? moment(item.endDate).toDate() : null}
          />
          <Input
            date
            id='endDate'
            label={item.checkRent ? 'Fine noleggio:' : 'Fine trasferimento'}
            style={{ width: '100%', marginRight: 5, gridArea: 'endDate' }}
            onChange={(e) => updateItem(e)}
            value={item.endDate ? moment(item.endDate).toDate() : ''}
            minDate={item.startDate ? moment(item.startDate).toDate() : null}
          />
          <Input
            id='description'
            label='Descrizione:'
            style={{ width: '100%', gridArea: 'description' }}
            onChange={(e) => updateItem(e)}
            value={item.description || ''}
          />
        </div>
        <motion.div
          variants={{
            start: { display: 'none', opacity: 0, height: 0, transition: { default: { duration: 0.5 } } },
            end: { display: 'grid', opacity: 1, height: 'auto', transition: { duration: 0.5 } }
          }}
          initial='start' animate={showOptional ? 'end' : 'start'}
          style={{ ...grid, gridTemplateAreas: screenWidth > config.smallScreen + 450 ? GRID_NORMAL[0] : GRID_SMALL[0] }}
        >
          <Input
            id='company'
            label='Fornitore:'
            style={{ width: '100%', gridArea: 'company' }}
            onChange={(e) => updateItem(e)}
            value={item.company || ''}
          />
          <div
            style={{
              gridArea: 'phone',
              display: 'flex',
              flexDirection: screenWidth > config.smallScreen + 450 ? 'row' : 'column'
            }}
          >
            <NumberPrefix
              style={{ marginRight: 5 }}
              onChangeSelectedCountry={(update) => changeSelectedCountry(update)}
              prefixCountry={prefixCountry}
            />
            <Input
              id='phone'
              label='Telefono:'
              style={{ width: '100%', gridArea: 'phone' }}
              onChange={(e) => updateItem(e)}
              value={item.phone || ''}
            />
          </div>
          <Input
            time
            id='startHour'
            label='Ora pickup:'
            style={{ width: '100%', gridArea: 'startHour' }}
            onChange={(e) => updateItem({ startHour: e.startHour !== null ? moment(e.startHour).format('HH:mm') : null })}
            value={item.startHour ? item.startHour.length > 5 ? moment(item.startHour).toDate() : moment(item.startHour, 'HH:mm').toDate() : ''}
          />
          <Input
            id='pickUpPoint'
            label='Città pick up:'
            style={{ width: '100%', gridArea: 'city', height: 33 }}
            onChange={(e) => updateItem(e)}
            value={item.pickUpPoint === undefined ? item.city : item.pickUpPoint || ''}
          />
          <Input
            time
            id='endHour'
            label='Ora drop off:'
            style={{ width: '100%', gridArea: 'endHour' }}
            onChange={(e) => updateItem({ endHour: e.endHour !== null ? moment(e.endHour).format('HH:mm') : null })}
            value={item.endHour ? item.endHour.length > 5 ? moment(item.endHour).toDate() : moment(item.endHour, 'HH:mm').toDate() : ''}
          />
          <Input
            id='dropOffPoint'
            label='Città drop off:'
            style={{ width: '100%', gridArea: 'cityDropOff', height: 33 }}
            onChange={(e) => updateItem(e)}
            value={item.dropOffPoint === undefined ? item.city : item.dropOffPoint || ''}
          />
        </motion.div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          marginLeft: 15
        }}
      >
        <Button
          rich
          icon='bin'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => onDelete('carForm', item._id)}
        />
        <Button
          rich
          icon='details'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => setShowOptional(!showOptional)}
        />
      </div>
    </>
  )

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {index > 0 && (
        <hr
          style={{
            height: 2,
            width: '100%',
            backgroundColor: 'white',
            border: 'none'
          }}
        />
      )}
      {index > 0
        ? (
          <FadeIn style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </FadeIn>)
        : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </div>)}

    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    marginTop: 0,
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: 5,
    display: 'grid',
    gridGap: '9px 9px',
    width: '100%',
    height: 'max-content'
  }
}

export default Car
