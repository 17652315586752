import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Button, Text, Slider } from '@Common/Components'
import state from '../../State'
import { useWindowSize } from 'react-use'

const GRID_NORMAL = `
'firstDiv secondDiv thirdDiv'
`

const GRID_MID = `
'firstDiv firstDiv firstDiv'
'secondDiv secondDiv secondDiv'
'thirdDiv thirdDiv thirdDiv'
`

function InfoTour ({
  financeData,
  handleChangeFinance,
  isOnAppTour,
  type,
  syncronizeOnAppTour,
  published,
  handleChangeSlider,
  qrCodeLink,
  resolver,
  atlData
}) {
  const { width: screenWidth } = useWindowSize()
  const config = state.config.state

  const renderFinanceAndStatusApp = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: screenWidth > 1324 ? 0 : 20,
        gridArea: 'firstDiv'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Checkbox
          checked={financeData}
          onChange={(e) => {
            handleChangeFinance(e.checked)
          }}
        />
        <Text
          value='Visualizza i dati finanziari della pratica (occhio ai regali!)'
          style={{ marginLeft: 5, width: '100%' }}
        />
      </div>

      {!isOnAppTour
        ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 10
            }}
          >
            <Button
              rich
              icon='check'
              style={{ marginLeft: 10 }}
              label={`Sincronizza ${type}`}
              onClick={async () => {
                const res = await syncronizeOnAppTour()
                if (!res) {
                  return false
                }
              }}
            />
          </div>
          )
        : (
          <Slider
            style={{ marginTop: 10 }}
            value={published}
            onChange={async (status) => {
              const res = await handleChangeSlider(status)
              if (!res) return false
            }}
          />
          )}
    </div>
  )

  const renderQrCodeAndResolver = () =>
    published && (qrCodeLink || resolver)
      ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: screenWidth > 1524 ? 'space-between' : 'space-around',
            marginTop: screenWidth > 1524 ? 0 : 30,
            gridArea: 'secondDiv'
          }}
        >
          {resolver
            ? (
              <Text value={`Codice tour: ${resolver}`} bold title style={{ textAlign: 'center' }} />
              )
            : null}
          {qrCodeLink
            ? (
              <Button
                tooltip='Stampa'
                onClick={() => {
                  const tourId = atlData._id.split('@')[1]
                  const a = document.createElement('a')
                  a.href = qrCodeLink
                  a.download = `${tourId}-qrcode.pdf`
                  a.target = '_blank'
                  a.click()
                }}
                rich
                label='Stampa documento con QR'
                icon='print'
                style={{ marginTop: 10 }}
              />
              )
            : null}
        </div>
        )
      : null

  const renderStatusAtl = () =>
    atlData.statusAtl
      ? (
        <div style={{ gridArea: 'thirdDiv' }}>
          <Text
            title
            bold
            value={`Stato ${type} in Atlante: ${atlData.statusAtl}`}
            style={{ textAlign: 'center' }}
          />
        </div>
        )
      : null

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '50px' }}>
      <div
        style={{
          display: 'grid',
          width: '100%',
          justifyContent: 'center',
          gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL : GRID_MID,
          gridGap: '50px'
        }}
      >
        {renderFinanceAndStatusApp()}
        {renderQrCodeAndResolver()}
        {renderStatusAtl()}
      </div>
    </div>
  )
}

export default InfoTour
