import React from 'react'
import { Text, Icon } from '@Common/Components'
import { useWindowSize } from 'react-use'

const GRID_BIG = `
'sync desync remove'
'synchronized notSynchronized notSynchronized`

const GRID_SMALL = `
'sync'
'desync'
'remove'
'synchronized'
'notSynchronized'
'notSynchronized'`

export default function Key () {
  const { width: screenWidth } = useWindowSize()
  return (
    <div style={{ marginBottom: 20 }}>
      <Text bold title upCase value='Legenda: ' />
      <div
        style={{
          display: 'grid',
          gridTemplateAreas: screenWidth > 600 ? GRID_BIG : GRID_SMALL,
          width: '50%',
          gridGap: 20,
          marginTop: 10
        }}
      >
        <div style={{ gridArea: 'sync' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Icon name='up-round' color='' size={25} />
            <Text size={16} value='Documento da sincronizzare' style={{ marginLeft: 5 }} />
          </div>
        </div>
        <div style={{ gridArea: 'desync' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Icon name='close' color='#d02f24' size={25} />
            <Text size={16} value='De-sincronizza documento' style={{ marginLeft: 5 }} />
          </div>
        </div>
        <div style={{ gridArea: 'remove' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Icon name='bin' color='' size={25} />
            <Text size={16} value='Rimuovi documento' style={{ marginLeft: 5 }} />
          </div>
        </div>

        <div style={{ gridArea: 'synchronized' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Icon name='sincronize-phone' color='#008800' size={25} />
            <Text size={16} value='Documento sincronizzato' style={{ marginLeft: 5 }} />
          </div>
        </div>
        <div style={{ gridArea: 'notSynchronized' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Icon name='sincronize-phone' color='#d02f24' size={25} />
            <Text size={16} value='Documento non sincronizzato' style={{ marginLeft: 5 }} />
          </div>
        </div>
      </div>
    </div>
  )
}
