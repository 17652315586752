import React, { useState } from 'react'
import moment from 'moment'
import { RadioButton } from 'primereact/radiobutton'
import { Input, Button, NumberPrefix, Text } from '@Common/Components'
import state from '../../State'
import { useMount } from 'react-use'

const GRID_NORMAL = [
  `
  'company company company company phone phone phone phone'
    'address address address address dropOff dropOff dropOff dropOff '
    'city city city city cityDropOff cityDropOff cityDropOff cityDropOff'
  `,
  '\'startDate endDate description description\''
]
const GRID_SMALL = [
  `
    'company company company company'
    'phone phone phone phone'
    'address address address address'
    'dropOff dropOff dropOff dropOff'
    'city city city city'
    'cityDropOff cityDropOff cityDropOff cityDropOff'
  `,
  `
  'startDate startDate startDate'
  'endDate endDate endDate'
  'description description description'
 `
]

const Car = ({ item = {}, onUpdate, onDelete, index }) => {
  const [showOptional, setShowOptional] = useState(false)
  const [prefixCountry, setPrefixCountry] = useState({
    label: 'Italy',
    prefix: '39'
  })

  useMount(() => {
    const { _id = '' } = item
    onUpdate('carForm', { _id, prefixCountry })
  })

  const updateItem = (update) => {
    const { _id = '' } = item
    onUpdate('carForm', { _id, ...update })
  }

  const changeSelectedCountry = (data) => {
    const { _id = '' } = item
    setPrefixCountry(data)
    onUpdate('carForm', {
      _id,
      prefixCountry: { label: data.label, prefix: data.prefix }
    })
  }

  const renderRadiobuttonCar = ({ checkTypeCar = '' }) => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
        <Text value='Tipologia servizio auto' style={{ width: '50%' }} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <RadioButton
            inputId='rb1'
            name='checkTypeCar'
            value='TRS'
            onChange={(e) => updateItem({ checkTypeCar: e.value })}
            checked={checkTypeCar === 'TRS' || !checkTypeCar}
          />
          <Text size={18} value='Trasferta' style={{ marginLeft: 5, marginTop: -5 }} />
          <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }}>
            <RadioButton
              inputId='rb2'
              name='checkTypeCar'
              value='NOL'
              onChange={(e) => updateItem({ checkTypeCar: e.value })}
              checked={checkTypeCar === 'NOL'}
            />
            <Text size={18} value='Noleggio' style={{ marginLeft: 5, marginTop: -5 }} />
          </div>
          <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }}>
            <RadioButton
              inputId='rb3'
              name='checkTypeCar'
              value='NCC'
              onChange={(e) => updateItem({ checkTypeCar: e.value })}
              checked={checkTypeCar === 'NCC'}
            />
            <Text size={18} value='Veicolo a disposizione' style={{ marginLeft: 5, marginTop: -5 }} />
          </div>
        </div>
      </div>
    )
  }

  const { main, grid } = styles
  const config = state.config.state
  const screenWidth = 1500

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {index > 0 && (
        <hr
          style={{
            height: 2,
            width: '100%',
            backgroundColor: 'white',
            border: 'none'
          }}
        />
      )}
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <div style={main}>
          {renderRadiobuttonCar(item)}
          {/*   <div style={ {
              display: 'flex', flexDirection: 'row', marginBottom: 10, justifyContent: 'flex-end' } }>
              <Text
                bold
                style={ { marginTop: 10, marginRight: 5 } }
                value={ 'E\' un noleggio' }
              />
              <Checkbox
                checked={ item.checkRent }
                style={ { marginTop: 12 } }
                tooltip={ !item.checkRent ? 'E\' un noleggio' : 'Non è un noleggio' }
                onChange={ (e) => {
                  this.updateItem({ checkRent: e.checked })
                } }
              />
            </div> */}
          <div
            style={{
              display: 'grid',
              width: '100%',
              gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL[1] : GRID_SMALL[1],
              gridGap: '9px'
            }}
          >
            <Input
              date
              id='startDate'
              label={item.checkRent ? 'Inizio noleggio:' : 'Inizio trasferimento'}
              style={{ width: '100%', marginRight: 5, gridArea: 'startDate' }}
              onChange={(e) => updateItem(e)}
              value={item.startDate ? moment(item.startDate).toDate() : ''}
              maxDate={item.endDate ? moment(item.endDate).toDate() : null}
            />
            <Input
              date
              id='endDate'
              label={item.checkRent ? 'Fine noleggio:' : 'Fine trasferimento'}
              style={{ width: '100%', marginRight: 5, gridArea: 'endDate' }}
              onChange={(e) => updateItem(e)}
              value={item.endDate ? moment(item.endDate).toDate() : ''}
              minDate={item.startDate ? moment(item.startDate).toDate() : null}
            />
            <Input
              id='description'
              label='Descrizione:'
              style={{ width: '100%', gridArea: 'description' }}
              onChange={(e) => updateItem(e)}
              value={item.description || ''}
            />
          </div>
          <div
            style={
              showOptional
                ? {
                    ...grid,
                    gridTemplateAreas: screenWidth > config.smallScreen + 450 ? GRID_NORMAL[0] : GRID_SMALL[0]
                  }
                : { display: 'none' }
            }
          >
            <Input
              id='company'
              label='Fornitore:'
              style={{ width: '100%', gridArea: 'company' }}
              onChange={(e) => updateItem(e)}
              value={item.company || ''}
            />
            <div
              style={{
                gridArea: 'phone',
                display: 'flex',
                flexDirection: screenWidth > config.smallScreen + 450 ? 'row' : 'column'
              }}
            >
              <NumberPrefix
                style={{ marginRight: 5 }}
                onChangeSelectedCountry={(update) => changeSelectedCountry(update)}
                prefixCountry={prefixCountry}
              />
              <Input
                id='phone'
                label='Telefono:'
                style={{ width: '100%', gridArea: 'phone' }}
                onChange={(e) => updateItem(e)}
                value={item.phone || ''}
              />
            </div>
            <Input
              id='pickUpPoint'
              label='Città pick up:'
              style={{ width: '100%', gridArea: 'city' }}
              onChange={(e) => updateItem(e)}
              value={item.pickUpPoint === undefined ? item.city : item.pickUpPoint || ''}
            />
            <Input
              id='dropOffPoint'
              label='Città drop off:'
              style={{ width: '100%', gridArea: 'cityDropOff' }}
              onChange={(e) => updateItem(e)}
              value={item.dropOffPoint === undefined ? item.city : item.dropOffPoint || ''}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            marginLeft: 15
          }}
        >
          <Button
            rich
            icon='bin'
            round
            style={{ margin: 3 }}
            width={30}
            onClick={() => onDelete('carForm', item._id)}
          />
          <Button
            rich
            icon='details'
            round
            style={{ margin: 3 }}
            width={30}
            onClick={() => setShowOptional(!showOptional)}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    marginTop: 0,
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: 5,
    display: 'grid',
    gridGap: '9px 9px',
    width: '100%',
    height: 'max-content'
  }
}

export default Car
