import React from 'react'
import moment from 'moment'
import { Text } from '@Common/Components'

const SIZE_TEXT = 15
function VouDisplay ({ data }) {
  const renderDate = (date) => (
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
      <Text size={50} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('DD')} />
      <div style={{ marginTop: 14, marginLeft: 5 }}>
        <Text size={17} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('MMMM')} />
        <Text size={17} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('YYYY')} />
      </div>
    </div>
  )
  const getTypeVoucher = (typeVou) => {
    if (!typeVou) return ''

    const splitId = typeVou.split('@')

    if (splitId.length && splitId[1].length) {
      const finalType = splitId[1].split('-')
      if (finalType.length) return finalType[0]
    }
    return data.type
  }

  const renderDescription = () => (
    <div
      style={{
        ...Styles.divContainer,
        minWidth: 150
      }}
    >
      <Text size={SIZE_TEXT} value='Tipo Voucher:' style={{ marginRight: 20 }} />
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text
          size={SIZE_TEXT}
          bold
          value={getTypeVoucher(data._id)}
          style={{ marginRight: 20, width: '100%' }}
        />
      </div>
    </div>
  )
  const renderStartDate = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={SIZE_TEXT} value='Inizio viaggio:' style={{ marginRight: 20 }} />

      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {renderDate(data.startDate)}
      </div>
    </div>
  )
  const renderEndDate = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={SIZE_TEXT} value='Fine viaggio:' style={{ marginRight: 20 }} />
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {renderDate(data.endDate)}
      </div>
    </div>
  )
  const renderDataReg = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={SIZE_TEXT} value='Data registrazione:' style={{ marginRight: 20 }} />
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {renderDate(data.registrationDate)}
      </div>
    </div>
  )
  const renderTotal = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={SIZE_TEXT} value='Costo totale:' style={{ marginRight: 20 }} />
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text size={24} bold value={`${data.total}€`} style={{ marginRight: 20 }} />
      </div>
    </div>
  )
  const renderToPay = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={SIZE_TEXT} value='Totale pagato:' style={{ marginRight: 20, width: '100%' }} />
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text size={24} bold value={`${data.totalCashed}€`} style={{ marginRight: 20, width: '100%' }} />
      </div>
    </div>
  )

  const renderDivision = () => (
    <div
      style={{
        width: 2,
        height: '100%',
        backgroundColor: 'white',
        border: '1px solid #32324e',
        maxHeight: 97
      }}
    />
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
      {renderDescription()}
      {renderDivision()}
      {renderStartDate()}
      {renderDivision()}
      {renderEndDate()}
      {renderDivision()}
      {renderDataReg()}
      {renderDivision()}
      {renderTotal()}
      {renderDivision()}
      {renderToPay()}
    </div>
  )
}
const Styles = {
  divContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 20
  }
}

export default VouDisplay
