import React, { useState } from 'react'
import moment from 'moment'
import { Input, Button, NumberPrefix, Icon, FadeIn } from '@Common/Components'

const ICONS = [
  { label: 'ticket', value: 'ticket' },
  { label: 'bike', value: 'bike' },
  { label: 'bus', value: 'bus' },
  { label: 'escursioni', value: 'escursioni' },
  { label: 'guida-turistica', value: 'guida-turistica' },
  { label: 'museo', value: 'museo' },
  { label: 'experiments', value: 'experiments' },
  { label: 'extreme', value: 'extreme' },
  { label: 'image', value: 'image' },
  { label: 'motorbike', value: 'motorbike' },
  { label: 'teatre', value: 'teatre' },
  { label: 'beach', value: 'beach' },
  { label: 'cart', value: 'cart' },
  { label: 'culture', value: 'culture' },
  { label: 'gourmet', value: 'gourmet' },
  { label: 'mountain', value: 'mountain' },
  { label: 'travel', value: 'travel' },
  { label: 'binoculars', value: 'binoculars' },
  { label: 'camp', value: 'camp' },
  { label: 'cocktail', value: 'cocktail' },
  { label: 'family', value: 'family' },
  { label: 'camper', value: 'camper' },
  { label: 'dog', value: 'dog' },
  { label: 'tent', value: 'tent' },
  { label: 'fa-notes-medical', value: 'fa-notes-medical' }
]

const GRID_LAYOUT = `
'startDate startDate startDate startHour startHour startHour endDate endDate endDate'
'description description description description description description icon icon icon'
'address address num city cap province coordinates coordinates coordinates'
'prefix prefix telephone telephone prefixTwo telephoneTwo telephoneTwo telephoneTwo telephoneTwo '
`

const iconTemplate = (icon) => (
  <Icon key={'ico' + 1} name={icon?.value || 'ticket'} size={24} />
)
export default function Other ({ item = {}, onDelete, index, onUpdate }) {
  const updateItem = (update) => {
    const { _id = '' } = item
    onUpdate('serviceForm', { _id, ...update })
  }

  const changeSelectedCountry = (data) => {
    setPrefixCountry(data)
    const { _id = '' } = item
    onUpdate('serviceForm', {
      _id,
      prefixCountry: { label: data.label, prefix: data.prefix }
    })
  }

  const { main, ever } = styles

  const [prefixCountry, setPrefixCountry] = useState({ label: 'Italy (Italia)', prefix: '39', value: 104, priority: 0 })

  const renderContent = () => (
    <>
      <div style={main}>
        <div style={{ ...ever, flexDirection: 'column' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateAreas: GRID_LAYOUT,
              gridGap: '9px 9px',
              width: '100%',
              height: 'max-content'
            }}
          >
            <Input
              date
              id='startDate'
              label='Data inizio'
              style={{ gridArea: 'startDate' }}
              onChange={(e) => updateItem(e)}
              value={item.startDate ? moment(item.startDate).toDate() : ''}
              maxDate={item.endDate ? moment(item.endDate).toDate() : null}
            />
            <Input
              time
              id='startHour'
              label='Ora inizio'
              style={{ gridArea: 'startHour' }}
              onChange={(e) => updateItem({ startHour: e.startHour !== null ? moment(e.startHour).format('HH:mm') : null })} value={
                item.startHour
                  ? item.startHour.length > 5
                      ? moment(item.startHour).toDate()
                      : moment(item.startHour, 'HH:mm').toDate()
                  : ''
              }
            />
            <Input
              date
              id='endDate'
              label='Data fine'
              style={{ gridArea: 'endDate' }}
              onChange={(e) => updateItem(e)}
              value={item.endDate ? moment(item.endDate).toDate() : ''}
              minDate={item.startDate ? moment(item.startDate).toDate() : null}
            />
            <Input
              id='description'
              label='Descrizione:'
              style={{ gridArea: 'description' }}
              onChange={(e) => updateItem(e)}
              value={item.description || ''}
            />
            <Input
              itemTemplate={iconTemplate}
              valueTemplate={iconTemplate}
              dropdown
              id='icon'
              style={{ gridArea: 'icon' }}
              // placeholder={<div style={{ display: 'flex', alignItems: 'center' }}><Text value='Icona:' style={{ marginRight: 10 }} color='rgb(120,120,120)' /><Icon name={item?.icon || 'ticket'} size={24} /></div>}
              value={item?.icon || 'ticket'}
              options={ICONS}
              // label='test'
              // placeholder={`${<Text value='Icona' />}${item.icon || ''}`}
              // placeholder={`${<Icon name={item.icon.props.name} /> || ''}`}
              onChange={({ icon }) => updateItem({ icon })}
            />
            <Input
              id='address'
              label='Indirizzo'
              style={{ gridArea: 'address' }}
              onChange={(e) => updateItem(e)}
              value={item.address || ''}
            />
            <Input
              id='num'
              label='Num.'
              style={{ gridArea: 'num' }}
              onChange={(e) => updateItem(e)}
              value={item.num || ''}
            />
            <Input
              id='city'
              label='Citta'
              style={{ gridArea: 'city' }}
              onChange={(e) => updateItem(e)}
              value={item.city || ''}
            />
            <Input
              id='cap'
              label='Cap'
              style={{ gridArea: 'cap' }}
              onChange={(e) => updateItem(e)}
              value={item.cap || ''}
            />

            <Input
              id='province'
              label='Provincia'
              style={{ gridArea: 'province' }}
              onChange={(e) => updateItem(e)}
              value={item.province || ''}
            />
            <Input
              id='coordinates'
              label='Coordinate'
              style={{ gridArea: 'coordinates' }}
              onChange={(e) => updateItem(e)}
              value={item.coordinates || ''}
            />
            <NumberPrefix
              style={{ gridArea: 'prefix' }}
              onChangeSelectedCountry={(update) => changeSelectedCountry(update)}
              prefixCountry={prefixCountry}
            />
            <Input
              id='phone'
              label='Telefono'
              style={{ gridArea: 'telephone' }}
              onChange={(e) => updateItem(e)}
              value={item.phone || ''}
            />
            <NumberPrefix
              style={{ gridArea: 'prefixTwo' }}
              onChangeSelectedCountry={(update) => changeSelectedCountry(update)}
              prefixCountry={prefixCountry}
            />
            <Input
              id='phoneTwo'
              label='Secondo Telefono'
              style={{ gridArea: 'telephoneTwo' }}
              onChange={(e) => updateItem(e)}
              value={item.phoneTwo || ''}
            />

          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}
          />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          marginLeft: 15
        }}
      >
        <Button
          rich
          icon='bin'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => onDelete('serviceForm', item._id)}
        />

      </div>
    </>
  )

  return (
    <div>
      <div>
        {index > 0 && (
          <hr
            style={{
              height: 2,
              width: '100%',
              backgroundColor: 'white',
              border: 'none'
            }}
          />
        )}
      </div>
      {index > 0
        ? (
          <FadeIn style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </FadeIn>)
        : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </div>)}
    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  ever: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}
