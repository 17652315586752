import React from 'react'
import { useWindowSize } from 'react-use'
import moment from 'moment'

import { Card, Flex, Input } from '@Common/Components'
import state from '../../../State'
import { countDaysNight } from '../../../Functions'

export default function TourData () {
  const { width: screenWidth } = useWindowSize()
  const config = state.config.state

  const [tourData, setTourData] = state.tourData.useState()
  const [tourActions, setTourActions] = state.tourActions.useState()
  const [startCheckErrorsGlobal, setStartCheckErrorsGlobal] = state.startCheckErrorsGlobal.useState()

  const tourDataStartDate = tourData.startDate ? moment(tourData.startDate).toDate() : ''
  const tourDataEndDate = tourData.endDate ? moment(tourData.endDate).toDate() : ''

  const updateItem = (update) => {
    setStartCheckErrorsGlobal(false)
    setTourData({ ...tourData, ...update })
    setTourActions({ ...tourActions, saved: false })
  }

  const inputProps = (id, label, value) => ({
    id,
    label,
    value
  })

  const onChangeCalendar = (field, value) => (e) => {
    if (e[field] && value) {
      const day1 = field === 'startDate' ? e[field] : value
      const day2 = field === 'startDate' ? value : e[field]
      const [nights, days] = countDaysNight(day1, day2)
      updateItem({ ...e, tripNights: nights, tripDays: days })
    } else updateItem(e)
  }

  return (
    <Card title='Dati Pratica' collapsable style={{ width: '100%', marginBottom: 20 }}>
      <Flex fw as js>
        <Flex fw row style={{ marginTop: 10, flexWrap: screenWidth < config?.mediumScreen ? 'wrap' : null }}>
          <Input
            error={startCheckErrorsGlobal ? !tourDataStartDate : false}
            {...inputProps('startDate', 'Inizio viaggio', tourDataStartDate)}
            onChange={onChangeCalendar('startDate', tourDataEndDate)}
            maxDate={tourDataEndDate ? moment(tourDataEndDate).toDate() : null}
            date
          />
          <Input
            error={startCheckErrorsGlobal ? !tourDataEndDate : false}
            {...inputProps('endDate', 'Fine viaggio', tourDataEndDate)}
            onChange={onChangeCalendar('endDate', tourDataStartDate)}
            minDate={tourDataStartDate ? moment(tourDataStartDate).toDate() : null}
            date
            style={{ marginLeft: 5 }}
          />
          <Input
            error={startCheckErrorsGlobal ? !tourData.tripDays : false}
            {...inputProps('tripDays', 'Giorni', (tourData?.tripDays || ''))}
            onChange={updateItem}
            style={{ marginLeft: 5 }}
          />
          <Input
            error={startCheckErrorsGlobal ? !tourData.tripNights : false}
            {...inputProps('tripNights', 'Notti', tourData?.tripNights)}
            onChange={updateItem}
            style={{ marginLeft: 5 }}
          />
        </Flex>
        <Flex fw as js>
          <Input
            error={startCheckErrorsGlobal ? !tourData.description : false}
            {...inputProps('description', 'Titolo', tourData?.description)}
            onChange={updateItem}
            style={{ marginTop: 5 }}
          />
        </Flex>
      </Flex>

    </Card>
  )
}
