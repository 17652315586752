import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import { Text, Flex } from '@Common/Components'
import { invalidateQuery, useCInfiniteQuery, useCQuery } from '../Services'
import { FC, useHandleNavigate } from '@Common/Services'

const sortDate = (conversations) => (event) => {
  const order = event.order
  return addMetadata(conversations).sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return order === -1 ? (new Date(b.lastUpdate) - new Date(a.lastUpdate)) : (new Date(a.lastUpdate) - new Date(b.lastUpdate))
  })
}

const getNameConversation = (userData) => {
  if (Array.isArray(userData)) {
    if (userData.length === 1) {
      return userData[0]?.name
    }
    const firstTwoUserData = [userData[0], userData[1]]
    let intermediateString = ''
    firstTwoUserData.forEach((item = {}) => {
      intermediateString = intermediateString?.length ? intermediateString + ', ' + item.name : item.name
    })
    // caso array
    if (userData.length > 2) {
      const other = userData.splice(2) // esempio: su un array di 6, splice(2), 4 elementi vanno in other, i primi 2 rimangono in userData
      return intermediateString + ' più altri ' + other.length
    }
    return intermediateString // non faccio vedere full elenco, ma nella filter, filtra per tutti
  }
  return userData?.name || 'Mittente non indicato'
}

const addMetadata = (conversations) => conversations.map(({ userData, ...fields }) => ({
  ...fields,
  lastUpdateDisplay: moment(fields.lastUpdate).format(
    moment().isSame(moment(fields.lastUpdate), 'day') ? 'HH:mm' : 'DD/MM/YYYY'
  ),
  status: fields.nextActor === 'agency' ? 'Da leggere' : 'Letto',
  composedName: getNameConversation(userData)
})).filter(({ lastMessage, subject }) => lastMessage || subject)

const ListMessage = () => {
  const { data: badges = {} } = useCQuery('badges')
  const { data: conversations = [] } = useCInfiniteQuery('conversations')
  const newMessagesBadge = badges.newMessagesBadge || 0
  const navigate = useHandleNavigate()

  const handleSelection = async ({ _id: selectedConversation, nextActor }) => {
    if (nextActor === 'agency') {
      FC.client.service('conversations').patch(selectedConversation, { nextActor: 'none', newAgency: 0 })
        .then(() => invalidateQuery(['conversations', 'badges']))
    }
    navigate('/messages/' + selectedConversation)
  }

  return (
    <>
      <DataTable
        value={addMetadata(conversations)}
        sortOrder={1}
        sortField='status'
        paginator
        rows={10}
        emptyMessage='Nessuna conversazione da visualizzare'
        className='TableList'
        selectionMode='single'
        onSelectionChange={(e) => handleSelection(e.value)}
        rowClassName={({ status }) => ({ messageToRead: status === 'Da leggere' })}
        filterDisplay='row'
      >
        <Column
          field='composedName'
          header='Mittente'
          sortable
          filter
          showClearButton={false}
          showFilterMenu={false}
          filterMatchMode='contains'
          style={{ border: 'none' }}
          filterPlaceholder='Cerca mittente'
        />
        {conversations.find(conv => conv.subject) &&
          <Column
            field='subject'
            header='Titolo conversazione'
            sortable
            filter
            showClearButton={false}
            showFilterMenu={false}
            filterMatchMode='contains'
            style={{ border: 'none' }}
            filterPlaceholder='Cerca conversazione'
          />}
        <Column
          field='status'
          header='Status'
          sortable
          style={{ border: 'none', textAlign: 'center' }}
          filter
          showClearButton={false}
          showFilterMenu={false}
          filterMatchMode='contains'
        />
        <Column
          field='lastUpdateDisplay'
          header='Data Conversazione'
          style={{ textAlign: 'center', border: 'none' }}
          sortable
          sortFunction={sortDate(conversations)}
          filter
          showClearButton={false}
          showFilterMenu={false}
          filterMatchMode='contains'
        />
      </DataTable>
      <Flex>
        <Text style={{ fontSize: 11, marginTop: 8 }} value={`Ci sono ${newMessagesBadge} conversazioni da leggere`} />
      </Flex>
    </>
  )
}

export default ListMessage
