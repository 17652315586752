import React, { useEffect, useState, useRef } from 'react'
import { Card, Button, Spinner, Modal, Flex } from '@Common/Components'
import { ClientField } from '../Components/ClientField'
import { invalidateQuery, useCInfiniteQuery, useCQuery } from '../Services'
import state from '../State'
import { useWindowSize } from 'react-use'
import { FC, useHandleNavigate } from '@Common/Services'
import { ElementInList } from '../Components/ElementInList'
import COUNTRIES from '@Common/Resources/json/countriesPrefixPriority.json'
import { useParams } from 'react-router-dom'
import { UserNotification } from './UserNotifications'

const countryListDropDefault = COUNTRIES.map((elem, index) => ({
  label: elem.name,
  prefix: elem.dialCode,
  value: index,
  priority: elem.priority
}))

const NewClient = (props) => {
  const userId = useParams().userId?.replace('-', '.')
  const { data: user = {}, isSuccess } = useCQuery(['user', userId])
  const [client, setClient] = useState(user || {})
  const [countryListDrop, setCountryListDrop] = useState(countryListDropDefault)
  const [prefixCountry, setPrefixCountry] = useState({ label: 'Italy', prefix: '39' })
  const [quotesList, setQuotesList] = useState([])
  const [conversationsList, setConversationsList] = useState([])
  const [toursList, setToursList] = useState([])
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(true)
  const [colorRed, setColorRed] = useState(false)
  const [clientInModal, setClientInModal] = useState({})
  const { data: agency } = useCQuery('agency')
  const { data: quotes } = useCInfiniteQuery('quotes')
  const navigate = useHandleNavigate()
  let showModalNotification = useRef(null)

  useEffect(() => {
    window.location.pathname.includes('clients/new') && setBaseState()
  }, [window.location.pathname])

  useEffect(async () => {
    if (isSuccess) {
      setClient(user || {})
      getPrefixCountry()
      const phone = user?._id ? user?._id.substring(user?._id.indexOf('.') + 1) : ''
      const [conversationsList, toursList, quotesList] = await Promise.all([
        await getConversation(),
        await getTours(),
        await getQuotes()
      ])
      setPhone(phone)
      setConversationsList(conversationsList)
      setToursList(toursList)
      setQuotesList(quotesList)
      setLoading(false)
    }
  }, [isSuccess, user])

  const setBaseState = () => {
    setClient({})
    setCountryListDrop(countryListDropDefault)
    setPrefixCountry({
      label: 'Italy',
      prefix: '39'
    })
    setQuotesList([])
    setConversationsList([])
    setToursList([])
    setPhone('')
    setLoading(true)
    setColorRed(false)
  }

  const getQuotes = () => {
    if (!user?._id) return []
    if (!quotes) return []
    return quotes
      .filter(({ request: item }) => item.user === user._id)
      .map((ele) => ({
        type: 'requests',
        title: ele.request.toLocation || 'no title',
        id: `REQ-${ele.year}-${ele.progressive}`,
        action: () => navigate('/quotes/' + ele._id + '-0'),
        status: ele.status || 'no status',
        createdAt: ele.createdAt || 'no createdAt'
      }))
  }

  const getConversation = async () => {
    if (!user?._id) return []

    const getAction = (id) => async () => {
      await FC.client.service('conversations').patch(id, { nextActor: 'none', newAgency: 0 })
      invalidateQuery(['conversations', 'badges'])
      navigate('/messages/' + id)
    }

    const res = await FC.service('conversations').find({ query: { user: user?._id } })
    if (!res) return []
    return res.map((ele) => ({
      type: 'conversations',
      title: ele.subject || ele?.userData[0]?.name || 'no title',
      action: getAction(ele._id),
      createdAt: ele.lastUpdate || 'no createdAt'
    }))
  }

  const getTours = async () => {
    if (!user?._id) return []

    const clientTours = await FC.service('info').get('getClientInfo', {
      query: { clientNumber: user._id }
    })
    if (!clientTours) return []
    return clientTours.map((ele) => ({
      type: 'toursClient',
      title: ele.description || 'no title',
      id: ele._id,
      action: () => { },
      createdAt: ele.creationDate || 'no createdAt'
    }))
  }

  const clearState = () => {
    setClient({})
    setCountryListDrop([])
    setPrefixCountry({
      label: 'Italy',
      prefix: '39'
    })
    setQuotesList([])
    setPhone('')
    setColorRed(false)
  }

  const saveAllData = async () => {
    const clientJson = { ...client }
    if (!clientJson.name || !clientJson.lastName || !phone || !clientJson.email) {
      setColorRed(true)
      window.growl.show({
        severity: 'error',
        summary: 'Dati obbligatori mancanti ',
        detail: 'Inserire i dati nei campi obbligatori'
      })
      return false
    }
    setColorRed(false)

    // Existing User
    if (client._id) {
      const update = await FC.service('users').patch(client._id, { ...client })
      window.growl.show({
        severity: update ? 'success' : 'error',
        summary: update ? 'Utente aggiornato correttamente ' : 'Errore durante l\'aggiornamento'
      })
      invalidateQuery('user')
      return true
    }

    clientJson._id = `${prefixCountry.prefix}.${phone}`
    setClientInModal(clientJson)
    const [exists] = await FC.service('users').find({ query: { _id: clientJson._id } })
    if (exists) {
      window.growl.show({
        severity: 'error',
        summary: 'Utente già presente ',
        detail: 'Il numero di telefono del cliente è già presente'
      })
      return false
    }

    if (agency.services.app) {
      showModalNotification.current.show()
    } else {
      const userC = await FC.service('info').create({ type: 'generateNewClient', ...client })
      invalidateQuery('allClients')
      window.growl.show({
        severity: userC ? 'success' : 'error',
        summary: userC ? 'Utente creato correttamente' : 'Errore nella creazione, riprovare'
      })
    }

    clearState()
  }

  const updateCategory = (data) => setClient({ ...client, tripType: { ...client.tripType, ...data } })

  const onChangeState = (data) => {
    if (data.note) {
      // gestita così perchè se utente ha due agenzie, non POSSONO VEDERE le note dell'altra agenzia
      data = { note: { [agency._id]: data.note } }
    }
    !data.phone && data.phone !== ''
      ? setClient({ ...client, ...data })
      : setPhone(data.phone)
  }

  const getPrefixCountry = () => {
    const value = user?._id ? user?._id.split('.') : ['39']
    const city = countryListDrop.filter((item) => item.prefix === value[0] && item.priority === 0)
    city.length && changeSelectedCountry(city[0])
  }

  const changeSelectedCountry = (data) => setPrefixCountry(data)

  const config = state.config.state
  const { width: screenWidth } = useWindowSize()
  return (
    <>
      <Modal
        hideClose noDismissable ref={showModalNotification}
        setShow={(show) => { showModalNotification = show }}
        header='Invia un invito al cliente'
        content={<UserNotification client={clientInModal} showModalNotification={showModalNotification} />}
      />
      <Flex fw>
        <Flex fw style={{ justifyContent: screenWidth > config.mediumScreen ? 'space-between' : '' }}>
          <Card title='GENERALI' collapsable>
            <ClientField
              client={client}
              onChange={(update) => onChangeState(update)}
              updateCategory={(update) => updateCategory(update)}
              onChangeSelectedCountry={(update) => changeSelectedCountry(update)}
              prefixCountry={prefixCountry}
              countryListDrop={countryListDrop}
              phone={phone}
              colorRed={colorRed}
            />
          </Card>
          {loading
            ? <Spinner size={100} />
            : (
              <Flex fw>
                {conversationsList.length > 0 && (
                  <Card
                    title='MESSAGGI' collapsable style={{ width: '100%', marginTop: screenWidth > config.mediumScreen ? 20 : 0 }}
                  >
                    {conversationsList.map((ele, i) => <ElementInList key={'conv' + i} ele={ele} />)}
                  </Card>
                )}
                {toursList.length > 0 && (
                  <Card title='VIAGGI' collapsable style={{ width: '100%', marginTop: 20 }}>
                    {toursList.map((ele, i) => <ElementInList key={'trav' + i} ele={ele} />)}
                  </Card>
                )}
                {quotesList.length > 0 && (
                  <Card title='PREVENTIVI' collapsable style={{ width: '100%', marginTop: 20 }}>
                    {quotesList.map((ele, i) => <ElementInList key={'quot' + i} ele={ele} />)}
                  </Card>
                )}
              </Flex>)}
        </Flex>
        <Button label='Salva' icon='check' onClick={saveAllData} style={{ marginTop: 20 }} />
      </Flex>
    </>
  )
}

export default NewClient
