import React, { useRef, useState } from 'react'
import { Card, Flex, Modal, Spinner, Text, Tabs, TabHeader, CardResult } from '@Common/Components'
import { invalidateQuery, useCQuery } from '../Services'
import { FC } from '@Common/Services'

const DuplicatesManagement = () => {
  const refModal = useRef(null)
  const { data: agency = {}, isSuccess: isSuccessAgency } = useCQuery('agency')
  const { data: duplicatesClients = [], isSuccess: isSuccessDuplicates } = useCQuery(['duplicatesClients', agency._id])
  const [crmUserToMerge, setCrmUserToMerge] = useState({})
  const [appUserToMerge, setAppUserToMerge] = useState({})

  const mergeClient = async (crmUser, appUser) => {
    const mergedClient = {
      ...crmUser,
      id: crmUser.idAnagrafica,
      cel: appUser.cell,
      nome: crmUser.nome || appUser.nome,
      cogn: crmUser.cogn || appUser.cogn,
      mail: crmUser.mail || appUser.mail,
      ind: crmUser.ind || appUser.ind,
      cit: crmUser.cit || appUser.cit
    }
    delete mergedClient.idAnagrafica
    delete mergedClient.type
    await FC.client.service('crm').create({
      action: 'patchUser',
      mergedClient
    })
    invalidateQuery(['duplicatesClients', 'searchClient'])
  }

  return (!isSuccessDuplicates || !isSuccessAgency || duplicatesClients === 'NOACTION')
    ? <Spinner />
    : (
      <Flex as js>
        <Modal
          ref={refModal}
          header={
            <Flex>
              <Text value='Sicuro di voler unire i 2 contatti?' color='white' size={18} />
              <Flex row wrap style={{ padding: 20 }}>
                <CardResult {...appUserToMerge} onContactSelection={() => { }} />
                <CardResult {...crmUserToMerge} onContactSelection={() => { }} />
              </Flex>
            </Flex>
          }
          styleText={{ textAlign: 'center' }}
          yes='Si'
          no='No'
          action={() => mergeClient(crmUserToMerge, appUserToMerge)}
        />
        <Text style={{ width: '100%', textAlign: 'center' }} value='GESTIONE DUPLICATI' bold size={25} />
        <Text
          style={{ padding: 20 }}
          value="In ogni sezione trovi l'utente di AppTour con alcuni suggerimenti di possibili duplicati. Scegliendo di unire un duplicato,
         i suoi dati salvati sul CRM saranno arricchiti con quelli presenti nel contatto corrispondente di AppTour." size={16}
        />
        <Flex row wrap fw>
          {duplicatesClients?.map(({ crmUsers, appUser }, ind) => {
            return (
              <Card key={ind} style={{ margin: 5 }}>
                <Flex>
                  <Text value='UTENTE APPTOUR:' bold />
                  <CardResult {...appUser} handleContactSelection={() => { }} />
                  {/* <Text value={`POSSIBIL${crmUsers.length > 1 ? 'I' : 'E'} DUPLICAT${crmUsers.length > 1 ? 'I' : 'O'}:`} bold style={{ marginBottom: -5, marginTop: 10 }} /> */}
                  <Flex fw row wrap>
                    {crmUsers.map((crmUser, ind) =>
                      <CardResult
                        key={ind} {...crmUser}
                        buttonLabel='Unisci'
                        handleContactSelection={() => { }}
                        buttonFunction={
                          () => {
                            refModal?.current?.show()
                            setCrmUserToMerge(crmUser)
                            setAppUserToMerge(appUser)
                          }
                        }
                      />)}
                  </Flex>
                </Flex>
              </Card>
            )
          })}
        </Flex>
      </Flex>)
}

const TABS = [
  { icon: 'copy', title: 'GESTIONE DUPLICATI', content: <DuplicatesManagement /> }
]

const ClientManagement = () => {
  const [currentTab, setCurrentTab] = useState(0)
  return (
    <div
      style={{
        width: '100%',
        height: '85vh',
        padding: 30,
        overflow: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none'
      }}
    >
      <Tabs
        onTabSelection={setCurrentTab}
        currentTab={currentTab}
      >
        {TABS?.filter(({ disabled }) => !disabled).map((tab, index) => (
          <TabHeader
            title={tab?.title}
            key={index}
            index={index}
            icon={tab?.icon}
          >
            {tab?.content}
          </TabHeader>
        ))}
      </Tabs>
    </div>
  )
}

export default ClientManagement
