import React, { useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Checkbox } from 'primereact/checkbox'
import { Card, Text, Flex, Spinner, Modal, Button } from '@Common/Components'
import { FC } from '@Common/Services'
import { filterAppointments, invalidateQuery, useCQuery } from '../Services'

const AppointmentsList = () => {
  const [selectedItem, setSelectedItem] = useState({})
  const [sendSms, setSendSms] = useState(false)

  const { data: agency = {} } = useCQuery('agency')
  const { isSuccess, data: appointments = [] } = useCQuery('appointments')

  const today = filterAppointments(appointments, agency, 'd')
  const week = filterAppointments(appointments, agency, 'w')
  const all = filterAppointments(appointments, agency)

  const refModalDelete = useRef(null)
  const refModalInfo = useRef(null)

  const openModal = (singleAppointment, selectedModal) => {
    setSelectedItem(singleAppointment)
    selectedModal === 'info' ? refModalInfo?.current?.show() : refModalDelete?.current?.show()
  }

  // delete appointment ( da booked ad available ) e send email ed sms
  const deleteAppointment = async () => {
    try {
      const { dayString, client, infoCalendar, stateString, ...otherData } = selectedItem
      if (otherData.state === 'cancelled') {
        window.growl.show({
          severity: 'error',
          summary: 'Appuntamento',
          detail: 'Appuntamento già annullato'
        })
        return
      }
      const res = await FC.service('appointments').patch(otherData._id, { ...otherData, state: 'cancelled' })
      if (res._id) {
        const [{ _id: currAppId, ...appointment } = {}] = await FC.service('appointments').find({
          query: { agency: agency._id, calId: res.calId, dayId: res.dayId, type: 'day' }
        })
        const timeSlots = appointment.timeSlots.map((t) =>
          t.id === res.slotId ? { ...t, state: 'available' } : t
        )
        await FC.service('appointments').patch(currAppId, { timeSlots })
        window.growl.show({
          severity: 'success',
          summary: 'Appuntamento',
          detail: 'Cancellazione appuntamento avvenuta con successo'
        })
        invalidateQuery('appointments')
        // invio email ad entrambi ed SMS
        const message = `Gentile ${client}, \n L'appuntamento presso: ${agency.name
          }  \n in data ${dayString} alle ore ${res.fromDisplay} è stato annullato \n ${agency.telephone || agency.email
            ? `per maggiori info: \n ${agency.telephone || ''} \n ${agency.email || ''}`
            : ''
          } `
        const baseMessage = {
          // agency.email in PRODUZIONE, test usare email mranocchia@siapcn.it o farsi un utente in KPNQUEST
          from: agency.email,
          to: otherData.user.phone,
          message,
          type: 'sms',
          network: agency.network || 'siapMail',
          userMail: res.user.email
        }

        const config = await FC.service('sendMail').get(agency?.network || agency?._id)
        const mail = {
          config,
          message: {
            from: {
              address: config?.from || config?.auth?.user,
              name: 'AppTour Appuntamenti'
            },
            text: message,
            subject: 'Annullamento appuntamento',
            to: otherData?.user?.email,
            replyTo: agency?.email
          }
        }
        // hasSiapSms if field is true yes SMS else only email
        const handleSend = async () =>
          agency.hasSiapSms && sendSms ? FC.service('sms').create(baseMessage) : { _id: true }
        const [resSms, resMail] = await Promise.all([handleSend(), FC.service('sendMail').create(mail)])
        if (resSms._id && !resMail?.error) {
          window.growl.show({
            severity: 'success',
            summary: 'Appuntamento',
            detail: 'Notifica al cliente della cancellazione appuntamento avvenuta con successo'
          })
        } else {
          window.growl.show({
            severity: 'error',
            summary: 'Appuntamento',
            detail: 'Errore notifica cancellazione appuntamento, contattare il cliente'
          })
        }
      }
    } catch (error) {
      window.growl.show({
        severity: 'error',
        summary: 'Appuntamento',
        detail: 'Errore operazione cancellazione appuntamento'
      })
    }
  }

  const renderBtns = (row) => (
    <Flex row style={{ width: 135, gap: 7 }}>
      <Button round icon='info' tooltip='Info' onClick={() => openModal(row, 'info')} />
      <Button round icon='bin' tooltip='Cancella' onClick={() => openModal(row, 'delete')} />
    </Flex>
  )

  const columnProps = (field, header, filter, sortable) => ({
    field, header, sortable: !!sortable, filter: !!filter, filterPlaceholder: '🔍   ' + header, showClearButton: false, showFilterMenu: false, alignHeader: 'center', filterMatchMode: 'contains', style: { border: 'none', textAlign: 'center' }
  })

  const renderTable = (data) => (

    <DataTable value={data} paginator rows={10} emptyMessage='Nessun appuntamento da visualizzare' className='TableList' filterDisplay='row'>
      <Column {...columnProps('dayString', 'Data appuntamento', true, true)} />
      <Column {...columnProps('fromDisplay', 'Dalle ore', true, false)} />
      <Column {...columnProps('toDisplay', 'Alle ore', true, false)} />
      <Column {...columnProps('client', 'Cliente', false, false)} />
      <Column {...columnProps('stateString', 'Stato appuntamento', true, false)} />
      <Column {...columnProps('operator', 'Operatore', true, false)} />
      <Column body={renderBtns} style={{ border: 'none', textAlign: 'left' }} />
    </DataTable>
  )
  /* TODO: Change conditional */
  const renderInfoAppointment = (
    <Flex as style={{ margin: 20, color: 'white' }}>
      <Text color='white' value={`Calendario: ${selectedItem.infoCalendar}`} size={20} />
      {selectedItem.user?.name && selectedItem.user?.surname && (
        <Text
          color='white'
          value={`Cliente: ${selectedItem.user.name} ${selectedItem.user.surname}`}
          size={20}
        />
      )}
      {selectedItem.user?.phone && (
        <Text
          color='white'
          value={`Telefono: ${selectedItem.user.phone}`}
          size={20}
        />
      )}
      {selectedItem.user?.email && (
        <Text
          color='white'
          value={`Email: ${selectedItem.user.email}`}
          size={20}
        />
      )}
      {selectedItem.user?.typeAppointm && (
        <Text
          color='white'
          value={`Tipologia di Appuntamento: ${selectedItem.user.typeAppointment}`}
          size={20}
        />
      )}
      {selectedItem?.stateString && (
        <Text
          color='white'
          value={`Stato Appuntamento: ${selectedItem.stateString}`}
          size={20}
        />
      )}
      {selectedItem?.dayString && selectedItem?.fromDisplay && selectedItem?.toDisplay && (
        <Text
          size={20}
          color='white'
          value={`Il ${selectedItem.dayString} dalle ${selectedItem.fromDisplay} alle ${selectedItem.toDisplay}`}
        />
      )}
      {selectedItem.user?.request && (
        <Text
          size={20}
          color='white'
          value={`${selectedItem.user.request}`}
        />
      )}
      {agency.hasSiapSms
        ? (
          <Flex row style={{ marginTop: 10 }}>
            <Checkbox
              checked={sendSms}
              onChange={(e) => {
                setSendSms(e.checked)
              }}
            />
            <Text
              style={{ marginLeft: 10 }}
              color='white'
              value="Invia sms di notifica al cliente (il costo del messaggio è a carico dell'agenzia)"
            />
          </Flex>)
        : null}
    </Flex>
  )
  if (!isSuccess) return <Spinner />
  return (
    <Flex js fw fh style={{}}>
      <Modal
        ref={refModalDelete}
        header='Elimina Appuntamento'
        content={renderInfoAppointment}
        styleText={{ textAlign: 'center' }}
        yes='Cancella appuntamento'
        no='Chiudi'
        action={() => deleteAppointment()}
      />
      <Modal
        ref={refModalInfo}
        header='Informazioni Appuntamento'
        content={renderInfoAppointment}
        styleText={{ textAlign: 'center' }}
        onlyYes='Ok'
        action={() => refModalInfo.current?.hide()}
      />
      <Card style={{ width: '100%' }} collapsable title='Appuntamenti di oggi'>
        {renderTable(today)}
      </Card>
      <Card
        style={{ width: '100%', marginTop: 20 }}
        title='Appuntamenti nei 7 giorni successivi'
        collapsable
        collapsed
      >
        {renderTable(week)}
      </Card>
      <Card style={{ width: '100%', marginTop: 20 }} title='Tutti gli appuntamenti' collapsable collapsed>
        {renderTable(all)}
      </Card>
    </Flex>
  )
}
export default AppointmentsList
