import React from 'react'
import { Text, Icon } from '.'

export const SelectSingleSquare = ({ style: { on, off }, type, icon, text, value, onChange }) => {
  const selected = !value ? false : !!value
  const style = selected ? on : off
  return (
    <div
      style={{
        display: 'flex',
        border: '1px solid white',
        // borderColor: selected ? 'white' : backgroundColorGeneral(),
        // da mettere quando ci saranno i temi da gestire e il cambio colore su tema bianco ecc.....
        marginRight: 5,
        marginBottom: 5,
        width: 150,
        alignItems: 'center',
        borderRadius: 5,
        height: 30,
        justifyContent: 'center',
        backgroundColor: style.backgroundColor
      }}
      onClick={() => onChange({ [type]: selected ? 0 : 1 })}
    >
      <Icon style={{ alignSelf: 'center' }} name={icon} size={20} color={style.icon} />
      <Text style={{ marginLeft: 5, color: style.text }} value={text} />
    </div>
  )
}
