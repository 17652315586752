import React, { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'

import { Card, Input, Flex } from '@Common/Components'
import { FC } from '@Common/Services'
import state from '../../../State'
import ItemTemplate from '../ItemTemplate'

export default function Customer () {
  const { width: screenWidth } = useWindowSize()
  const config = state.config.state

  const [clientData, setClientData] = state.clientData.useState()
  const [tourActions, setTourActions] = state.tourActions.useState()
  const [tourData] = state.tourData.useState()
  const [startCheckErrorsGlobal, setStartCheckErrorsGlobal] = state.startCheckErrorsGlobal.useState()

  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState([])

  useEffect(() => {
    FC.service('info').get('getAllClient')
      .then((res) => setClients(res))
      .catch(() => setClients([]))

    return () => {
      setStartCheckErrorsGlobal(false)
    }
  }, [])

  const updateItem = (update) => {
    setClientData({ ...clientData, ...update })
    setTourActions({ ...tourActions, saved: false })
  }

  const filterClientText = (field, { query }) => {
    if (!Array.isArray(clients)) {
      console.error('clients is not an array or is undefined')
      setFilteredClients([])
      return
    }
    if (!field || typeof query === 'undefined' || query === null) {
      console.error('field or query is undefined or null')
      setFilteredClients(clients)
      return
    }
    const filtered = clients.filter((client) => {
      const fieldValue = client[field]
      return fieldValue && fieldValue.toLowerCase().includes(query.toLowerCase())
    })
    setFilteredClients(filtered)
  }

  const inputProps = (id, field, label, value) => ({
    id,
    field,
    label,
    value,
    minLength: 1,
    inputAutoComplete: true,
    suggestions: filteredClients,
    onChange: onChangeInput(id),
    onSelect: onSelectSuggestion,
    completeMethod: (e) => filterClientText(field, e),
    itemTemplate: (data) => <ItemTemplate data={data} />,
    error: startCheckErrorsGlobal ? !value : false

  })

  const onChangeInput = (field) => (e) => {
    state.startCheckErrorsGlobal.setState(false)
    updateItem({ [field]: e[field] })
  }

  const onSelectSuggestion = ({ value }) => {
    state.startCheckErrorsGlobal.setState(false)
    updateItem({
      phone: value._id,
      name: value.name,
      surname: value.lastName,
      email: value.email
    })
  }

  return (
    <Card title='Dati cliente' collapsable style={{ width: '100%', marginBottom: 20 }}>

      <Flex fw as js row style={{ marginTop: 10, flexWrap: screenWidth < config?.mediumScreen ? 'wrap' : null }}>
        <Input {...inputProps('phone', '_id', 'Telefono', clientData?.phone)} />
        <Input {...inputProps('name', 'name', 'Nome', clientData?.name)} style={{ marginLeft: 5 }} />
        <Input {...inputProps('surname', 'lastName', 'Cognome', clientData?.surname)} style={{ marginLeft: 5 }} />
        <Input {...inputProps('email', 'email', 'Email', clientData?.email)} style={{ marginLeft: 5 }} error={startCheckErrorsGlobal ? (!clientData?.email && !tourData?._id) : false} />
      </Flex>

    </Card>
  )
}
