import React, { useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { Text, Flex, Spinner, Button } from '..'
import { ReorderFiles } from './ReorderFiles'
import { Themes } from '../../../Apps/quotes/Services'
import { readFile } from '@Common/Services'

// const defaultFiles = [{ uri: 'uri1', title: 'title1' }, { uri: 'uri2', title: 'title2' }, { uri: 'uri3', title: 'title3' }, { uri: 'uri4', title: 'title4' }]
const defaultAccept = 'image/png,image/jpeg,image/jpg,image/bmp'

export default function UploadFile ({
  files = [],
  updateFiles,
  dragAndDropUpload = true,
  className,
  accept,
  title,
  titleTypeOfFile,
  onReorder
}) {
  const [uploading, setUploading] = useState(false)
  const [errorVisible, setErrorVisible] = useState(false)
  const [errorText, setErrorText] = useState('')
  const upload = useRef(null)

  const onDrop = (droppedFiles) => {
    const filesToUpload = []
    const mimeTypeAvailable = accept?.split(',') || defaultAccept?.split(',')
    droppedFiles.forEach((droppedFile) => {
      const isAcceptedFile = mimeTypeAvailable.find((mimeType) => mimeType?.trim()?.includes(droppedFile?.type?.trim()))
      if (isAcceptedFile) filesToUpload.push(droppedFile)
      else {
        setErrorText('Formato File non supportato')
        setErrorVisible(true)
        setTimeout(() => setErrorVisible(false), 4000)
      }
    })
    uploadFiles(filesToUpload)
  }

  // Devo aggiornare i files.
  const onDelete = (updatedFile) => {
    // Callback del padre
    updateFiles(updatedFile)
  }

  // Funzione che carica il file a video. Mette un campo uri contentente il b64 e needUpload per indicare in fase di salvataggio entità che il file deve essere mandato su AWS.
  const uploadFiles = async (filesToUpload = []) => {
    const newFiles = [...files]
    if (filesToUpload?.length > 7) return { ok: false, summary: 'Attenzione, è possibile selezionare un massimo di 7 foto', detail: 'Riprovare' }
    try {
      for (const file of filesToUpload) {
        if (file.size > 10000 * 1024) throw new Error('File troppo grande. Dimensione massima consentita: 10 MB')
        const base64 = await readFile(file)
        newFiles.push({ refFile: file, uri: base64, needUpload: true })
      }
      updateFiles(newFiles)
    } catch (e) {
      return { ok: false, summary: e?.message, detail: 'Errore' }
    }
  }

  const onChangeInputFile = async (e) => {
    setUploading(true)
    uploadFiles(e?.target?.files)
    setUploading(false)
  }

  const onClickBtnSfoglia = () => upload.current.click()

  const { getRootProps, isDragActive } = useDropzone({ onDrop })
  return (
    <div style={{ marginTop: 30, position: 'relative', marginBottom: 30, width: '100%' }}>
      <Flex
        row style={{
          justifyContent: 'space-between'
        }}
      >
        <Flex as js>
          {title && <Text bold title upCase style={{ marginBottom: 5 }} value={title} />}
          {titleTypeOfFile && <Text style={{ marginBottom: 5 }} value={titleTypeOfFile} />}
          {errorVisible && <Text style={{ marginBottom: 20 }} value={errorText} color='red' />}
        </Flex>
        {uploading
          ? <Spinner />
          : <Button rich icon='lens' label='Sfoglia' onClick={onClickBtnSfoglia} style={{ cursor: 'pointer' }} />}
      </Flex>
      <div {...getRootProps()}>
        <div style={{ ...styles.cardStyle, height: 'auto', ...(isDragActive && dragAndDropUpload ? { backgroundColor: 'rgba(255,255,255,0.8)' } : {}) }} className={className}>
          <Text title bold upCase color='grey' value={isDragActive && dragAndDropUpload ? 'Rilascia il file per effettuare il caricamento' : dragAndDropUpload ? 'Drag and Drop per caricare file e cambiare l\'ordine di visualizzazione' : 'Drag and Drop per cambiare l\'ordine di visualizzazione'} />
          <ReorderFiles files={files} onDelete={onDelete} onReorder={onReorder} />
        </div>
      </div>
      <input
        type='file'
        accept={accept || defaultAccept}
        ref={upload}
        style={{ display: 'none' }}
        onChange={onChangeInputFile}
        multiple
      />
    </div>
  )
}

const styles = {
  cardStyle: {
    position: 'relative',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 15,
    borderRadius: 20,
    backgroundColor: Themes.colors.translucent,
    borderStyle: 'dashed',
    borderColor: 'rgba(0, 0, 0, 0.2)'
  }
}
