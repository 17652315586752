import React from 'react'

import { Card } from '@Common/Components'
import state from '../../../State'
import { Other } from './Elements'
import PlusButton from '../PlusButton'
import { updateService, deleteService } from '../../../Functions'

export default function OtherServiceForm () {
  const [serviceForm] = state.serviceForm.useState()

  if (!serviceForm?.length) return null

  return (
    <Card
      key='other'
      title='ALTRO'
      collapsable={serviceForm.length > 0}
      style={{ marginBottom: '20px', width: '100%' }}
    >
      <PlusButton type='serviceForm' />
      {serviceForm.map((item, index) => (
        <Other
          key={index}
          index={index}
          item={item}
          onUpdate={updateService}
          onDelete={deleteService}
        />
      ))}
    </Card>
  )
}
