import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Flex, Spinner } from '@Common/Components'
import { useCQuery } from '../Services'
import state, { initiTourState } from '../State'
import { saveFunction } from '../Functions'
import { useHandleNavigate } from '@Common/Services'
import {
  Header,
  TourData,
  Customer,
  Documents,
  HotelForm,
  BarBottom,
  FlightForm,
  TrainForm,
  CarForm,
  OtherServiceForm,
  CruiseForm,
  PassengerForm,
  MoneyForm,
  Note,
  Footer
} from '../Components'

export default function Tour () {
  const tourId = useParams()?.tourId || ''

  const navigate = useHandleNavigate()

  const { data: user = {} } = useCQuery('user')
  const { data: tour = {}, isSuccess: isSuccessTour } = useCQuery(['tour', tourId])

  const [isSaving, setIsSaving] = useState(false)

  const [tourActions, setTourActions] = state.tourActions.useState()
  const [tourData] = state.tourData.useState()
  const setStartCheckErrorsGlobal = state.startCheckErrorsGlobal.useState()[1]

  useEffect(() => {
    try {
      if (!tourId) initiTourState('', {})
      if (isSuccessTour) initiTourState(tour.type, tour)
      if (!window?.location?.pathname.includes('tours/new')) setTourActions({ ...tourActions, resolver: tourData?.tourResolver })
    } catch { }
  }, [isSuccessTour, tourId])

  const onClickSave = async () => {
    setStartCheckErrorsGlobal(true)
    setIsSaving(true)
    const id = await saveFunction(user)
    setIsSaving(false)
    id && navigate('/tours/' + id)
  }

  if (!isSuccessTour) return <Spinner />
  return (
    <Flex fw>
      <Header />
      <Customer />
      <TourData />
      <Documents />
      <HotelForm />
      <FlightForm />
      <TrainForm />
      <CarForm />
      <OtherServiceForm />
      <CruiseForm />
      <PassengerForm />
      <MoneyForm />
      <BarBottom />
      <Note />
      {isSaving
        ? <Spinner />
        : <Button rich icon='check' label='Salva' style={{ width: 150, margin: 5, padding: 10, height: 30, cursor: 'pointer' }} onClick={onClickSave} />}
      <Footer tourId={tourId} />
    </Flex>
  )
}
