import { useCQuery as CommonUseCQuery, prefetchQueries as CommonPrefetchQueries, prefetchQuery as CommonPrefetchQuery, invalidateQuery as CommonInvalidateQuery } from '@Common/Services'
/* import state from '../State'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
} */

const queries = {

}

export const useCQuery = (queryKey) => CommonUseCQuery(queryKey, queries)

export const prefetchQuery = (queryKey) => CommonPrefetchQuery(queryKey, queries)

export const prefetchQueries = (queries) => CommonPrefetchQueries(queries)

export const invalidateQuery = (queryKey) => CommonInvalidateQuery(queryKey)
