import React from 'react'
import { AppContainer } from '@Common/Components'
import { Clients } from './clients'
import { Appointments } from './appointments'
import { Messages } from './messages'
import { AtlTour } from './atlTour'
import { Tours } from './tours'
import { Offers } from './offers'
import { Home } from './Home'
import { useCQuery } from '../../Common/Services'
import { checkRoute } from '@Common/Services'
import { Routes } from 'react-router-dom'

const routes = [
  { path: '/', page: 'Home', content: Home },
  { path: '/clients', page: 'Clients', content: Clients },
  { path: '/appointments', page: 'Appointments', content: Appointments },
  { path: '/messages', page: 'Messages', license: 'chat', content: Messages },
  { path: '/atlTour', page: 'AtlTour', license: 'app', hasAtl: true, content: AtlTour },
  { path: '/tours', page: 'Tours', license: 'app', content: Tours },
  { path: '/offers', page: 'OffersAll', license: 'offers', content: Offers }
]

export const App = () => {
  const { data: agency = {}, isSuccess: isSuccessAgency } = useCQuery('agency')
  const { data: user = {}, isSuccess: isSuccessUser } = useCQuery('user')

  return (
    <AppContainer>
      {(isSuccessAgency && isSuccessUser) && (
        <Routes>
          {routes.map((r, ind) => <React.Fragment key={ind}>{checkRoute(r, agency, user)} </React.Fragment>)}
        </Routes>
      )}
    </AppContainer>
  )
}
