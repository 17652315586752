import React from 'react'
import { Text, Card, Icon, Hover, Flex } from '@Common/Components'
import { useCQuery } from '../Services'
import state from '../State'
import { getHomeCards } from '../Components/HomeCards'
import { useWindowSize } from 'react-use'
import { useHandleNavigate } from '@Common/Services'

const DashboardElement = ({ cardTitle, buttons, isBigScreen, visible, service, targetServices }) => {
  const navigate = useHandleNavigate()
  return visible
    ? (
      <Card style={{ ...styles.smallContainer(isBigScreen ? 500 : 300), opacity: service ? 1 : 0.6 }}>
        {!service && <Text style={{ marginTop: -22 }} size={15} value='Modulo non attivo' />}
        <Text upCase bold title size={22} style={styles.titleStyle} value={cardTitle} />
        <div style={{ ...styles.convList, flexDirection: isBigScreen ? 'row' : 'column' }}>
          {buttons.map(({ title, icon, target }, index) => {
            const onClick = () => navigate(service ? target : targetServices)
            return (
              <Hover key={index} style={styles.hoverBaloon}>
                <Icon
                  name={icon}
                  text={title}
                  style={styles.circle}
                  size={45}
                  onClick={onClick}
                />
              </Hover>
            )
          })}
        </div>
      </Card>)
    : null
}

export const Home = () => {
  const { width: screenWidth } = useWindowSize()
  const { data: user = {} } = useCQuery('user')
  const config = state.config.state || {}
  const { isSuccess, data: agency } = useCQuery('agency')
  const navigate = useHandleNavigate()

  if (!isSuccess) return null
  const HomeCards = getHomeCards(agency)
  const isBigScreen = screenWidth > config.smallScreen
  const cards = HomeCards.filter((hc) => hc.isEnabled(agency.services))

  return (
    <Flex row wrap fw>
      <Flex row wrap fw fh>
        {!user.networkAdmin
          ? cards.map((element, index) => (
            <DashboardElement
              key={index}
              isBigScreen={isBigScreen}
              user={user}
              visible={element.isVisible(agency.serviceSettings, agency.hasAtl, agency.services.app)}
              service={element.isEnabled(agency.services)}
              {...element}
            />))
          : (
            <Card style={{ ...styles.smallContainer(isBigScreen ? 500 : 300) }}>
              <Text upCase bold title size={22} style={styles.titleStyle} value='Amministrazione utenti' />
              <div style={{ ...styles.convList, flexDirection: isBigScreen ? 'row' : 'column' }}>
                <Hover style={styles.hoverBaloon}>
                  <Icon
                    name='agencies'
                    text='Elenco agenzie'
                    style={styles.circle}
                    size={45}
                    onClick={() => navigate('/administration/agencies')}
                  />
                </Hover>
                <Hover style={styles.hoverBaloon}>
                  <Icon
                    name='add'
                    text='Nuovo'
                    style={styles.circle}
                    size={45}
                    onClick={() => navigate('/administration/newAgencies')}
                  />
                </Hover>
              </div>
            </Card>)}
      </Flex>
    </Flex>
  )
}

const styles = {
  smallContainer: (minWidth) => ({
    minWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    margin: 30
  }),
  convList: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  circle: {
    borderRadius: '50%',
    width: 90,
    height: 90,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto'
  },
  hoverBaloon: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30
  },
  titleStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20,
    marginRight: 20
  }
}
