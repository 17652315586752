import React from 'react'
import { Input, Button, FadeIn } from '@Common/Components'
import state from '../../../../State'
import { useWindowSize } from 'react-use'

const GRID_NORMAL = [
  `
'name name name surname surname surname '
' email email email email email email'
'phone phone phone phone age age`
]

const GRID_SMALL = [
  `'name name name'
'surname surname surname'
'email email email'
'phone phone phone'
'age age age'
`
]

const Passenger = ({ item = {}, onDelete, index, onUpdate }) => {
  const passengers = state.passengers.state
  const config = state.config.state
  const { width: screenWidth } = useWindowSize()

  const updateItem = (update) => {
    if (!onDelete) {
      onUpdate({ passengers, ...update })
    } else {
      const { _id = '' } = item
      onUpdate('passengers', { _id, ...update })
    }
  }

  const renderContent = () => (
    <>
      <div
        style={{
          display: 'grid',
          width: '100%',
          gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL[0] : GRID_SMALL[0],
          gridGap: '9px'
        }}
      >
        <Input
          style={{ gridArea: 'name' }}
          id='name'
          label='Nome:'
          onChange={(e) => updateItem(e)}
          value={onDelete ? item.name || '' : state.passenger.state?.name || ''}
        />
        <Input
          style={{ gridArea: 'surname' }}
          id='surname'
          label='Cognome:'
          onChange={(e) => updateItem(e)}
          value={onDelete ? item.surname || item.surname || '' : state.passengers.state?.surname || ''}
        />
        <Input
          style={{ gridArea: 'email' }}
          id='email'
          label='Email:'
          onChange={(e) => updateItem(e)}
          value={onDelete ? item.email || '' : state.passengers.state?.email || ''}
        />
        <Input
          style={{ gridArea: 'phone' }}
          id='phone'
          label='Cellulare:'
          onChange={(e) => updateItem(e)}
          value={onDelete ? item.phone || '' : state.passengers.state?.phone || ''}
        />
        <Input
          date
          monthNavigator
          yearNavigator
          yearRange='1900:2050'
          style={{ gridArea: 'age' }}
          id='age'
          keyfilter='int'
          label='Data di nascita:'
          onChange={(e) => updateItem(e)}
          value={onDelete ? item.age || '' : state.passenger.state?.age || ''}
        />
      </div>

      {onDelete && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            marginLeft: 5
          }}
        >
          <Button
            rich
            icon='bin'
            round
            style={{ marginLeft: 15 }}
            width={30}
            onClick={() => onDelete('passengers', item._id)}
          />
        </div>
      )}
    </>)

  return (
    <div>
      <div>
        {index > 0 && (
          <hr
            style={{
              height: 2,
              width: '100%',
              backgroundColor: 'white',
              border: 'none'
            }}
          />
        )}
      </div>
      {index > 0
        ? (
          <FadeIn style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </FadeIn>)
        : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </div>)}
    </div>
  )
}

export default Passenger
