import React from 'react'
import ItemDescription from './ItemDescription'
import TimelineItem from './TimelineItem'
import { Carousel } from 'primereact/carousel'
import { Flex } from '@Common/Components'

const itemTemplate = (tour) => {
  return (
    <Flex fw as fh>
      <TimelineItem tour={tour} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginLeft: 20, width: 1, border: '2px solid black' }} />
        <div>
          {tour.events.map((event, ind) => (
            <ItemDescription event={event} key={ind} />
          ))}
        </div>
      </div>
    </Flex>
  )
}

const TimelineNotVip = ({ tour }) => {
  return (
    <Carousel
      value={tour}
      numVisible={2}
      numScroll={1}
      orientation='vertical'
      itemTemplate={itemTemplate}
      verticalViewPortHeight='500px'
    />
  )
}
export default TimelineNotVip
