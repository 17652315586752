import { useRef, useState } from 'react'
import { Button, Modal } from '@Common/Components'
import GroupchatManager from './index'
import { filterMerge, handlerMerge, unmergeConversation, textTemplateMerge, getMergedConvButton } from './Functions'
import { useCQuery } from '../../Services'
import _ from 'lodash'

export const MergeChat = ({ conversation }) => {
  const modalChat = useRef(null)
  const { data: allListConversations = [] } = useCQuery('groupChatConversations')
  const { data: listConversationsToRemove = [] } = useCQuery(['mergedConversations', conversation.groupChat.tourId])
  const copylistConversations = _.differenceWith(allListConversations, [conversation], (first, second) => first._id === second._id)
  const [conversationToUnmerge, setConversationToUnmerge] = useState({})

  return (
    <>
      <Modal
        ref={modalChat}
        header="Unisci un'altra chat di gruppo a questa chat"
        content={
          <GroupchatManager
            conversation={conversation}
            filterFunction={(event, setFilteredClients) => {
              filterMerge(event, setFilteredClients, copylistConversations)
            }}
            buttonFunction={handlerMerge}
            emptyMessage='Nessuna conversazione unita'
            removeFunction={(setSearched) => { unmergeConversation(conversation, setSearched, conversationToUnmerge) }}
            modalHeader='Vuoi dividere questa conversazione?'
            inputField='subject'
            inputLabel='Titolo: '
            buttonIcon='chat'
            textTemplate={textTemplateMerge}
            buttonText='Unisci'
            renderList={(showRefModal, itemTemplate) => {
              return listConversationsToRemove.length ? listConversationsToRemove.map((conv) => { return getMergedConvButton(conv, showRefModal, setConversationToUnmerge, itemTemplate) }) : false
            }}
          />
        }
      />
      <Button
        icon='chat'
        label='Unisci chat di gruppo'
        style={{
          marginLeft: 20,
          padding: 5
        }}
        onClick={() => {
          // setModalAction('add')
          modalChat.current ? modalChat.current.show() : modalChat.show()
        }}
      />
    </>
  )
}
