import { Card, Flex, Text } from '@Common/Components'

const TimelineItem = ({ item }) => {
  return (
    <Card
      style={{
        padding: 10,
        paddingTop: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 0,
        width: '100%'
      }}
    >
      <Flex
        row fw js style={{
          marginLeft: 10
        }}
      >
        <Text
          style={{
            marginRight: 10,
            fontSize: 34,
            fontWeight: 'bold'
          }}
          value={item.date.date}
        />
        <Text
          style={{
            fontSize: 20,
            fontWeight: 'bold'
          }}
          value={item.date.longMonth}
        />
        <Text
          style={{
            fontSize: 20,
            marginLeft: 3,
            fontWeight: 'bold'
          }}
          value={item.date.years + ','}
        />
        <Text
          style={{
            fontSize: 18,
            marginLeft: 3
          }}
          value={item.date.dayName.toLowerCase()}
        />
      </Flex>
    </Card>
  )
}

export default TimelineItem
