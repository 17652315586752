import { Text, Flex } from '@Common/Components'
import { Separator } from './Separator'

export const UserInList = ({ user, onClick }) => (
  <div onClick={onClick} style={{ cursor: 'pointer', width: '100%' }}>
    <Flex js fw row wrap style={{ paddingLeft: 10 }}>
      <img
        src={user.avatar || 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'}
        alt='img user'
        style={{ maxWidth: 40, maxHeight: 40, borderRadius: '50%' }}
      />
      <Text style={{ marginLeft: 15 }} bold value={`${user.name} ${user.lastName}`} />
    </Flex>
    <Separator />
  </div>
)
