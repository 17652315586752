import React from 'react'

import { Button, Flex, Icon, Spinner, TextareaAuto } from '@Common/Components'

const handleKeypress = (send) => (e) => {
  if (e.code === 'Enter' && !e.shiftKey) {
    e.preventDefault()
    send()
  }
}

export const Composer = ({ emoji, update, send, currentMessage, onKeyPressed, pickFile, sending }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: 'calc(100% - 320px)',
        position: 'absolute',
        bottom: 10,
        left: 340,
        paddingRight: 40
      }}
    >
      <Icon
        name='emoticons'
        onClick={emoji}
        style={{
          position: 'absolute',
          left: 8,
          bottom: 6,
          cursor: 'pointer'
        }}
      />
      <Icon
        name='attached'
        onClick={pickFile}
        style={{
          position: 'absolute',
          left: 50,
          bottom: 6,
          cursor: 'pointer'
        }}
      />
      <TextareaAuto
        placeholder='Scrivi un messaggio (Enter per inviare, Maiusc + Enter per andare a capo)'
        style={{ paddingLeft: 100, paddingTop: 12, minHeight: 45 }}
        value={currentMessage}
        autoFocus
        onChange={(e) => update(e.target.value)}
        onKeyPress={handleKeypress(send)}
      />
      {sending
        ? <Flex style={{ marginLeft: 10, height: 44, width: 44 }}> <Spinner /></Flex>
        : <Button icon='send' iconSize={26} onClick={send} style={{ marginLeft: 10, height: 44, width: 44, borderRadius: 22 }} />}
    </div>
  )
}
