import React, { useRef } from 'react'
import { useWindowSize } from 'react-use'

import { Button, Flex, Text, Modal } from '@Common/Components'
import state from '../../../State'
import { clearAll, printPDF, showGrowl } from '../../../Functions'
import { useCQuery } from '../../../Services'

export default function Information () {
  const { width: screenWidth } = useWindowSize()
  const configState = state.config.state

  const { data: user = {} } = useCQuery('user')

  const [tourData, setTourData] = state.tourData.useState()
  const [tourActions, setTourActions] = state.tourActions.useState()
  const idViaggio = tourData._id?.split('@').pop() || ''

  const modalDeleteRef = useRef(null)

  const onClickCopy = () => {
    if (tourData?._id) {
      state.toursDocuments.setState([])
      showGrowl('info', `Attenzione, questo viaggio è la copia di ${state.tourData.state._id.split('@').pop()}`, 'Salvare per crearne una nuova ')
      setTourActions({ ...tourActions, copied: true, saved: false })
      setTourData({ ...tourData, status: 0 })
    } else showGrowl('error', 'Attenzione', 'Per poter copiare un viaggio è necessario prima crearne uno.')
  }

  const actionClearAll = () => {
    clearAll()
  }

  const onClickDelete = () => modalDeleteRef?.current?.show()

  const onClickPrint = async () => printPDF(user)
  // if (printed) invalidateQuery(['tours', 'tour'])

  const renderText = (field, value, style, styleText) => (
    <Flex row style={{ ...style }}>
      <Text value={`${field}`} title bold />
      <Text value={value} style={{ marginLeft: 5, ...styleText }} />
    </Flex>
  )

  return (
    <Flex wrap fw row jb as style={{ marginBottom: 10 }}>
      <Modal
        ref={modalDeleteRef}
        header='Si desidera cancellare tutti i campi ?'
        action={actionClearAll}
      />

      <Flex as style={{ marginLeft: 20 }}>
        {idViaggio && !tourActions.copied && renderText('ID:', idViaggio)}
        {tourData?.description && renderText('Descrizione:', tourData?.description, { marginTop: 5 })}
        {!tourActions?.saved && renderText('', idViaggio ? 'Le modifiche di questo viaggio non sono ancora state salvate' : 'Questo viaggio non è ancora stato salvato', { marginTop: 5 }, { color: '#d02f24', marginLeft: 0 })}
        {tourActions?.copied && renderText('', `Questo viaggio è una bozza originata da: ${idViaggio}`, { marginTop: 5 }, { marginLeft: 0 })}
        {tourData?.status !== 1 && !tourActions?.copied && idViaggio && renderText('', 'Questo viaggio è una bozza', { marginTop: 5 }, { marginLeft: 0 })}
        {tourData?.status === 1 && idViaggio && (
          <>
            {tourData?.tourResolver
              ? (
                <Flex row as js style={{ marginTop: 5 }}>
                  <Text
                    style={{ color: '#008800' }}
                    value={'Viaggio sincronizzato sull\'App del cliente (codice apptour:'}
                  />
                  <Text bold style={{ marginLeft: 5, color: '#008800' }} value={tourData?.tourResolver} />
                  <Text style={{ marginLeft: 2, color: '#008800' }} value=')' />
                </Flex>)
              : <Text style={{ color: '#008800', marginTop: 5 }} value={'Viaggio sincronizzato sull\'App del cliente'} />}
          </>
        )}
      </Flex>
      <Flex
        row fh
        style={{
          marginRight: 10,
          marginTop: screenWidth > configState.mediumScreen ? 0 : 10
        }}
      >
        <Button
          tooltip='Copia' rich round icon='copy'
          style={{ marginRight: 10 }}
          onClick={onClickCopy}
        />
        <Button
          tooltip='Cancella' rich round icon='bin'
          style={{ marginRight: 10 }}
          onClick={onClickDelete}
        />
        <Button
          tooltip='Stampa' rich round icon='print'
          onClick={onClickPrint}
          style={{ marginRight: 10 }}
        />
      </Flex>
    </Flex>
  )
}
