import React from 'react'
import { Carousel } from 'primereact/carousel'
import { Text, Icon } from '@Common/Components'

function RenderPassengers ({ passengers = [] }) {
  const renderIcon = (type) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 60,
        backgroundColor: 'white',
        borderRadius: 50
      }}
    >
      <Icon name={type} size={40} />
    </div>
  )
  const renderDivision = () => (
    <hr
      style={{
        height: 1,
        width: '80%',
        backgroundColor: 'white',
        border: 'none',
        marginBottom: 10,
        marginTop: 10
      }}
    />
  )
  const respondiveModule = [
    {
      breakpoint: '1550px',
      numVisible: 1,
      numScroll: 1
    }
  ]
  const itemTimeline = (item) => (
    <div
      style={{
        marginTop: -10,
        backgroundColor: 'rgba(255,255,255,0.6)',
        borderRadius: 20,
        padding: 20,
        paddingLeft: 5,
        overflow: 'none',
        boxShadow:
          '0px 4px 33px -40px rgba(100,100,100,0.2),0px 4px 10px -14px rgba(100,100,100,0.24), 0px 4px 13px -2px rgba(100,100,100,0.2)',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15, marginTop: 20 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            alignItems: 'center',
            marginLeft: 0
          }}
        >
          {/* Icone */}
          <div style={{ marginTop: 5 }}>{renderIcon('account')}</div>
          <div style={{ marginTop: 35 }}>{renderIcon('phone')}</div>
        </div>
        <div style={{ backgroundColor: 'white', marginLeft: 5, marginRight: -10 }}>
          {/* linea divisoria */}
          <hr style={{ width: '1px', backgroundColor: 'white', size: 30 }} />
        </div>
        <div
          style={{
            width: '100%',
            /*
              marginLeft: 10,
              marginTop: 20,
            */
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* dati passeggero */}
          <Text size={17} value={`${item.name || ''} ${item.surname || ''}`} />
          {renderDivision()}
          <Text size={17} value={item.phone} />
          {renderDivision()}
        </div>
      </div>
    </div>
  )

  return passengers.length
    ? (
      <Carousel
        value={passengers}
        itemTemplate={itemTimeline}
        numVisible={2}
        numScroll={1}
        dotsContainerClassName='dotsCarousel'
        style={{ paddingTop: 20 }}
        responsiveOptions={respondiveModule}
      />)
    : <Text size={16} value='Nessun passeggero presente' />
}

export default RenderPassengers
