import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './AppTour'

import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
// import 'react-dates/initialize'
// import 'primeflex/primeflex.css'
import 'react-dates/lib/css/_datepicker.css'

import '@Common/Services/Sentry'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

window.queryClient = queryClient

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
