import { FC, useCQuery as CommonUseCQuery, prefetchQueries as CommonPrefetchQueries, prefetchQuery as CommonPrefetchQuery, invalidateQuery as CommonInvalidateQuery } from '@Common/Services'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const queries = {
  tour: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, tourId] }) => tourId ? FC.service('tours').get(tourId) : null
  },
  tours: {
    config: queryConfig.rare,
    queryFn: () => FC.service('tours').find({})
  }
}

export const useCQuery = (queryKey) => CommonUseCQuery(queryKey, queries)

export const prefetchQuery = (queryKey) => CommonPrefetchQuery(queryKey, queries)

export const prefetchQueries = (queries) => CommonPrefetchQueries(queries)

export const invalidateQuery = (queryKey) => CommonInvalidateQuery(queryKey)

// INFINITE QUERIES

const infiniteQueries = {
  infiniteTours: {
    firstPageLength: 20,
    pageLength: 100,
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1 }) =>
      // Definisco la dimensione della prima pagina e delle successive, in modo da avere una risposta velocissima per la prima pagina e più lenta per le successive
      FC.client.service('tours').find({
        query:
        {
          $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
          $limit: pageParam === 1 ? firstPageLength : pageLength,
          $sort: { _id: -1 }
        }
      }),
    noInitalPrefetch: true
  }
}

export const useCInfiniteQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config, firstPageLength = 50, pageLength = 50 } = { ...infiniteQueries, ...extraQueries }?.[queryKey[0]] || queryNotFound

  const { data: { pages = [] } = {}, isSuccess, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
    queryKey,
    queryFn(firstPageLength, pageLength),
    {
      // devo fetchare nuova pagina se sono della lunghezza massima stabilita (firstPageLength o pageLength)
      getNextPageParam: (lastPage, pages) =>
        (pages?.length === 1)
          ? (lastPage?.length === firstPageLength) ? pages?.length + 1 : null
          : (lastPage?.length === pageLength) ? pages?.length + 1 : null,
      ...config
    })

  useEffect(() => !isFetching && !isFetchingNextPage && hasNextPage && fetchNextPage(), [isFetchingNextPage, isFetching])

  return { data: pages?.flat(), isSuccess, isFetchingNextPage }
}
