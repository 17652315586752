import moment from 'moment'
import { Text, Flex } from '@Common/Components'

const Separator = () => <div style={{
  width: '100%',
  height: 1,
  marginLeft: 10,
  marginTop: 10,
  marginBottom: 10,
  backgroundImage: 'linear-gradient(to right, white 50%, rgba(255, 255, 255, 0.4) 50%)'
}}
                        />

export const ElementInList = ({ ele }) => {
  const handleClick = ele.action
  const rightElement = ele.type === 'client'
    ? `${ele.createdAt && moment(ele.createdAt).format('DD/MM/YYYY') !== 'Invalid date'
    ? moment(ele.createdAt).format('DD/MM/YYYY')
    : 'no Data'
  } - da : ${ele.price ? ele.price : 'no price'} €`
    : `${ele.createdAt && moment(ele.createdAt).format('DD/MM/YYYY') !== 'Invalid date'
    ? moment(ele.createdAt).format('DD/MM/YYYY')
    : 'no Data'
  }`

  return (
    <>
      <Flex
        onClick={handleClick} row jb fw style={{
          marginTop: 10,
          marginLeft: 20,
          marginRight: 20,
          cursor: ele.type !== 'toursClient' ? 'pointer' : ''
        }}
      >
        <Flex as style={{ width: '40%' }}>
          <Text bold value={ele.title} />
        </Flex>
        {ele.id && <Text bold value={ele.id} />}
        <Text italic size={13} value={rightElement} style={{ marginRight: 20 }} />
      </Flex>
      <Separator />
    </>
  )
}
