import React from 'react'
import { Text, Button } from '@Common/Components'
import Key from './Key'
import TableDocuments from './TableDocuments'

const canceledObj = {
  'AN - Annullata': true,
  'AP - Annulata con penale': true,
  Annullato: true,
  Void: true
}

const type = {
  PRA: ['La pratica', 'a'],
  'BGT-A': ['Il biglietto', 'o'],
  'BGT-V': ['Il biglietto', 'o'],
  VOU: ['Il voucher', 'o'],
  VIP: ['La pratica', 'a'],
  TC: ['La pratica', 'a']
}

function DocumentCardBody ({
  atlDataDefault,
  atlDocuments,
  renderActions,
  renderStatus,
  webDocuments,
  editorTitle,
  upload,
  addNewDocuments
}) {
  if (canceledObj[atlDataDefault.statusAtl]) {
    return (
      <Text
        value={`${type[atlDataDefault.type][0]} è annullat${type[atlDataDefault.type][1]
          }. I documenti non sono visualizzabili.`}
      />
    )
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginTop: 30,
          marginBottom: 30
        }}
      >
        <Text value='Documenti provenienti da Atlante' bold title style={{ textAlign: 'left' }} />
      </div>
      <TableDocuments
        documents={atlDocuments}
        editable={false}
        emptyMessage='Nessun documento generato da Atlante'
        renderActions={renderActions}
        renderStatus={renderStatus}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginTop: 60,
          marginBottom: 30
        }}
      >
        <Text value='Documenti aggiuntivi' bold title />
        <Text value='Clicca nella cella del titolo per aggiungerne uno ad un documento' />
      </div>
      <TableDocuments
        documents={webDocuments}
        editable
        emptyMessage='Nessun documento aggiuntivo'
        renderActions={renderActions}
        renderStatus={renderStatus}
        editorFunction={editorTitle}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end' /* , marginTop: 60, marginBottom: 30 */
        }}
      >
        <Button
          rich
          icon='lens'
          label='Allega nuovi documenti'
          onClick={() => upload && upload.current && upload.current.click()}
          style={{ cursor: 'pointer', marginRight: 10 }}
        />
      </div>
      <input
        type='file'
        accept={'application/pdf, text/*, image/png,image/jpeg,image/jpg,image/bmp'}
        ref={upload}
        style={{ display: 'none' }}
        onChange={addNewDocuments}
        multiple
      />
      <Key />
    </>
  )
}

export default DocumentCardBody
