import React from 'react'
import moment from 'moment'
import { Input, Button } from '@Common/Components'
import state from '../../../../State'

const Money = ({ item = {}, onDelete, onUpdate }) => {
  const updateItem = (update) => {
    if (!onDelete) {
      onUpdate({ ...state.clientData.state, ...update })
    } else {
      const { _id = '' } = item
      onUpdate('moneyForm', { _id, ...update })
    }
  }

  const { main, input } = styles

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
      <div style={main}>
        <Input
          id='id'
          label='ID Viaggio:'
          style={input}
          onChange={(e) => updateItem(e)}
          value={item.id || ''}
        />
        <Input
          date
          id='date'
          label='Data'
          style={input}
          onChange={(e) => updateItem(e)}
          value={item.date ? moment(item.date).toDate() : ''}
        />
        <Input
          id='total'
          label='Totale:'
          style={input}
          keyfilter='money'
          onChange={(e) => updateItem(e)}
          value={item.total || ''}
        />
        <Input
          id='payed'
          label='Incassato:'
          style={input}
          keyfilter='money'
          onChange={(e) => updateItem(e)}
          value={item.payed || ''}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          marginLeft: 15
        }}
      >
        <Button
          rich
          icon='bin'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => onDelete('moneyForm', item._id)}
        />
      </div>
    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  ever: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  input: {
    width: '100%',
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 5
  }
}

export default Money
