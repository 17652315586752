import React from 'react'

import { Card } from '@Common/Components'
import state from '../../../State'
import { Passenger } from './Elements'
import PlusButton from '../PlusButton'
import { updateService, deleteService } from '../../../Functions'

export default function PassengerForm () {
  const [passengers] = state.passengers.useState()

  if (!passengers?.length) return null

  return (
    <Card
      key='passengers'
      title='PASSEGGERI'
      collapsable={passengers.length > 0}
      style={{ marginBottom: '20px', width: '100%' }}
    >
      <PlusButton type='passengers' />
      {passengers.map((item, index) => (
        <Passenger
          key={index}
          index={index}
          item={item}
          onUpdate={updateService}
          onDelete={deleteService}
        />
      ))}
    </Card>
  )
}
