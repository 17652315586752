import React, { useState, useRef } from 'react'

import { Text, Button, Modal, Flex } from '@Common/Components'
import { showGrowl, getUpdatedLogs } from '../../Functions'
import Table from './Table'
import { FC } from '@Common/Services'
import { useCQuery, invalidateQuery } from '../../Services'

export default function TabOffers ({ offers, drafted = false }) {
  const { data: user = {} } = useCQuery('user')

  const [selectedOffers, setSelectedOffers] = useState([])

  const showDeleteAll = useRef(null)
  const showPublishSelected = useRef(null)

  const dataTableOffers = offers.map((of) => ({
    ...of,
    operator: of.logs
      ? Array.isArray(of.logs) && of.logs.length
          ? of.logs[0].operator
          : of.logs.operator || of.logs.user
      : 'nessuna ultima modifica'
  }))

  const onClickDeleteSelected = () => {
    if (selectedOffers.length > 0) showDeleteAll?.current?.show()
    else showGrowl('error', 'Selezionare almeno una offerta da eliminare', 'Selezionane una dalla tabella')
  }

  const onClickPublishSelected = () => {
    if (selectedOffers.length > 0) showPublishSelected?.current?.show()
    else showGrowl('error', 'Selezionare almeno una offerta da pubblicare', 'Selezionane una dalla tabella')
  }

  const publishSelected = async () => {
    try {
      for (const o of selectedOffers) {
        const offerLogs = getUpdatedLogs(user, 'Ha pubblicato l\'offerta', o?.logs)
        await FC.service('offers').patch(o._id, { status: 1, logs: offerLogs })
      }
      setSelectedOffers([])
      invalidateQuery('offers')
    } catch {
      showGrowl('error', 'Attenzione, errore', 'Impossibile pubblicarel la/e offerta/e selezionate')
      setSelectedOffers([])
      invalidateQuery('offers')
    }
  }

  return (
    <>

      <Modal
        ref={showPublishSelected}
        header={`Sicuro di voler pubblicare l${selectedOffers.length === 1 ? 'a' : 'e'} offert${selectedOffers.length === 1 ? 'a' : 'e'
          }?`}
        action={publishSelected}
      />
      <Flex row as fw je height={35} style={{ position: 'relative', marginTop: 10, paddingRight: 2, marginBottom: 40 }}>
        <Button
          icon='bin'
          label='Elimina selezionati'
          style={{ marginRight: 16 }}
          onClick={onClickDeleteSelected}
        />
        {drafted &&
          <Button
            icon='go'
            label='Pubblica offerta'
            style={{ marginRight: 16 }}
            onClick={onClickPublishSelected}
          />}
      </Flex>
      <Table
        offers={dataTableOffers}
        selectedOffers={selectedOffers}
        setSelectedOffers={setSelectedOffers}
        showDeleteAll={showDeleteAll}
      />
      <Flex js>
        <Text value={`Hai ${offers.length} offerte`} />
        {selectedOffers.length > 0 && <Text value={`Hai selezionato ${selectedOffers.length} offert${selectedOffers.length === 1 ? 'a' : 'e'} `} />}

      </Flex>
    </>
  )
}
