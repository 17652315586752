import React from 'react'

import { Flex, Button } from '@Common/Components'
import { addService } from '../../Functions'

const PlusButton = ({ type }) => (
  <Flex as fw je style={{ marginBottom: 20 }}>
    <Button
      rich icon='plus'
      round
      style={{ margin: 3 }}
      width={30}
      onClick={() => addService(type)}
    />
  </Flex>
)

export default PlusButton
