// File dove fare le funzioni per le pratiche app.
import moment from 'moment'

import { toCamelCase } from './Tours'

export const getHotel = (hotel) => {
  const hotelName = hotel.name || ''
  return {
    type: 'hotel',
    order: 2,
    title: `Hotel ${toCamelCase(hotelName.toLowerCase().replace('hotel', ''))}`,
    details: [
      {
        text: hotel.description || ''
      },

      {
        text: hotel.nights && `${hotel.nights} 'Notti`
      }
    ],

    obj: hotel
  }
}

export const getFlight = (flight) => {
  const { startTime, endTime = '' } = getStartAndEndTime(flight.startHour, flight.endHour)
  const startAirport = flight.startAirport || ''
  const endAirport = flight.endAirport || ''
  const code = flight.code || ''
  let endObj = {}
  if (endTime !== '') {
    endObj = {
      text: `A ${endAirport}`,
      time: endTime
    }
  }

  return {
    type: 'flight',
    order: 1,
    title: flight.description ? `${flight.description}` : 'Volo',
    details: [
      {
        text: flight.startAirport ? `Da ${startAirport}` : 'Partenza',
        time: startTime
      },
      endObj,
      {
        text: flight.code && `Numero del volo ${code}`
      }
    ]
  }
}

export const getTrain = (train) => {
  const { startTime, endTime = '' } = getStartAndEndTime(train.startHour, train.endHour)
  const endStation = train.endStation || ''
  let endObj = {}
  if (endTime !== '') {
    endObj = {
      text: `A ${endStation}`,
      time: endTime
    }
  }
  return {
    type: 'train',
    order: 3,
    title: train.description,
    obj: train,
    details: [
      {
        text: train.startStation ? `Da ${train.startStation}` : 'Partenza',
        time: startTime
      },
      endObj
    ],
    actions: [
      {
        type: 'document',
        payload: 'FlightTicket'
      }
    ]
  }
}

export const getTransfer = (transfer) => {
  const transfers = []

  transfers.push({
    date: getDayObj(moment(transfer.startDate)),
    type: 'car',
    order: 4,
    title: 'Transfer',
    details: [
      {
        text: transfer.description
      },
      {
        text: transfer.company && `'Company': ${transfer.company}`
      }
    ],
    obj: transfer
  })
  if (transfer.endDate && transfer.startDate !== transfer.endDate) {
    transfers.push({
      date: getDayObj(moment(transfer.endDate)),
      type: 'car',
      order: 4,
      title: 'Transfer',
      details: [
        {
          text: transfer.description
        },
        {
          text: transfer.company && `Company: ${transfer.company}`
        }
      ],
      obj: transfer
    })
  }
  return transfers
}

export const getCruise = (cruise) => {
  const cruises = []
  const { startTime, endTime = '' } = getStartAndEndTime(cruise.startHour, cruise.endHour)
  const startPort = cruise.startPort ? `da ${cruise.startPort}` : ''
  const endPort = cruise.endPort ? `a ${cruise.endPort}` : ''
  let endObj = {}
  if (endTime !== '') {
    endObj = {
      text: `A ${endPort}`,
      time: endTime
    }
  }
  cruises.push({
    type: 'cruise',
    title: cruise.description,
    order: 5,
    date: getDayObj(moment(cruise.startDate)),
    details: [
      {
        text: cruise.startPort ? `Da ${startPort}` : 'Partenza',
        time: startTime
      }
    ]
  })

  if (cruise.endDate && cruise.startDate !== cruise.endDate) {
    cruises.push({
      date: getDayObj(moment(cruise.endDate)),
      type: 'cruise',
      order: 5,
      title: cruise.description,
      details: [endObj],
      actions: [
        {
          type: 'document',
          payload: 'Documenti crociera'
        }
      ]
    })
  }
  return cruises
}

export const getService = (service) => ({
  type: 'service',
  order: 7,
  notVip: true,
  title: 'Servizi Richiesti',
  details: [
    {
      text: `${service.description || ''}${service.description ? '\n' : ''}${service.address || ''} ${service.num || ''} ${service.city || ''}${(service.address || service.num || service.city) ? '\n' : ''}${service.cap || ''} ${service.province || ''}${(service.cap || service.province) ? '\n' : ''}${service.telephone || ''}`,
      icon: service.icon || 'ticket'
    }
  ]
})

export const getInsurance = (insurance) => ({
  type: 'insurance',
  order: 8,
  title: 'Assicurazione',
  details: [
    {
      text: insurance.insuranceName
    }
  ]
})

export const getRental = (rent) => {
  const rentals = []
  const startTime = moment(rent.startDate)
  const endTime = moment(rent.endDate)
  const pickUpPoint = rent.pickUpPoint ? `'Inizio noleggio' 'a' ${rent.pickUpPoint}` : 'Inizio del noleggio'
  const dropOffPoint = rent.dropOffPoint ? `Fine del noleggio a ${rent.dropOffPoint}` : 'Fine del noleggio'
  if (startTime.isSame(endTime, 'day')) {
    rentals.push({
      date: getDayObj(startTime),
      type: 'car',
      title: 'Noleggio',
      order: 6,
      details: [
        {
          text: pickUpPoint,
          time: startTime.format('HH:mm')
        },

        {
          text: dropOffPoint,
          time: endTime.format('HH:mm')
        },
        {
          text: rent.description || ''
        },
        {
          text: rent.company && `'Compagnia': ${rent.company}`
        }
      ],
      obj: rent
    })
  } else {
    // Caso in cui noleggio un giorno e restituisco un altro giorno.
    // Devo mettere due eventi diversi.
    rentals.push(
      {
        date: getDayObj(startTime),
        type: 'car',
        title: 'Noleggio',
        order: 6,
        details: [
          {
            text: pickUpPoint,
            time: startTime.format('HH:mm')
          },
          {
            text: rent.description
          },
          {
            text: rent.company && `Compagnia: ${rent.company}`
          }
        ],
        obj: rent
      },
      {
        date: getDayObj(endTime),
        type: 'car',
        title: 'Noleggio',
        order: 6,
        details: [
          {
            text: rent.description
          },
          {
            text: rent.company && `'Compagnia': ${rent.company}`
          },
          {
            text: dropOffPoint,
            time: endTime.format('HH:mm')
          }
        ],
        obj: rent
      }
    )
  }
  return rentals
}

const getDayObj = (dateSt) => {
  const date = dateSt === undefined || dateSt === '' ? moment() : moment(dateSt, 'DD/MM/YYYY')
  const dateObj = date.toObject()
  dateObj.longMonth = toCamelCase(date.format('MMMM'))
  dateObj.shortMonth = toCamelCase(date.format('MMM'))
  dateObj.shortYear = date.format('YY')
  dateObj.dayName = toCamelCase(date.format('dddd'))
  dateObj.dayShortName = toCamelCase(date.format('ddd'))
  dateObj.timestamp = date.unix()
  dateObj.moment = date
  return dateObj
}

const getStartAndEndTime = (startHour, endHour) => {
  const formatCheck = (data) => !!Date.parse(data)

  const startTime = formatCheck(startHour) ? moment(startHour).format('HH:mm') : startHour
  const endTime = formatCheck(endHour) ? moment(endHour).format('HH:mm') : endHour

  return {
    startTime,
    endTime
  }
}
