import React from 'react'
import moment from 'moment'
import { Text } from '@Common/Components'
import state from '../../../State'
import { useWindowSize } from 'react-use'

const GRID_NORMAL = `
'description startDate endDate dataReg total toPay'

`
const GRID_MID = `
'description description description description description description'
'startDate startDate endDate endDate dataReg dataReg'
'total total total toPay toPay toPay'
`

const SIZE_TEXT = 15
function PraDisplay ({ data }) {
  const { width: screenWidth } = useWindowSize()
  const config = state.config.state
  const renderDate = (date) => (
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
      <Text size={50} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('DD')} />
      <div style={{ marginTop: 14, marginLeft: 5 }}>
        <Text size={17} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('MMMM')} />
        <Text size={17} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('YYYY')} />
      </div>
    </div>
  )

  const renderDescription = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gridArea: 'description',
        justifyContent: 'center',
        marginBottom: screenWidth > config.mediumScreen + 150 ? 0 : 30
      }}
    >
      <div
        style={{
          ...Styles.divContainer,
          minWidth: 150
        }}
      >
        <Text size={15} value='Descrizione:' style={{ marginRight: 20 }} />
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text size={15} bold value={data.description} style={{ marginRight: 20, width: '100%' }} />
        </div>
      </div>
      {screenWidth > config.mediumScreen + 150 ? renderDivision() : ''}
    </div>
  )
  const renderStartDate = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        gridArea: 'startDate',
        justifyContent: 'center',
        marginBottom: screenWidth > config.mediumScreen + 150 ? 0 : 30
      }}
    >
      <div
        style={{
          ...Styles.divContainer
        }}
      >
        <Text size={SIZE_TEXT} value='Inizio viaggio:' style={{ marginRight: 20 }} />

        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: screenWidth > config.mediumScreen + 150 ? 0 : -20
          }}
        >
          {renderDate(data.startDate)}
        </div>
      </div>
      {renderDivision()}
    </div>
  )
  const renderEndDate = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        gridArea: 'endDate',
        justifyContent: 'center',
        marginBottom: screenWidth > config.mediumScreen + 150 ? 0 : 30
      }}
    >
      <div
        style={{
          ...Styles.divContainer
        }}
      >
        <Text size={SIZE_TEXT} value='Fine viaggio:' style={{ marginRight: 20 }} />
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: screenWidth > config.mediumScreen + 150 ? 0 : -20
          }}
        >
          {renderDate(data.endDate)}
        </div>
      </div>
      {renderDivision()}
    </div>
  )
  const renderDataReg = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        gridArea: 'dataReg',
        justifyContent: 'center',
        marginBottom: screenWidth > config.mediumScreen + 150 ? 0 : 30
      }}
    >
      <div
        style={{
          ...Styles.divContainer
        }}
      >
        <Text size={SIZE_TEXT} value='Data registrazione:' style={{ marginRight: 20 }} />
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: screenWidth > config.mediumScreen + 150 ? 0 : -20
          }}
        >
          {renderDate(data.registrationDate)}
        </div>
      </div>
      {renderDivision()}
    </div>
  )
  const renderTotal = () => (
    <div style={{ display: 'flex', flexDirection: 'row', gridArea: 'total', justifyContent: 'center' }}>
      <div
        style={{
          ...Styles.divContainer
        }}
      >
        <Text size={SIZE_TEXT} value='Costo totale:' style={{ marginRight: 20 }} />
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text size={40} value={`${data.tourAmount}€`} style={{ marginRight: 20 }} />
        </div>
      </div>
      {screenWidth > config.mediumScreen + 150 ? renderDivision() : ''}
    </div>
  )
  const renderToPay = () => (
    <div style={{ display: 'flex', flexDirection: 'row', gridArea: 'toPay', justifyContent: 'center' }}>
      <div
        style={{
          ...Styles.divContainer
        }}
      >
        <Text size={SIZE_TEXT} value='Totale pagato:' style={{ marginRight: 20, width: '100%' }} />
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text size={40} value={`${data.tourAmountPaid}€`} style={{ marginRight: 20, width: '100%' }} />
        </div>
      </div>
      {screenWidth > config.mediumScreen + 150 ? renderDivision() : ''}
    </div>
  )

  const renderDivision = () => (
    <div
      style={{
        width: 2,
        height: '100%',
        backgroundColor: 'white',
        border: '1px solid #32324e',
        maxHeight: 97
      }}
    />
  )

  return (
    <div
      style={{
        display: 'grid',
        width: '100%',
        justifyContent: 'center',
        gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL : GRID_MID,
        gridGap: '9px'
      }}
    >
      {renderDescription()}
      {renderStartDate()}
      {renderEndDate()}
      {renderDataReg()}
      {renderTotal()}
      {renderToPay()}
    </div>
  )
}
const Styles = {
  divContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 20
  }
}

export default PraDisplay
