import React from 'react'
import ClientsGeneral from './Pages/ClientsGeneral'
import { Navigate, Route } from 'react-router-dom'
import ClientManagement from './Pages/ClientManagement'
import NewClient from './Pages/NewClient'

export const Clients = (
  <>
    <Route path='/clients/*' element={<Navigate to='/clients/list' />} />
    <Route path='/clients/list' element={<ClientsGeneral />} />
    <Route path='/clients/new' element={<NewClient />} />
    <Route path='/clients/:userId' element={<NewClient />} />
    <Route path='/clients/management' element={<ClientManagement />} />
  </>
)
