import React, { useState, useRef } from 'react'
import { Button, Card, Flex, Modal, Input, Text } from '@Common/Components'
import state from '../../State'

const DEFAULT_IMG = 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'

const GroupchatManager = ({ renderList, conversation, buttonFunction, emptyMessage, removeFunction, modalHeader, inputField, inputLabel, buttonIcon, textTemplate, buttonText, filterFunction, extraUsers }) => {
  const [filteredClients, setFilteredClients] = useState([])
  const [searched, setSearched] = useState('')
  const [selected, setSelected] = useState({})
  const config = state.config.state

  let refModal = useRef(null)

  const renderEmptyText = (value) => <Text style={{ width: '100%', marginTop: 5, backgroundColor: 'white', padding: 10, borderRadius: 20 }} center value={value} />
  const showRefModal = () => { refModal.current ? refModal.current.show() : refModal(); return true }

  const itemTemplate = (data) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            display: 'block',
            width: 50,
            height: 50,
            borderRadius: '50%',
            border: '2px solid white',
            backgroundImage: `url(${data.avatar
              ? data.avatar.includes(config.awsBaseURL)
                ? data.avatar
                : config.awsBaseURL + data.avatar
              : DEFAULT_IMG
              })`,
            backgroundPosition: 'top center',
            backgroundSize: 'cover',
            margin: 20
          }}
        />
        <Flex>
          {textTemplate(data)}
        </Flex>
      </div>
    )
  }

  return (
    <Card
      style={{
        minHeight: 200,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Modal
        setShow={(show) => {
          refModal = show
        }}
        ref={refModal}
        header={modalHeader}
        action={async () => {
          removeFunction(setSearched)
        }}
      />
      <Flex fw row>
        <Input
          inputAutoComplete
          id='phone'
          value={searched}
          suggestions={filteredClients}
          completeMethod={(event) => {
            filterFunction(event, setFilteredClients)
          }}
          field={inputField}
          size={30}
          style={{ width: '100%', zIndex: 50, height: 36, borderRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
          label={inputLabel}
          minLength={1}
          dropdownAutoComplete
          itemTemplate={itemTemplate}
          onChange={({ phone }) => {
            setSearched(phone)
          }}
          onSelect={(e) => {
            setSelected(e.value)
          }}
        />
        <Button
          icon={buttonIcon}
          rich
          label={buttonText}
          onClick={() => buttonFunction(conversation, selected, setSelected, setSearched)}
          style={{ width: '40%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        />
      </Flex>
      <Flex fw style={{ marginTop: 10 }}>
        {renderList(showRefModal, itemTemplate) || renderEmptyText(emptyMessage)}
      </Flex>
    </Card>
  )
}

export default GroupchatManager
