import React from 'react'

import { Flex, Text } from '@Common/Components'
import state from '../../State'

const DEFAULT_IMG = 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'

export default function ItemTemplate ({ data }) {
  const config = state.config.state
  return (
    <Flex js row>
      <Flex
        style={{
          width: 50,
          height: 50,
          borderRadius: '50%',
          border: '2px solid white',
          backgroundImage: `url(${data.avatar
            ? data.avatar.includes(config.awsBaseURL)
              ? data.avatar
              : config.awsBaseURL + data.avatar
            : DEFAULT_IMG
            })`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          margin: 20
        }}
      />
      <Flex>
        <Text value={`${data.name} ${data.lastName}`} />
        <Text value={data._id} />
        <Text value={data.email} />
      </Flex>

    </Flex>

  )
}
