import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import AppointmentsList from './Pages/AppointmentsList'

export const Appointments =
  (
    <>
      <Route path='/appointments/*' element={<Navigate to='/appointments/list' />} />
      <Route path='/appointments/list' element={<AppointmentsList />} />
    </>

  )
