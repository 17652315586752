import React, { useRef, useState } from 'react'
import { Button, Card, Input, Modal, Flex, Text } from '@Common/Components'
import { invalidateQuery } from '../../Services'
import DeleteChat from '../DeleteChat'
import { FC, readFile } from '@Common/Services'

const DEFAULT_IMG = 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'

export const ParticipantList = ({ users, conversation }) => {
  const modalList = useRef(null)
  const inputFile = useRef(null)
  const [newSubject, setNewSubject] = useState(conversation.subject)
  const [pickedFile, setPickedFile] = useState(null)
  const [newImg, setNewImg] = useState(null)
  const selectedConversation = conversation?._id

  const uploadPickedFile = async () => {
    try {
      const { size } = pickedFile
      if (size > 4000 * 1024) throw new Error('big')

      const base64 = await readFile(pickedFile)
      const uploadedFile = await FC.service('upload').create({ uri: base64 }, { query: { entity: 'conversations', id: selectedConversation } })

      if (!uploadedFile) throw new Error('')
      const docInfo = await FC.service('documents').patch(uploadedFile.documentId, { type: 'attachment' })

      const groupChatCopy = conversation.groupChat
      groupChatCopy.avatar = docInfo.uri
      await FC.service('conversations').patch(selectedConversation, { groupChat: groupChatCopy })
    } catch (e) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore file inserito',
        detail: e.message === 'big' ? 'File troppo grande ' : 'Errore caricamento file.'
      })
      return null
    }
    return null
  }

  const saveAll = async () => {
    pickedFile && await uploadPickedFile()
    if (newSubject) {
      await FC.service('conversations').patch(selectedConversation, { subject: newSubject })
      invalidateQuery('conversations')
    } else {
      window.growl.show({
        severity: 'error',
        summary: 'Formato non valido',
        detail: 'Inserire correttamente il titolo della chat'
      })
      return null
    }
    window.growl.show({
      severity: 'success',
      summary: 'Salvataggio avvenuto',
      detail: 'Salvataggio eseguito correttamente'
    })
  }

  return (
    <>
      <Modal
        ref={modalList}
        header='Impostazioni Chat'
        style={{ minWidth: 500, width: 500 }}
        content={
          <Card
            style={{
              minHeight: 200,
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 0
            }}
          >
            <Flex fw row wrap style={{ marginTop: 10, marginBottom: 10 }}>
              <Text center value='Immagine del gruppo' style={{ width: '100%' }} bold />
              <Flex row style={{ width: '100%', backgroundColor: 'white', marginTop: 5, marginBottom: 5, borderRadius: 20 }} fw>
                <Text center value="Clicca sulla foto per modificare l'immagine del gruppo" style={{ marginTop: 10, width: '40%' }} />
                <img style={{ height: 150, width: 150, objectFit: 'contain', borderRadius: 50, margin: 20, backgroundColor: 'whitesmoke', border: '1px solid black' }} src={newImg || conversation?.groupChat?.avatar || DEFAULT_IMG} onClick={() => inputFile.current && inputFile.current.click()} />
                <input
                  type='file'
                  accept='image/png,image/jpeg,image/jpg,image/bmp,application/pdf'
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={async (e) => {
                    setNewImg(URL.createObjectURL(e.target.files[0]))
                    setPickedFile(e.target.files[0])
                  }}
                />
              </Flex>
            </Flex>
            <Flex fw row wrap style={{ marginTop: 10, marginBottom: 10 }}>
              <Text center value='Titolo della Chat' style={{ width: '100%' }} bold />
              <Input
                multiline
                id='title'
                style={{ width: '100%', borderRadius: 20, textAlign: 'center', maxHeight: 50 }}
                value={newSubject}
                onChange={(e) => { setNewSubject(e.title) }}
              />
            </Flex>
            <Flex fw row wrap style={{ marginTop: 10, marginBottom: 10 }}>
              <Text value='Elenco Partecipanti' center style={{ width: '100%' }} bold />
              {/*    <Flex row key={ind} style={{ width: '100%', backgroundColor: 'white', height: 50, marginTop: 5, marginBottom: 5, borderRadius: 20 }} fw js>
                    <img style={{ height: 40, borderRadius: 40, marginLeft: '10%' }} src={usr.avatar || DEFAULT_IMG} /> <Text style={{ marginLeft: '5%' }} center value={usr.name} color='black' />
                  </Flex> */}
              <Flex fw row wrap style={{ marginTop: 10, width: '100%' }}>
                {users?.map((usr, ind) =>
                  <Flex key={ind} style={{ width: '100%', backgroundColor: 'white', height: 50, marginTop: 5, marginBottom: 5, borderRadius: 20 }} fw>
                    <Flex row fw>
                      <Text
                        style={{ marginLeft: '5%' }} center
                        value={`${usr.name || 'Passeggero senza nome'} - ${usr.phone || 'Numero non inserito'}`}
                        color='black'
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <DeleteChat conversation={conversation} style={{ padding: '18px 20px', marginLeft: 0, marginBottom: 10 }} />
            <Button label='Salva' rich onClick={saveAll} />
          </Card>
        }
      />
      <Button
        icon='chat'
        label='Impostazioni gruppo'
        style={{
          marginLeft: 20,
          padding: 5
        }}
        onClick={() => {
          setNewSubject(conversation.subject)
          setNewImg(null)
          setPickedFile(null)
          modalList.current ? modalList.current.show() : modalList.show()
        }}
      />
    </>
  )
}
