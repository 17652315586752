import { Button, Modal } from '@Common/Components'
import GroupchatManager from './index'
import { useRef, useState } from 'react'
import { filterAdd, getUserButton, handlerAdd, removeParticipant, textTemplateAdd } from './Functions'
import { useCQuery } from '../../Services'

export const AddClient = ({ conversation }) => {
  const { data: extraUsers = [] } = useCQuery(['extraUsers', conversation.groupChat.listUsr])
  const { data: allListClients = [] } = useCQuery('allClients')
  const [userToDelete, setUserToDelete] = useState('')

  const modalChat = useRef(null)
  return (
    <>
      <Modal
        ref={modalChat}
        header='Gestisci gli utenti esterni al tour'
        content={
          <GroupchatManager
            conversation={conversation}
            filterFunction={(event, setFilteredClients) => {
              filterAdd(event, allListClients, conversation, setFilteredClients)
            }}
            buttonFunction={handlerAdd}
            emptyMessage='Nessun partecipante esterno'
            removeFunction={(setSearched) => { removeParticipant(conversation, setSearched, userToDelete) }}
            modalHeader='Vuoi eliminare questo utente?'
            inputField='_id'
            inputLabel='Cliente: '
            buttonIcon='add-person'
            textTemplate={textTemplateAdd}
            buttonText='Aggiungi'
            extraUsers={extraUsers}
            renderList={(showRefModal, itemTemplate) => {
              return conversation?.groupChat?.listUsr.length ? conversation?.groupChat?.listUsr.map((userId) => { return getUserButton(userId, showRefModal, setUserToDelete, extraUsers) }) : false
            }}
          />
        }
      />
      <Button
        icon='add-person'
        label='Aggiungi utenti esterni'
        style={{
          marginLeft: 20,
          padding: 5
        }}
        onClick={() => {
          // setModalAction('merge')
          modalChat.current ? modalChat.current.show() : modalChat.show()
        }}
      />
    </>
  )
}
