import React from 'react'

import { Flex, Text, TextareaAuto } from '@Common/Components'
import state from '../../State'

export default function Details () {
  const [offerDetails, setOfferDetails] = state.offerDetails.useState()
  const [offerActions, setOfferActions] = state.offerActions.useState()

  const [startCheckErrorsSyncAppGlobal, setStartCheckErrorsSyncAppGlobal] = state.startCheckErrorsSyncAppGlobal.useState()

  const updateData = (field) => (e) => {
    setStartCheckErrorsSyncAppGlobal(false)
    setOfferActions({ ...offerActions, saved: false })
    setOfferDetails({ ...offerDetails, [field]: e.target.value })
  }

  return (
    <Flex fw js as>
      <Flex fw js as>
        <Text bold title upCase style={{ marginTop: 30, marginBottom: 10 }} value='Descrizione del viaggio' />
        <TextareaAuto
          placeholder='Descrizione del viaggio'
          value={offerDetails.description}
          onChange={updateData('description')}
          style={startCheckErrorsSyncAppGlobal && !offerDetails.description ? { border: '1px solid red' } : {}}
        />
      </Flex>
      <Flex fw js as>
        <Text bold title upCase style={{ marginTop: 30, marginBottom: 10 }} value='Servizi inclusi' />
        <TextareaAuto
          placeholder='Servizi inclusi'
          value={offerDetails.serviceIncluded}
          onChange={updateData('serviceIncluded')}
        />
      </Flex>
      <Flex fw js as>
        <Text bold title upCase style={{ marginTop: 30, marginBottom: 10 }} value='Servizi esclusi' />
        <TextareaAuto
          placeholder='Servizi esclusi'
          value={offerDetails.serviceExcluded}
          onChange={updateData('serviceExcluded')}
        />
      </Flex>
      <Flex fw js as>
        <Text bold title upCase style={{ marginTop: 30, marginBottom: 10 }} value={'Note per l\'agenzia'} />
        <TextareaAuto
          placeholder={'Note visibili all\' agenzia'}
          onChange={updateData('note')}
          value={offerDetails.note}
        />
      </Flex>
    </Flex>
  )
}
