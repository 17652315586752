import React, { useState } from 'react'
import { Tabs, TabHeader } from '@Common/Components'
import ListClient from '../Components/ListClient'

const tabs = [{
  id: 0, icon: 'account', title: 'CLIENTI', disabled: false, content: <ListClient />
}]

const ClientsGeneral = () => {
  const [currentTab, setCurrentTab] = useState(0)
  return (
    <Tabs
      onTabSelection={setCurrentTab}
      currentTab={currentTab}
    >
      {tabs?.filter(({ disabled }) => !disabled).map((tab, index) => (
        <TabHeader
          title={tab?.title}
          key={index}
          index={index}
          icon={tab?.icon}
        >
          {tab?.content}
        </TabHeader>
      ))}
    </Tabs>
  )
}
export default ClientsGeneral
