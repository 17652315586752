import { Text, Flex } from '@Common/Components'
import { Separator } from './Separator'
import moment from 'moment'
import state from '@Common/State'

const Avatar = ({ ele }) => {
  const config = state.config.state
  const backgroundImageUrl = !ele.groupChat
    ? (ele?.userData[0] && ele?.userData[0]?.avatar
        ? (ele.userData[0].avatar.includes(config.awsBaseURL)
            ? ele.userData[0].avatar
            : config.awsBaseURL + ele.userData[0].avatar)
        : 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png')
    : 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'

  return (
    <div style={{
      ...styles.avatar,
      backgroundImage: `url(${ele?.groupChatAvatar || backgroundImageUrl})`
    }}
    />
  )
}

const Badge = ({ ele }) => {
  if (!ele.badge || ele.badge === 0) return null
  return (
    <div style={styles.badge}>
      <Text bold size='14px' color='white' center value={ele.badge} />
    </div>
  )
}

export const ConversationInList = ({ onClick, ele }) => {
  const timeValue = moment(ele.date).isSame(moment(), 'day') ? moment(ele.date).format('HH:mm') : moment(ele.date).format('DD/MM/YYYY')
  const handleAction = ele.action
  return (
    <div
      style={{
        ...(ele.highlited || ele.isCurrent ? styles.cardStyle : {}),
        ...(ele.isCurrent ? { backgroundColor: 'rgba(255, 255, 255, 0.8)' } : {}),
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={handleAction}
    >
      <Flex js fw row wrap style={{ paddingLeft: 10 }}>
        <Avatar ele={ele} />
        <div style={{ flex: 1, marginLeft: 10 }}>
          <Text bold value={!ele.groupChat ? ele.userData[0] ? ele.userData[0].name : '' : ''} />
          <Text italic={!ele.groupChat} size={13} style={{ width: 190 }} value={ele.subject} bold={!!ele.groupChat} />
          <Text italic size={10.5} style={styles.time} value={timeValue} />
        </div>
        <Badge ele={ele} />
      </Flex>
      {!ele.isCurrent && <Separator />}

    </div>
  )
}

const styles = {
  cardStyle: {
    padding: 5,
    borderRadius: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    boxShadow:
      '1px 1px 3px 0 rgba(0, 0, 0, 0.2), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 2px 1px 1px -1px rgba(0, 0, 0, 0.12)',
    marginBottom: 10
  },
  avatar: {
    display: 'block',
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: '2px solid white',
    backgroundPosition: 'top center',
    backgroundSize: 'cover'
  },
  time: {
    width: 50,
    float: 'right',
    marginRight: '5px',
    textAlign: 'right'
  },
  badge: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    backgroundColor: '#32324e',
    position: 'absolute',
    right: 2,
    top: 2
  }
}
