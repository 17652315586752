import React, { useRef } from 'react'
import { useWindowSize } from 'react-use'

import { Card, Flex, Text, Button, Slider, Modal } from '@Common/Components'
import state from '../../State'
import { clearState, saveOffer, showGrowl } from '../../Functions'
import { useCQuery } from '../../Services'
import { useHandleNavigate } from '@Common/Services'

export default function Header () {
  const { data: user = {} } = useCQuery('user')
  const navigate = useHandleNavigate()

  const { width: screenWidth } = useWindowSize()
  const config = state.config.state

  const [offerMainData] = state.offerMainData.useState()
  const [offerActions, setOfferActions] = state.offerActions.useState()

  // Error trigger
  const setStartCheckErrorsGlobal = state.startCheckErrorsGlobal.useState()[1]
  const setStartCheckErrorsSyncAppGlobal = state.startCheckErrorsSyncAppGlobal.useState()[1]

  const showDeleteAll = useRef(false)

  const published = offerActions?.status === 1 || false

  const onClickSave = async () => {
    setStartCheckErrorsGlobal(true)
    const id = await saveOffer(user, published ? 1 : 0)
    if (id) {
      setOfferActions({ ...offerActions, saved: true })
      navigate('/offers/' + id)
    }
  }

  const onChangeSlider = async (status) => {
    setStartCheckErrorsGlobal(true)
    setStartCheckErrorsSyncAppGlobal(true)
    setOfferActions({ ...offerActions, status: status ? 1 : 0 })
    const id = await saveOffer(user, status ? 1 : 0)
    if (!id) setOfferActions({ ...offerActions, status: status ? 0 : 1 })
    else {
      setOfferActions({ ...offerActions, saved: true, status: status ? 1 : 0 })
      navigate('/offers/' + id)
    }
  }

  const onClickCopy = () => {
    if (!offerMainData?._id) showGrowl('error', 'Attenzione', 'Per poter copiare un\'offerta è necessario prima crearne una.')
    else {
      state.offerDocuments.setState([])
      setOfferActions({ ...offerActions, copied: true, saved: false, status: 0 })
    }
  }

  const onClickDelete = () => showDeleteAll?.current?.show()

  return (
    <Card collapsable style={{ marginBottom: 20 }}>
      <Modal
        ref={showDeleteAll}
        header='Sicuro di cancellare tutti i campi?'
        action={() => {
          clearState()
          //  navigate('/offers')
        }}
      />
      <Flex fh fw style={{ flexDirection: screenWidth > config.mediumScreen ? 'row' : 'column' }}>
        <Flex fw row fh style={{ justifyContent: screenWidth > config.mediumScreen ? '' : 'center' }}>
          {offerMainData?.title && <Text value={offerMainData?.title} size={20} />}
          <Flex style={{ marginLeft: 20 }}>
            {!offerActions?.saved && (
              <Text
                value={
                  !offerMainData?.title
                    ? 'Questa offerta non è ancora stata salvata'
                    : 'Le modifiche di questa offerta non sono ancora state salvate'
                }
                style={{ color: '#d02f24' }}
              />
            )}
            {offerActions?.copied && <Text value={`Questo offerta è una bozza originata da: ${offerMainData?.title}`} />}
            {!published && !offerActions?.copied && offerMainData?.title && <Text value='Questo offerta è una bozza ' />}
            {published && offerMainData?.title && (
              <Text value={'Offerta sincronizzata sull\'App dei clienti'} style={{ color: '#008800' }} />
            )}
          </Flex>
        </Flex>

        <Flex fw row fh>
          <Button icon='check' label='Salva' onClick={onClickSave} />
          <Flex as js fw fh>
            <Slider
              style={{ justifyContent: 'none', marginTop: 5, marginLeft: 40 }}
              value={published}
              onChange={onChangeSlider}
            />
          </Flex>
        </Flex>
        <Flex fw>
          <Flex row fh fw je>
            <Button
              tooltip='Copia'
              round
              icon='copy'
              style={{ marginRight: 10 }}
              onClick={onClickCopy}
            />
            <Button
              tooltip='Cancella'
              round
              icon='bin'
              style={{ marginRight: 10 }}
              onClick={onClickDelete}
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
