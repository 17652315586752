import React from 'react'

import { Card } from '@Common/Components'
import state from '../../../State'
import { Hotel } from './Elements'
import PlusButton from '../PlusButton'
import { updateService, deleteService } from '../../../Functions'

export default function HotelForm () {
  const [hotelForm] = state.hotelForm.useState()

  if (!hotelForm?.length) return null

  return (
    <Card
      key='hotel'
      title='HOTEL'
      collapsable={hotelForm.length > 0}
      style={{ marginBottom: '20px', width: '100%' }}
    >
      <PlusButton type='hotelForm' />
      {hotelForm.map((item, index) => (
        <Hotel
          key={index}
          index={index}
          item={item}
          onUpdate={(a, b) => updateService(a, b)}
          onDelete={deleteService}
        />
      ))}
    </Card>
  )
}
