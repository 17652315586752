import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Flex, Text } from '@Common/Components'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function MessageViewer ({ currentMessage, isAgency, isTheFirstBubble }) {
  if (currentMessage.messageType === 'image') {
    return (
      <Flex>
        {(!isAgency && currentMessage.groupChat && isTheFirstBubble) && <Text
          bold
          value={currentMessage.user.nameSurname}
          color='rgba(0, 0, 0, 0.7)'
          style={{ width: '100%', marginBottom: 5 }}
                                                                        />}
        <img onClick={() => window.open(currentMessage.url)} src={currentMessage.url} style={{ height: 'auto', maxHeight: 100, maxWidth: '100%', cursor: 'pointer' }} />
      </Flex>
    )
  }
  if (!currentMessage.messageType || currentMessage.messageType === 'text') {
    return (
      String(currentMessage.text)
        .split('\n')
        .map((msg, key) => (
          <div key={key}>
            {(!isAgency && currentMessage.groupChat && isTheFirstBubble) && (
              <Text bold value={currentMessage.user.nameSurname} color='rgba(0, 0, 0, 0.7)' />)}
            <Text value={msg} color={isAgency ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'} />
          </div>
        ))
    )
  }

  if (currentMessage.messageType === 'file') {
    return (
      <Flex>
        {(!isAgency && currentMessage.groupChat && isTheFirstBubble) && (
          <Text
            bold value={currentMessage.user.nameSurname}
            color='rgba(0, 0, 0, 0.7)' style={{ width: '100%', marginBottom: 5 }}
          />)}
        <Flex style={{ cursor: 'pointer' }} onClick={() => window.open(currentMessage.url)}>
          <Document file={currentMessage.url}>
            <Page pageNumber={1} height={100} width={75} />
          </Document>
        </Flex>
      </Flex>

    )
  }
}
