import React from 'react'
import { Input, Text } from '@Common/Components'

const GRID_NORMAL = `
  'labelPratica labelPratica type type year year number number'
  'labelPartenza labelPartenza startDate1 startDate1 startDate1 startDate2 startDate2 startDate2'
  'labelReg labelReg registrationDate1 registrationDate1 registrationDate1 registrationDate2 registrationDate2 registrationDate2'
  `
function SearchPra({ updateState, data }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateAreas: GRID_NORMAL,
        gridGap: '20px 9px',
        width: '100%'
      }}
    >
      <Text
        value='Pratica:'
        style={{
          gridArea: 'labelPratica',
          marginRight: 10,
          marginTop: 4
        }}
      />
      <Input
        id='type'
        label='Tipo:'
        style={{ gridArea: 'type' }}
        onChange={(e) => updateState('pra', e)}
        value={data.type || ''}
      />
      <Input
        keyfilter='int'
        id='year'
        label='Anno:'
        style={{ gridArea: 'year' }}
        onChange={(e) => updateState('pra', e)}
        value={data.year || ''}
      />
      <Input
        keyfilter='int'
        id='number'
        label='Numero:'
        style={{ gridArea: 'number' }}
        onChange={(e) => updateState('pra', e)}
        value={data.number || ''}
      />

      {/* SECONDA PARTE */}
      <Text value='Data Partenza:' style={{ marginRight: 20, marginTop: 4, gridArea: 'labelPartenza' }} />
      <Input
        date
        id='startDate1'
        label='Da:'
        style={{ gridArea: 'startDate1' }}
        onChange={(e) => updateState('pra', e)}
        value={data.startDate1 || ''}
      />
      <Input
        date
        id='startDate2'
        label='A:'
        style={{ gridArea: 'startDate2' }}
        onChange={(e) => updateState('pra', e)}
        value={data.startDate2 || ''}
      />

      {/* TERZA PARTE */}
      <Text value='Registrazione:' style={{ marginRight: 10, marginTop: 4, gridArea: 'labelReg' }} />
      <Input
        date
        id='registrationDate1'
        label='Da:'
        style={{ gridArea: 'registrationDate1' }}
        onChange={(e) => updateState('pra', e)}
        value={data.registrationDate1 || ''}
      />
      <Input
        date
        id='registrationDate2'
        label='A:'
        style={{ gridArea: 'registrationDate2' }}
        onChange={(e) => updateState('pra', e)}
        value={data.registrationDate2 || ''}
      />
    </div>
  )
}

export { SearchPra }
