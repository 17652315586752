import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Spinner } from '@Common/Components'
import { FC, useHandleNavigate } from '@Common/Services'
import { useCInfiniteQuery } from '../Services/QueryCache'

const ListClient = () => {
  const [clientTable, setClientTable] = useState([])
  const [loading, setLoading] = useState(true)
  const {
    data: clients = [],
    isSuccess: isSuccessClients,
    isFetchingNextPage: isFetchingNextPageClients
  } = useCInfiniteQuery('infiniteClients')
  const navigate = useHandleNavigate()

  const getTypeString = (tripType) => {
    const obj = Object.keys(tripType)
    const tripTypeString = obj.filter((key) => tripType[key] === 1)
    // gestire lunghezza ecc e divisione stringhe type (tentmountaincruise --> tent, mountain, cruise)
    return tripTypeString
  }

  const elaborateProps = async () => {
    let completeDate = []
    try {
      const quotes = await FC.client.service('quotes').find()
      completeDate = await Promise.all(
        clients.map(async (item) => {
          const reqFiltered = quotes.filter(({ request }) => request.user === item._id)
          return {
            ...item,
            nameLastName: item.name || item.lastName ? `${item?.name || ''} ${item?.lastName || ''}` : 'SENZA NOME',
            quotesNumber: reqFiltered.length, // da capire i quotes effettivi, oltre che le richieste
            toursNumber: item.userTours ? item.userTours : 0,
            tripTypeString: item.tripType ? getTypeString(item.tripType) : []
          }
        })
      )
    } catch (error) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore dati',
        detail: 'Errore connessione con il server'
      })
    }
    setClientTable(completeDate)
    setLoading(false)
  }

  useEffect(() => isSuccessClients && elaborateProps(), [isSuccessClients, clients])

  const clientSelect = async (data) => {
    const selectedClient = clients.filter((item) => item._id === data._id)
    navigate('/clients/' + selectedClient[0]?._id.replace('.', '-'))
  }

  return (
    <>
      {loading
        ? <Spinner />
        : (
          <DataTable
            value={clientTable}
            paginator
            rows={10}
            filterDisplay='row'
            emptyMessage='Nessun utente da visualizzare'
            className='TableList'
            selectionMode='single'
            onSelectionChange={(e) => clientSelect(e.value)}
          >
            <Column
              filter
              showClearButton={false}
              showFilterMenu={false}
              field='nameLastName'
              header='Cliente'
              sortable
              filterPlaceholder='🔍   Cliente'
              filterMatchMode='contains'
              style={{ border: 'none' }}
            />
          </DataTable>)}

      {isFetchingNextPageClients && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      )}
    </>
  )
}

export default ListClient
