import React, { useRef, useState } from 'react'
import { Card, Flex, Text, Spinner } from '@Common/Components'
import { useCQuery, invalidateQuery, useCInfiniteQuery } from '../Services/QueryCache'
import state from '../State'
import DeleteChat from './DeleteChat'
import { ParticipantList } from './GroupchatManager/ParticipantList'
import { AddClient } from './GroupchatManager/AddClient'
import { MergeChat } from './GroupchatManager/MergeChat'
import moment from 'moment'
import { Bubble } from './Bubble'
import image from '../Resources/Images/UtyaDuck-512px-3.gif'
import { Composer } from './Composer'
import { FC, readFile } from '@Common/Services'
import { Picker } from 'emoji-mart'
import { useParams } from 'react-router-dom'

const DEFAULT_IMG = 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'

export const Chat = () => {
  const selectedConversation = useParams()?.selectedConversation
  const agencyId = state.auth.state.userAgency
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [sending, setSending] = useState(false)
  const [currentMessage, setCurrentMessage] = useState('')

  const { data: user = {} } = useCQuery('user')
  const { data: conversations = [] } = useCInfiniteQuery('conversations')
  const { data: agency = {} } = useCQuery(['agency', agencyId])
  const { data: currentMessages = [], isSuccess } = useCQuery(['messages', selectedConversation])

  const conversation = conversations.find((el) => el._id === selectedConversation) || {}
  const conversationUser = Array.isArray(conversation.userData || []) ? (conversation.userData || []) : [conversation.userData]

  const showUserInfo = useRef({})
  let pickerRef = useRef(null)

  const formatMessages = (messages) => {
    if (!messages || messages.length === 0) return []
    return messages
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((message) => ({
        id: message._id,
        text: message.text,
        createdAt: message.createdAt,
        user: {
          id: message.user,
          avatar: message.avatar,
          nameSurname: message.nameSurname
        },
        groupChat: message.groupChat,
        messageType: message.messageType,
        url: message.url,
        read: message?.read
      }))
  }

  const sendMessage = async (attachment) => {
    let operator = {}
    if (agency.useOperatorForChat) operator = { operator: user._id || '' }
    if (attachment?.messageType) {
      setCurrentMessage('')
      await FC.service('messages').create({ ...attachment, conversationId: selectedConversation, user: user.agency, ...operator })
      setSending(false)
    } else {
      const message = currentMessage
      if (!message) return false
      setCurrentMessage('')
      setSending(true)
      const messageData = {
        conversationId: selectedConversation,
        text: message,
        user: user.agency,
        messageType: 'text',
        ...operator
      }
      await FC.service('messages').create(messageData)
      setSending(false)
    }
    invalidateQuery(['messages', 'conversations'])
  }

  const renderChatContent = () => {
    const listMessages = formatMessages(currentMessages, conversationUser)
    const listElements = []
    listMessages.forEach((msg, ind, arr) => {
      const correctUser = Array.isArray(conversationUser) ? conversationUser.find(item => item.phone === msg.user.id) : conversationUser
      const previousMessage = ind === 0 ? false : arr[ind - 1]
      const isSameDay = previousMessage ? moment(previousMessage.createdAt).isSame(moment(msg.createdAt), 'day') : true
      const isToday = moment().isSame(moment(previousMessage.createdAt), 'day')
      !isSameDay && listElements.push(
        <div key={`divDate${ind}`} style={styles.dateContainer}>
          <Text
            bold color='#32324e' style={{ margin: 'auto' }}
            value={isToday ? 'OGGI' : moment(previousMessage.createdAt).format('DD/MM/YYYY')}
          />
        </div>
      )
      listElements.push(
        Bubble({
          agencyId: agency._id,
          userData: correctUser,
          avatarAgency: msg.user.avatar,
          imgDefault: msg.user.avatar,
          currentMessage: msg,
          previousMessage: ind === 0 ? {} : arr[ind - 1],
          nextMessage: ind === arr[arr.length1 - 1] ? {} : arr[ind + 1],
          key: ind,
          changePopupState: () => { try { showUserInfo() } catch (error) { } }
        })
      )
      if (ind === arr.length - 1) {
        listElements.push(
          <div key={`divDate${ind}date`} style={styles.dateContainer}>
            <Text bold color='#32324e' style={{ margin: 'auto' }} value={moment(msg.createdAt).format('DD/MM/YYYY')} />
          </div>
        )
      }
    })
    if (listMessages.length === 0) {
      listElements.push(
        <Flex fw key={0} fh>
          <Card fadeIn style={{ width: 300 }}>
            <Flex fw>
              <Text size={18} value='Ancora nessun messaggio ...' style={{ marginBottom: 10 }} />
              <img style={{ width: '60%' }} src={image} alt='image' />
            </Flex>
          </Card>
        </Flex>)
    }
    return listElements
  }

  const uploadPickedFile = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    e.persist()
    try {
      setSending(true)
      const pickedFile = e.target.files[0]
      const { size, name } = pickedFile
      const fileExtension = name.split('.').pop()
      if (size > 25000 * 1024) throw new Error('big')
      const base64 = await readFile(pickedFile)
      const uploadedFile = await FC.service('upload').create({ uri: base64 }, { query: { entity: 'conversations', id: selectedConversation } })
      if (!uploadedFile) throw new Error('')
      const docInfo = await FC.service('documents').patch(uploadedFile.documentId, { type: 'attachment' })
      const messageData = {
        fileExtension,
        messageType: fileExtension === 'pdf' ? 'file' : 'image',
        url: docInfo.uri,
        fileName: name
      }
      await sendMessage(messageData)
    } catch (e) {
      setSending(false)
      console.log('Error', e)
      window.growl.show({
        severity: 'error',
        summary: 'Errore file inserito',
        detail: e.message === 'big' ? 'File troppo grande ' : 'Errore caricamento file.'
      })
      return null
    }
  }

  return (
    <>
      <Card style={{ width: '100%', paddingTop: 20, top: 0 }}>
        <Flex row js as fw>
          <div
            style={{
              display: 'block',
              width: 50,
              height: 50,
              borderRadius: '50%',
              border: '2px solid white',
              backgroundImage: `url(${(conversation?.groupChat ? conversation?.groupChat?.avatar : conversationUser[0]?.avatar) || DEFAULT_IMG})`,
              backgroundPosition: 'top center',
              backgroundSize: 'cover',
              cursor: 'pointer',
              marginRight: 20
            }}
          />
          {!conversation?.groupChat ? <Text bold value={conversationUser[0]?.name} /> : null}
          <Flex as>
            <Text bold value={`${conversation?.groupChat?.tourId || ''}`} />
            <Text value={`${conversation.subject || ''}`} />
          </Flex>
          <Flex row style={{ alignItems: 'right', marginLeft: 'auto' }}>
            {conversation?.groupChat
              ? (
                <>
                  <ParticipantList users={conversationUser} conversation={conversation} />
                  <AddClient conversation={conversation} />
                  <MergeChat conversation={conversation} />
                </>)
              : <DeleteChat conversation={conversation} />}
          </Flex>
        </Flex>
      </Card>
      <div
        style={{
          margin: '0 auto',
          width: '100%',
          overflow: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'none',
          height: 'calc(100% - 130px)',
          paddingRight: 15,
          display: 'flex',
          flexDirection: 'column-reverse'
        }}
      >
        {isSuccess ? renderChatContent() : <Spinner />}
      </div>
      <Picker
        id='emoji' set='apple' title='Seleziona le emoji' emoji='grin'
        onSelect={({ native }) => setCurrentMessage(currentMessage + native)}
        style={{
          position: 'absolute',
          left: '340px',
          bottom: '80px',
          width: '420px',
          height: '420px',
          display: showEmojiPicker ? 'block' : 'none'
        }}
      />
      <Composer
        emoji={() => setShowEmojiPicker(!showEmojiPicker)}
        update={(currentMessage) => setCurrentMessage(currentMessage)}
        send={(attachment) => sendMessage(attachment)}
        currentMessage={currentMessage}
        pickFile={() => pickerRef.click()}
        sending={sending}
      />
      <input
        type='file' ref={(ref) => { pickerRef = ref }}
        accept='image/png,image/jpeg,image/jpg,image/bmp,application/pdf'
        style={{ display: 'none' }} onChange={async (e) => uploadPickedFile(e)}
      />
    </>
  )
}

const styles = {
  dateContainer: {
    width: 110,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    margin: 'auto',
    marginTop: 10,
    marginBottom: 10,
    color: '#32324e'
  }
}
