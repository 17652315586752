import _ from 'lodash'
import { invalidateQuery } from '../../Services'
import { Button, Text } from '@Common/Components'
import { FC } from '@Common/Services'

export const unmergeConversation = async (conversation, setSearched, conversationToUnmerge) => {
  // conservationCopy.groupChat.listUsr = conservationCopy.groupChat.listUsr.filter(id => id !== userToDelete)
  try {
    const conservationCopy = { ...conversation }
    const copySelected = { ...conversationToUnmerge }
    copySelected.groupChat.merged = ''
    delete copySelected.groupChat.merged
    conservationCopy.groupChat.extraTour = conservationCopy.groupChat.extraTour.filter(tourId => tourId !== copySelected.groupChat.tourId)
    await Promise.all([
      FC.client.service('conversations').patch(conversation._id, { groupChat: conservationCopy.groupChat }),
      FC.client.service('conversations').patch(conversationToUnmerge._id, { groupChat: copySelected.groupChat })
    ])
    setSearched('')
    window.growl.show({
      severity: 'success',
      summary: 'Unito',
      detail: 'Chat divisa con successo!'
    })
    invalidateQuery(['conversations', 'mergedConversations', 'groupChatConversations'])
  } catch (err) {
    console.log(err)
  }
}

export const removeParticipant = async (conversation, setSearched, userToDelete) => {
  try {
    const conservationCopy = { ...conversation }
    conservationCopy.groupChat.listUsr = conservationCopy.groupChat.listUsr.filter(id => id !== userToDelete)
    await FC.client.service('conversations').patch(conversation._id, { groupChat: conservationCopy.groupChat })
    invalidateQuery('extraUsers')
    setSearched('')
    window.growl.show({
      severity: 'success',
      summary: 'Unito',
      detail: 'Utente rimosso con successo!'
    })
    invalidateQuery('conversations')
  } catch (err) {
    console.log(err)
  }
}

export const handlerAdd = async (conversation, selected, setSelected, setSearched) => {
  const copyConversation = { ...conversation }
  if (!selected._id) {
    window.growl.show({
      severity: 'error',
      summary: 'Errore',
      detail: 'Nessun cliente selezionato'
    })
    return
  }
  setSelected({})

  copyConversation.groupChat.listUsr.push(selected._id)
  await FC.client.service('conversations').patch(conversation._id, { groupChat: copyConversation.groupChat })
  setSearched('')
  // window.growl.show({
  //   severity: 'success',
  //   summary: 'Aggiunto',
  //   detail: 'Cliente aggiunto al gruppo!'
  // })
  invalidateQuery(['extraUsers', 'conversations'])
}

export const handlerMerge = async (conversation, selected, setSelected, setSearched) => {
  const copyConversation = { ...conversation }
  if (!selected._id) {
    window.growl.show({
      severity: 'error',
      summary: 'Errore',
      detail: 'Nessuna chat selezionata'
    })
    return
  }
  setSelected({})
  // caso di unione delle conversazioni
  const copySelected = { ...selected }
  copyConversation.groupChat.extraTour ? copyConversation.groupChat.extraTour.push(selected.groupChat.tourId) : copyConversation.groupChat.extraTour = [selected.groupChat.tourId]
  copySelected.groupChat.merged = copyConversation.groupChat.tourId
  delete copySelected.groupChat.extraTour
  await Promise.all([FC.client.service('conversations').patch(conversation._id, { groupChat: copyConversation.groupChat }),
    FC.client.service('conversations').patch(selected._id, { groupChat: copySelected.groupChat })])
  setSearched('')
  window.growl.show({
    severity: 'success',
    summary: 'Unito',
    detail: 'Chat unita con successo!'
  })
  invalidateQuery(['conversations', 'mergedConversations', 'groupChatConversations'])
  return true
}

export const filterAdd = (event, allListClients, conversation, setFilteredClients) => {
  const listClients = _.differenceWith(allListClients, conversation.userData, (first, second) => first._id === second.phone)
  const results = listClients.filter((client) => {
    return client._id.includes(event.query) || (client.name && client.name.toLowerCase().includes(event.query.toLowerCase())) || (client.lastName && client.lastName.toLowerCase().includes(event.query.toLowerCase())) || (client.name && client.lastName && (client.name.toLowerCase() + ' ' + client.lastName.toLowerCase()).includes(event.query.toLowerCase()))
  })
  setFilteredClients(results)
}

export const filterMerge = (event, setFilteredClients, copylistConversations) => {
  const listConversations = copylistConversations.filter(conv => (!conv.groupChat.extraTour || !conv.groupChat.extraTour.length) && (!conv.groupChat.merged || conv.groupChat.merged === ''))
  const results = listConversations.filter((client) => {
    return client.subject.toLowerCase().includes(event.query.toLowerCase())
  })
  setFilteredClients(results)
}

export const textTemplateAdd = (data) => (
  <>
    <Text value={`${data.name} ${data.lastName}`} />
    <Text value={data._id} />
    <Text value={data.email} />
  </>
)

export const textTemplateMerge = (data) => {
  const { userData } = data
  const getPartecipant = () => {
    if (Array.isArray(userData)) {
      const firstTwoUserData = [userData[0], userData[1]]
      let intermediateString = ''
      firstTwoUserData.forEach((item = {}) => {
        intermediateString = intermediateString.length ? intermediateString + ', ' + item.name : item.name
      })
      // caso array
      if (userData.length > 2) {
        const other = userData.splice(2) // esempio: su un array di 6, splice(2), 4 elementi vanno in other, i primi 2 rimangono in userData
        return intermediateString + ' più altri ' + other.length
      }
      return intermediateString // non faccio vedere full elenco, ma nella filter, filtra per tutti
    }
    return userData?.name || ''
  }
  return (
    <div style={{ width: 200 }}>
      <Text value={data.subject} style={{ width: 200 }} />
      <Text value={data.groupChat.tourId.split('@')[1]} style={{ width: 200 }} />
      <Text value={getPartecipant()} style={{ width: 200 }} />
    </div>
  )
}

export const getUserButton = (userId, showRefModal, setUserToDelete, extraUsers) => {
  const { name = '', lastName = '', _id } = extraUsers.find(({ _id }) => _id === userId) || {}
  return (
    <Button
      onClick={() => showRefModal() && setUserToDelete(_id)}
      style={{ width: '100%', marginTop: 5 }} rich icon='bin' key={_id} label={`${name} ${lastName}`}
    />
  )
}

export const getMergedConvButton = (conv, showRefModal, setConversationToUnmerge, itemTemplate) => (
  <Button
    onClick={() => showRefModal() && setConversationToUnmerge(conv)}
    style={{ width: '100%', marginTop: 5, height: 80 }} rich icon='bin' key={conv._id} label={itemTemplate(conv)}
  />
)
