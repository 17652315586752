import React, { useRef, useState } from 'react'
import { useCQuery, invalidateQuery } from '../Services'
import { FC } from '@Common/Services'
import { Flex, Text, Modal, Card, Input, Spinner, Button } from '@Common/Components'
import Select from 'react-select'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

const BroadcastManager = () => {
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedClients, setSelectedClients] = useState([])
  const [broadcastDetails, setBroadcastDetails] = useState([])

  // MODAL SETTINGS
  const [headerText, setHeaderText] = useState('')
  const [action, setAction] = useState()

  const refModal = useRef(null)
  const refDetailsModal = useRef(null)

  const { data: allClients, isSuccess } = useCQuery('allClients')
  const { data: agency, isSuccess: isSuccessAgency } = useCQuery('agency')
  const { data: broadcastMessages = [] } = useCQuery('broadcastMessages')
  if (!isSuccess || !isSuccessAgency) return <Spinner />
  const clientsNames = allClients.map(({ name, lastName, _id }) => {
    return ({
      value: _id + '/' + name + lastName,
      label: name + ' ' + lastName + ' - ' + _id
    })
  })

  const createBroadcast = async () => {
    setLoading(true)
    await FC.service('info').create({ type: 'createBroadcastMessage', agency: agency._id, message: message, users: selectedClients.map(({ value }) => value.split('/')[0]) })
    setLoading(false)
    setMessage('')
    setSelectedClients([])
    invalidateQuery(['broadcastMessages', 'messages', 'conversations'])
  }

  const showModal = (headerText, action) => {
    setAction(action)
    setHeaderText(headerText)
    if (refModal) refModal.current ? refModal.current.show() : refModal.show()
  }

  const deleteBroadcastMessage = async (idBroadcast, details) => {
    const messagesIds = details.map(detail => detail.message._id)
    await Promise.all(messagesIds.map(message => FC.service('messages').patch(message, { status: 0 })))
    await FC.service('broadcastMessages').patch(idBroadcast, { status: 0 })
    invalidateQuery(['broadcastMessages', 'messages', 'conversations'])
  }

  return (
    <>
      <Modal
        ref={refModal}
        header={headerText}
        styleText={{ textAlign: 'center' }}
        yes='Sì'
        no='No'
        action={action}
      />
      <Modal
        ref={refDetailsModal}
        header='Destinatari messaggio broadcast'
        content={
          <Card>
            <Flex fw row wrap style={{ marginTop: 10, width: '100%', maxHeight: 500, overflowY: 'scroll' }}>
              {broadcastDetails.map((detail, ind) =>
                <Flex key={ind} style={{ width: '100%', backgroundColor: 'white', height: 50, marginTop: 5, marginBottom: 5, borderRadius: 20 }} fw>
                  <Flex row fw>
                    <Text style={{ marginLeft: '5%' }} center value={`${detail?.user?.name} ${detail?.user?.lastName} - ${detail?.user?._id || 'Numero non inserito'} - ${detail?.message?.read ? 'Messaggio letto' : 'Messaggio non letto'}`} color='black' />
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Card>
        }
      />

      <Card style={{ display: 'flex', flexDirection: 'column', width: '100%' }} title='GESTIONE MESSAGGI BROADCAST'>
        <Text value='Scrivi il testo del messaggio da inviare a più utenti' style={{ marginBottom: 10 }} />
        <Flex fw row style={{ marginBottom: 20 }}>
          <Flex style={{ width: '75%', marginRight: '5%' }}>
            <Input
              multiline
              placeholder='Testo del messaggio'
              id='text'
              value={message}
              style={{ height: 60, width: '100%', borderRadius: 10 }}
              onChange={({ text }) => setMessage(text)}
            />
          </Flex>
          <Flex style={{ width: '20%' }}>
            {loading
              ? <Spinner />
              : (
                <Button
                  rich
                  icon='edit'
                  label='Invia Messaggio Broadcast'
                  style={{
                    height: 60,
                    width: '100%',
                    cursor: (!selectedClients.length || message === '') ? 'not-allowed' : 'pointer',
                    opacity: (!selectedClients.length || message === '') ? 0.6 : 1
                  }}
                  styleText={{ textAlign: 'center' }}
                  onClick={() => !selectedClients.length || message === '' ? null : showModal('Stai per inviare un messaggio broadcast agli utenti selezionati. Vuoi procedere?', () => createBroadcast)}
                />)}
          </Flex>
        </Flex>
        <Flex row fw as>
          <div style={{ width: '75%', marginRight: '5%', marginBottom: 20 }}>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={clientsNames}
              noOptionsMessage={() => 'Nessun cliente'}
              onChange={(e) => setSelectedClients(e)}
              value={selectedClients}
              placeholder='Seleziona i destinatari del messaggio...'
            />
          </div>
          <Flex style={{ width: '20%' }}>
            <Button
              rich
              icon='add-person'
              label='Seleziona tutti gli utenti'
              styleText={{ textAlign: 'center' }}
              style={{ marginBottom: 20, width: '100%', height: 60 }}
              onClick={() => setSelectedClients(clientsNames)}
            />
            {selectedClients.length !== 0 &&
              <Button
                rich
                icon='add-person'
                label='Rimuovi tutti gli utenti selezionati'
                styleText={{ textAlign: 'center' }}
                style={{ marginBottom: 20, width: '100%', height: 60 }}
                onClick={() => { setSelectedClients([]) }}
              />}
          </Flex>
        </Flex>
        <Flex fw>
          <DataTable
            style={{ width: '100%' }}
            value={broadcastMessages}
            paginator
            rows={10}
            responsiveLayout='scroll'
            emptyMessage='Nessun messaggio da visualizzare'
            className='TableGeneral'
            sortField='date'
            sortOrder={-1}
          >
            <Column field='date' header='Data' headerStyle={{ paddingLeft: 10 }} body={(v) => moment(v.date).format('DD/MM/YYYY hh:mm')} sortable />
            <Column field='message' header='Messaggio' sortable />
            <Column header='Stato' field='_id' sortable body={({ status }) => status === 0 ? 'Eliminato' : 'Consegnato'} />
            <Column
              header='Dettagli' body={(v) =>
                <Button
                  rich label='Mostra dettagli' onClick={() => {
                    refDetailsModal.current ? refDetailsModal.current.show() : refDetailsModal.show()
                    setBroadcastDetails(v.details)
                  }}
                />}
            />
            <Column
              header='Elimina' body={(v) =>
                v.status !== 0
                  ? (
                    <Button
                      rich
                      label='Elimina Messaggio'
                      onClick={() => {
                        showModal('Stai per eliminare un messaggio broadcast. Tutti i destinatari del messaggio non potranno più vederlo. Vuoi procedere?', () => () => deleteBroadcastMessage(v._id, v.details))
                      }}
                    />)
                  : null}
            />

          </DataTable>
        </Flex>
      </Card>
    </>
  )
}

export default BroadcastManager
