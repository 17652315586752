import React from 'react'
import { ClientFormData } from './ClientFormData'
import { CategoryClient } from './CategoryClient'
import { NoteClient } from './NoteClient'

const ICON_LIST = [
  { id: 'tent', text: 'Natura' },
  { id: 'cocktail', text: 'Intrattenimento' },
  { id: 'beach', text: 'Mare' },
  { id: 'extreme', text: 'Avventura' },
  { id: 'climbing', text: 'Sport' },
  { id: 'culture', text: 'Cultura' },
  { id: 'cruise', text: 'Crociera' },
  { id: 'family', text: 'Famiglia' },
  { id: 'gourmet', text: 'Gourmet' },
  { id: 'mountain', text: 'Montagna' },
  { id: 'camper', text: 'Camper' },
  { id: 'motorbike', text: 'Moto' },
  { id: 'emoticons', text: 'Gruppo' },
  { id: 'beauty', text: 'In rosa' },
  { id: 'love', text: 'Nozze' }
]
export const ClientField = ({
  client,
  countryListDrop,
  prefixCountry,
  onChange,
  onChangeSelectedCountry,
  updateCategory,
  phone,
  colorRed
}) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <ClientFormData
      client={client}
      countryListDrop={countryListDrop}
      prefixCountry={prefixCountry}
      onChangeState={onChange}
      onChangeSelectedCountry={onChangeSelectedCountry}
      phone={phone}
      colorRed={colorRed}
    />

    <CategoryClient
      client={client}
      iconList={ICON_LIST}
      updateCategory={updateCategory}
    />

    <NoteClient client={client} onChangeState={onChange} />
  </div>
)
