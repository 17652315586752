import React from 'react'

import { Flex, UploadFile } from '@Common/Components'
import state from '../../State'

export default function Documents () {
  const [offerDocuments, setOfferDocuments] = state.offerDocuments.useState()
  const [offerActions, setOfferActions] = state.offerActions.useState()

  const [startCheckErrorsSyncAppGlobal, setStartCheckErrorsSyncAppGlobal] = state.startCheckErrorsSyncAppGlobal.useState()

  const updateFiles = (updatedFiles) => {
    setStartCheckErrorsSyncAppGlobal(false)
    setOfferActions({ ...offerActions, saved: false })
    setOfferDocuments(updatedFiles)
  }

  const onReorder = (reorderedFiles) => {
    setOfferDocuments(reorderedFiles)
  }

  return (
    <Flex fw as js style={{ marginTop: 10 }}>
      <Flex fw as js>
        <UploadFile
          files={offerDocuments || []}
          updateFiles={updateFiles}
          accept='image/png,image/jpeg,image/jpg,image/bmp'
          title='Scegli le immagini da caricare'
          titleTypeOfFile='formati supportati: .png, .jpeg, .jpg, .bmp'
          onReorder={onReorder}
          className={startCheckErrorsSyncAppGlobal && !offerDocuments.length ? 'invalid-input' : ''}
        />
      </Flex>
    </Flex>
  )
}
