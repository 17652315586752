import { FC, useCQuery as CommonUseCQuery, useCInfiniteQuery as CommonUseCInfiniteQuery, prefetchQueries as CommonPrefetchQueries, prefetchQuery as CommonPrefetchQuery, invalidateQuery as CommonInvalidateQuery } from '@Common/Services'
import state from '../State'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
}

const queries = {
  duplicatesClients: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, agencyID] }) => FC.service('info').get('getDuplicatesClients', { query: { agency: agencyID || state.auth.state.userAgency } })
  },
  user: {
    config: queryConfig.frequent,
    queryFn: ({ queryKey: [key, userId] }) => userId && userId !== 'new' ? FC.client.service('users').get(userId) : null
  }
}

const infiniteQueries = {
  quotes: {
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true
    },
    queryFn: ({ pageParam = 1 }) => {
      const FIRSTPAGE_DIMENSION = 10
      const PAGE_DIMENSION = 100
      return FC.client.service('quotes').find({
        query:
        {
          $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * PAGE_DIMENSION + FIRSTPAGE_DIMENSION)),
          $limit: pageParam === 1 ? FIRSTPAGE_DIMENSION : PAGE_DIMENSION,
          $sort: { year: -1, progressive: -1 }
        }
      })
    },
    noInitalPrefetch: true
  }
}

export const useCQuery = (queryKey) => CommonUseCQuery(queryKey, queries)

export const useCInfiniteQuery = (queryKey) => CommonUseCInfiniteQuery(queryKey, infiniteQueries)

export const prefetchQuery = (queryKey) => CommonPrefetchQuery(queryKey, queries)

export const prefetchQueries = (queries) => CommonPrefetchQueries(queries)

export const invalidateQuery = (queryKey) => CommonInvalidateQuery(queryKey)
