import React from 'react'
import ItemDescription from './ItemDescription'
import TimelineItem from './TimelineItem'
import { Carousel } from 'primereact/carousel'
import { Text } from '@Common/Components'
import { getTimelineList } from '../../Functions'

const itemTemplate = (item) => {
  return (
    <div style={{ width: '100%' }}>
      <TimelineItem item={item} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginLeft: 20, width: 1, border: '2px solid black' }} />
        <div>
          {item.events.map((event, ind) => (
            <ItemDescription event={event} key={ind} />
          ))}
        </div>
      </div>
    </div>
  )
}

const TourPreview = ({ tour }) => {
  const timeline = getTimelineList(tour)
  return !timeline?.length
    ? <Text value="Aggiungi qualche servizio al viaggio per poterne visualizzare l'anteprima" />
    : (
      <>
        <Text value="Questa è l'anteprima dell'attuale visualizzazione del viaggio per gli utenti App" />
        <Carousel
          value={timeline}
          numVisible={2}
          numScroll={1}
          orientation='vertical'
          itemTemplate={itemTemplate}
          verticalViewPortHeight='450px'
        />
      </>
      )
}
export default TourPreview
