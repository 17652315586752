import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Card, Spinner, Text, Input, Icon, Modal, Button, Flex, TextareaAuto } from '@Common/Components'
import { Car, Cruise, Flight, Hotel, Train, Other } from '../Components/Elements'
import state from '../State'
import { getUrlParams, FC, readFile } from '@Common/Services'
import TimelineNotVip from '../Components/AtlComponent/TimelineNotVip'
import TimelineVip from '../Components/Function/TimelineVip'
import HeaderAtl from '../Components/AtlComponent/HeaderAtl'
import RenderPassengers from '../Components/AtlComponent/RenderPassengers'
import TimelineVisionCarousel from '../Components/AtlComponent/TimelineVisionCarousel'
import StatusIcon from '../Components/AtlComponent/DataTableComponent/StatusIcon'
import DocumentCardBody from '../Components/AtlComponent/DataTableComponent/DocumentCardBody'
import BarBottom from '../Components/BarBottom'

const canceledObj = {
  'AN - Annullata': true,
  'AP - Annulata con penale': true,
  Annullato: true,
  Void: true
}

const type = {
  pra: ['La pratica', 'a'],
  bgt: ['Il biglietto', 'o'],
  vou: ['Il voucher', 'o']
}

const AtlanteTours = (props) => {
  const [cruiseForm, setCruiseForm] = state.cruiseForm.useState()
  const [flightForm, setFlightForm] = state.flightForm.useState()
  const [hotelForm, setHotelForm] = state.hotelForm.useState()
  const [serviceForm, setServiceForm] = state.serviceForm.useState()
  const [trainForm, setTrainForm] = state.trainForm.useState()
  const [carForm, setCarForm] = state.carForm.useState()
  const [notesForm, setNotesForm] = state.notesForm.useState()
  const [tour, setTour] = useState([])
  const [atlDataDefault, setAtlDataDefault] = useState({})
  const [loading, setLoading] = useState(true)
  const [labelNoNumber, setLabelNoNumber] = useState(false)
  const [atlId, setAtlId] = useState('')
  const [published, setPublished] = useState(false)
  const [isOnAppTour, setIsOnAppTour] = useState(false)
  const [financeData, setFinanceDate] = useState(false)
  const [typeData, setTypeData] = useState('')
  const [atlDocuments, setAtlDocuments] = useState([])
  const [webDocuments, setWebDocuments] = useState([])
  const [syncingDocument, setSyncingDocument] = useState(false)
  const [idDocumentUpdating, setIdDocumentUpdating] = useState(null)
  const [resolver, setResolver] = useState(null)
  const [qrCode, setQrCode] = useState(null)
  const [extraServicesExists, setExtraServicesExists] = useState('')

  const upload = useRef(null)
  const modalUnableToSynch = useRef(null)

  const getExtraServices = async (id) => {
    const savedExtraServices = await FC.client.service('extraservices').find({ query: { tourRef: id } })
    if (savedExtraServices.length) {
      setExtraServicesExists(savedExtraServices[0]._id)
      setNotesForm(savedExtraServices[0].note)
      setCarForm(savedExtraServices[0].cars)
      setTrainForm(savedExtraServices[0].trains)
      setServiceForm(savedExtraServices[0].services)
      setHotelForm(savedExtraServices[0].hotels)
      setFlightForm(savedExtraServices[0].flights)
      setCruiseForm(savedExtraServices[0].cruises)
    }
    return savedExtraServices
  }

  useEffect(() => {
    const [id, type] = getUrlParams(['id', 'type'])
    setAtlId(id)
    setTypeData(type)
    const callATL = async () => {
      let res = {}
      switch (type) {
        case 'pra':
          res = await FC.service('atlante').get('singleTour', { query: { id } })

          break
        case 'bgt':
          res = await FC.service('atlante').get('singleTicket', { query: { id } })

          break
        case 'vou':
          res = await FC.service('atlante').get('singleVoucher', { query: { id } })

          break

        default:
          break
      }

      if (!res) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore di caricamento viaggio ',
          detail: 'Riprovare'
        })
        return false
      }

      const isOnAppTour = await FC.service('tours').find({ query: { _id: res._id, isATL: true } })

      if (isOnAppTour && isOnAppTour.length) {
        // tour ATL not sync on AppTour
        setPublished(!!isOnAppTour[0].status)
        setFinanceDate(!!isOnAppTour[0].financeData)
        setIsOnAppTour(true)
        setResolver(isOnAppTour[0].resolver)
        setQrCode(isOnAppTour[0].qrcode)
      }
      // tour ATL not sync on AppTour
      res &&
        res.passengers &&
        res.passengers.forEach((element) => {
          !element.phone && setLabelNoNumber(true)
          const isNumberArr = element.phone ? element.phone.split('.') : []
          !isNumberArr[isNumberArr?.length - 1]?.length && setLabelNoNumber(true)
        })

      const { documents, ...data } = res

      const atl = documents.filter((doc) => doc.atl)
      const web = documents.filter((doc) => !doc.atl)

      setWebDocuments(web)
      setAtlDocuments(atl)
      setAtlDataDefault(data)

      const extraServices = await getExtraServices(data._id)
      if (extraServices.length) {
        const uniqJson = TimelineVip({
          ...data,
          extraServices: {
            cars: extraServices[0].cars,
            flights: extraServices[0].flights,
            hotels: extraServices[0].hotels,
            services: extraServices[0].services,
            trains: extraServices[0].trains,
            cruises: extraServices[0].cruises
          }
        })
        setTour(uniqJson)
      } else {
        const uniqJson = TimelineVip(data)
        setTour(uniqJson)
      }
    }

    callATL().then(() => {
      setLoading(false)
    })
  }, [])

  /* Handling the label value */
  const translatorLabel = (type) => {
    const config = {
      pra: 'Pratica',
      bgt: 'Biglietto',
      vou: 'voucher'
    }
    return config[type] || null
  }

  /* Handling functions for changing the status of the trip */
  const syncronizeOnAppTour = async () => {
    if (canceledObj[atlDataDefault.statusAtl]) {
      modalUnableToSynch.current.show()
      return false
    }

    const res = await FC.service('tours').create({
      ...atlDataDefault,
      status: 1,
      showFinanceData: financeData ? 1 : 0
    })

    if (!res) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore di creazione viaggio ',
        detail: 'Riprovare'
      })
      return false
    }
    setAtlDataDefault(res)
    setResolver(res.resolver)
    setQrCode(res.qrcode)
    setPublished(!!res.status)
    setFinanceDate(!!res.showFinanceData)
    setIsOnAppTour(true)

    window.growl.show({
      severity: 'success',
      summary: 'Salvataggio dati',
      detail: 'Viaggio sincronizzato correttamente'
    })
    return res
  }

  const handleChangeFinance = (data) => setFinanceDate(data)

  const handleChangeSlider = async (data) => {
    if (data === 1 && canceledObj[atlDataDefault.statusAtl]) {
      modalUnableToSynch.current.show()
      return false
    }
    // gestione patch pratic
    const res = await FC.service('tours').patch(atlDataDefault._id, {
      ...atlDataDefault,
      status: data,
      showFinanceData: financeData ? 1 : 0
    })
    if (!res) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore di creazione viaggio ',
        detail: 'Riprovare'
      })
      return false
    }
    setPublished(!!data)
    window.growl.show({
      severity: 'success',
      summary: 'Salvataggio dati',
      detail: 'Viaggio sincronizzato correttamente'
    })
    return res
  }

  /* Handling functions for upload and delete documents */
  const addNewDocuments = (e) => {
    e.stopPropagation()
    e.preventDefault()
    e.persist()

    const files = []
    const { files: uploads } = e.target

    for (let i = 0; i < uploads.length; i++) {
      const { name } = uploads[i]
      const tempId = _.uniqueId('id_')

      files.push({
        _id: tempId,
        file: name,
        type: 'EXTRA',
        refFile: uploads[i],
        needsUpload: true,
        stauts: 0,
        date: moment().format('DD/MM/YYYY'),
        title: ''
      })
    }

    setWebDocuments([...webDocuments, ...files])
  }

  // Gestisce la sincronizzazione di documenti ATL. Chiama WS
  const synchronizeDocumentAtl = (doc, status) => async () => {
    if (!isOnAppTour && !published) {
      window.growl.show({
        severity: 'error',
        summary: 'Impossibile sincronizzare il documento. Devi prima sincronizzare la pratica.',
        detail: 'Riprovare'
      })
      return false
    }

    setIdDocumentUpdating(doc._id)
    setSyncingDocument(true)
    const { _id } = atlDataDefault
    const idTour = _id.split('@')[1]
    const body = {
      idTour,
      type: 'synchronizeDocument',
      progressive: doc.progressive,
      status,
      typeTour: typeData.toUpperCase()
    }

    const res = await FC.service('atlante').create(body)
    if (!res) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore durante la sincronizzazione del documento',
        detail: 'Riprovare'
      })
      setSyncingDocument(false)
      return false
    }

    const updatedAtlDoc = atlDocuments.map((elem) => (elem._id === doc._id ? { ...elem, status } : elem))

    setAtlDocuments(updatedAtlDoc)

    setSyncingDocument(false)
    setIdDocumentUpdating(null)
    return true
  }

  const uploadSinglDocOnAwsHandligStatus = (doc, status) => async () => {
    if (!isOnAppTour && !published) {
      window.growl.show({
        severity: 'error',
        summary: 'Impossibile sincronizzare il documento. Devi prima sincronizzare la pratica.',
        detail: 'Riprovare'
      })
      return false
    }

    setIdDocumentUpdating(doc._id)
    setSyncingDocument(true)
    // Caso base: doc già presente su db.
    if (doc.uri) {
      const { from } = doc
      const isAtlDoc = from === 'ATLANTE'
      const res = await FC.service('documents').patch(doc._id, {
        status,
        title: doc.title,
        deleted: false,
        isAtlDoc
      })

      if (!res) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore durante la sincronizzazione del documento',
          detail: 'Riprovare'
        })
        setSyncingDocument(false)
        setIdDocumentUpdating(null)
        return false
      }
      const updatedWebDocuments = webDocuments.map((elem) =>
        elem._id === doc._id ? { ...elem, status } : elem
      )
      setWebDocuments(updatedWebDocuments)
      setSyncingDocument(false)
      setIdDocumentUpdating(null)
      return true
    }

    const { _id: idTour } = atlDataDefault
    const uri = await readFile(doc.refFile)

    const res = await FC.client.service('upload').create({ uri }, { query: { entity: 'tours', id: idTour } })
    if (!res) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore durante la sincronizzazione del documento',
        detail: 'Riprovare'
      })
      setSyncingDocument(false)
      setIdDocumentUpdating(null)
      return false
    }

    const data = {
      noNotify: published,
      file: doc.file,
      from: 'BACKEND_APPTOUR',
      type: 'EXTRA',
      title: doc.title,
      status,
      deleted: false
    }
    const resPatch = await FC.service('documents').patch(
      res.documentId,
      res.isNewUploadFromWeb ? data : { status, isAtlDoc: true }
    )

    if (!resPatch) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore durante la sincronizzazione del documento',
        detail: 'Riprovare'
      })
      setSyncingDocument(false)
      setIdDocumentUpdating(null)
      return false
    }

    const updatedWebDocuments = webDocuments.map((elem) => (elem._id === doc._id ? resPatch : elem))
    setWebDocuments(updatedWebDocuments)
    setSyncingDocument(false)
    setIdDocumentUpdating(null)
    return resPatch
  }

  const deleteDocument = (document) => async () => {
    if (document.uri) {
      const res = await FC.service('documents').patch(document._id, { deleted: true })
      if (!res) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore durante l\'eliminazione del documento',
          detail: 'Riprovare'
        })
        return false
      }
    }

    const updatedDocuments = webDocuments.filter((doc) => doc._id !== document._id)
    setWebDocuments(updatedDocuments)
  }

  /* Render icons for the table */
  const renderActions = (itm) => {
    const isUpdating = idDocumentUpdating === itm._id
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {syncingDocument && isUpdating
          ? <Spinner size={22} />
          : (
            <StatusIcon
              document={itm}
              synchronizeDocumentAtl={synchronizeDocumentAtl}
              uploadSinglDocOnAwsHandligStatus={uploadSinglDocOnAwsHandligStatus}
              deleteDocument={deleteDocument}
            />)}
      </div>
    )
  }

  const renderStatus = (itm) => {
    const isUpdating = idDocumentUpdating === itm._id
    const colorIcon = itm.status ? '#008800' : '#d02f24'
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {syncingDocument && isUpdating
          ? <Spinner size={22} />
          : <Icon name='sincronize-phone' color={colorIcon} size={25} />}
      </div>
    )
  }

  /* Handling edit of 'Title' field */
  const cellEditing = ({ props: { value: webDocs }, rowIndex, field, rowData }) => (event) => {
    const updatedWebDocs = [...webDocs]
    updatedWebDocs[rowIndex][field] = event.title
    rowData.title = event.title
    setWebDocuments(updatedWebDocs)
  }

  /* ************************************************************ */

  const newItem = (type) => {
    state[type].setState([...state[type].state, { _id: (_.max(state[type].state?.map(i => parseInt(i._id))) + 1 || 1).toString() }])
  }

  const plusButton = (type) => (
    <div style={{ marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Button rich icon='plus' round style={{ margin: 3 }} width={30} onClick={() => newItem(type)} />
    </div>
  )

  const deleteItem = (type, id) => {
    if (!state[type]?.state) return false
    state[type].setState(state[type].state?.filter((item) => item._id !== id))
  }

  const updateItem = (type, { _id, ...update }) => {
    if (!state[type]?.state) return false
    state?.[type].setState(state?.[type].state?.map((item) => (item._id === _id ? { ...item, ...update } : item)))
  }

  const handleAddExtraTour = async () => {
    if (extraServicesExists === '') {
      await FC.client.service('extraservices').create({
        flights: flightForm,
        hotels: hotelForm,
        services: serviceForm,
        trains: trainForm,
        cars: carForm,
        cruises: cruiseForm,
        tourRef: atlDataDefault._id,
        note: notesForm
      })
    } else {
      await FC.client.service('extraservices').patch(extraServicesExists, {
        flights: flightForm,
        hotels: hotelForm,
        services: serviceForm,
        trains: trainForm,
        cars: carForm,
        cruises: cruiseForm,
        tourRef: atlDataDefault._id,
        note: notesForm
      })
    }
    window.growl.show({
      severity: 'success',
      summary: 'Modifica viaggio',
      detail: 'Viaggio modificato correttamente!'
    })

    const extraServices = await getExtraServices(atlDataDefault._id)

    if (extraServices.length) {
      const uniqJson = TimelineVip({
        ...atlDataDefault,
        extraServices: {
          cars: extraServices[0].cars,
          flights: extraServices[0].flights,
          hotels: extraServices[0].hotels,
          services: extraServices[0].services,
          trains: extraServices[0].trains,
          cruises: extraServices[0].cruises
        }
      })
      setTour(uniqJson)
    }
  }

  /* ************************************************************ */

  const editorTitle = (e) => <Input value={e.rowData.title} id='title' onChange={cellEditing(e)} />

  /* Modal content */
  const renderModalContent = () => (
    <Card
      style={{
        display: 'flex',
        height: 200,
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Text value={`${type[typeData][0]} è annullat${type[typeData][1]} su Atlante.`} />
      <Text value={`Non può essere sincronizzat${type[typeData][1]} sul cellulare del cliente.`} />
    </Card>
  )

  /* Render */
  return loading
    ? <Spinner />
    : (
      <>
        <Modal ref={modalUnableToSynch} header='Attenzione' content={renderModalContent()} />
        <div
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <Card
            title={`${translatorLabel(typeData)} ${atlId}`}
            collapsable={false}
            textSize={20}
          >
            <HeaderAtl
              atlData={atlDataDefault}
              published={published}
              financeData={financeData}
              handleChangeFinance={handleChangeFinance}
              handleChangeSlider={handleChangeSlider}
              isOnAppTour={isOnAppTour}
              syncronizeOnAppTour={syncronizeOnAppTour}
              qrCodeLink={qrCode}
              resolver={resolver}
            />
          </Card>
          {/* screenWidth > 1550 ? '70%' : '97%', */}
          <Card
            textSize={20}
            style={{
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'space-between'
            }}
            key='doc'
            title='Documenti'
            collapsable
          >
            <DocumentCardBody
              atlDataDefault={atlDataDefault}
              atlDocuments={atlDocuments}
              renderActions={renderActions}
              renderStatus={renderStatus}
              webDocuments={webDocuments}
              editorTitle={editorTitle}
              upload={upload}
              addNewDocuments={addNewDocuments}
            />
          </Card>
          <Flex row style={{ marginTop: 20 }} as>
            <Card title='Elenco Servizi' textSize={20} collapsable style={{ width: '50%' }}>
              {/*  <TimelineVision tour={ tour } /> */}
              {atlDataDefault.type === 'VIP'
                ? <TimelineVisionCarousel tour={tour} />
                : <TimelineNotVip tour={tour} />}
            </Card>
            <div style={{ width: 20 }} />
            <Card textSize={20} title='Passeggeri' collapsable style={{ width: '50%' }}>
              <div style={{ marginBottom: 20 }}>
                {labelNoNumber
                  ? (
                    <Text
                      color='#d02f24'
                      value='Alcuni numeri non sono conformi al fine di una corretta sincronizzazione su AppTour. Controllare i passeggeri da Atlante'
                    />)
                  : null}
              </div>
              <RenderPassengers
                passengers={atlDataDefault && atlDataDefault.passengers ? atlDataDefault.passengers : []}
              />
            </Card>
          </Flex>
          <div style={{ marginTop: 20 }}>
            {hotelForm.length > 0 &&
              <Card
                key='hotel'
                title='HOTEL'
                collapsable={hotelForm.length > 0}
                style={{ marginBottom: '20px' }}
              >
                {plusButton('hotelForm')}
                {hotelForm.length > 0 &&
                  hotelForm.map((item, index) => (
                    <Hotel
                      key={index}
                      index={index}
                      item={item}
                      onUpdate={updateItem}
                      onDelete={deleteItem}
                    />
                  ))}
              </Card>}
            {serviceForm.length > 0 && (
              <Card
                key='other'
                title='ALTRO'
                collapsable={serviceForm.length > 0}
                style={{ marginBottom: '20px' }}
              >
                {plusButton('serviceForm')}
                {serviceForm.length > 0 &&
                  serviceForm.map((item, index) => (
                    <Other
                      key={index}
                      index={index}
                      onUpdate={updateItem}
                      onDelete={deleteItem}
                      item={item}
                    />
                  ))}
              </Card>
            )}

            {flightForm.length > 0 && (
              <Card
                key='flight'
                title='VOLO'
                collapsable={flightForm.length > 0}
                style={{ marginBottom: '20px' }}
              >
                {plusButton('flightForm')}
                {flightForm.length > 0 &&
                  flightForm.map((item, index) => (
                    <Flight
                      key={index}
                      index={index}
                      onUpdate={updateItem}
                      onDelete={deleteItem}
                      item={item}
                    />
                  ))}
              </Card>
            )}

            {trainForm.length > 0 && (
              <Card
                key='train'
                title='TRENI'
                collapsable={trainForm.length > 0}
                style={{ marginBottom: '20px' }}
              >
                {plusButton('trainForm')}
                {trainForm.length > 0 &&
                  trainForm.map((item, index) => (
                    <Train
                      key={index}
                      index={index}
                      onUpdate={updateItem}
                      onDelete={deleteItem}
                      item={item}
                    />
                  ))}
              </Card>
            )}

            {carForm.length > 0 && (
              <Card
                key='car'
                title='AUTO'
                collapsable={carForm.length > 0}
                style={{ marginBottom: '20px' }}
              >
                {plusButton('carForm')}
                {carForm.length > 0 &&
                  carForm.map((item, index) => (
                    <Car
                      key={index}
                      index={index}
                      onUpdate={updateItem}
                      onDelete={deleteItem}
                      item={item}
                    />
                  ))}
              </Card>
            )}

            {cruiseForm.length > 0 && (
              <Card
                key='cruise'
                title='NAVE'
                collapsable={cruiseForm.length > 0}
                style={{ marginBottom: '20px' }}
              >
                {plusButton('cruiseForm')}
                {cruiseForm.length > 0 &&
                  cruiseForm.map((item, index) => (
                    <Cruise
                      key={index}
                      index={index}
                      onUpdate={updateItem}
                      onDelete={deleteItem}
                      item={item}
                    />
                  ))}
              </Card>
            )}
            {atlDataDefault.type !== 'VIP' &&
              <Card key='note' title='Note sul viaggio' style={{ marginBottom: '20px' }}>
                <TextareaAuto
                  placeholder='Note visibili anche al cliente'
                  value={notesForm}
                  style={{ marginTop: 20 }}
                  onChange={(e) => {
                    setNotesForm(e.target.value)
                  }}
                />
              </Card>}
          </div>
          {atlDataDefault.type !== 'VIP' &&
            <Flex fw>
              <BarBottom action={(type) => newItem(type)} params={['']} hidePassengers hideMoneyForm />
              <Button rich icon='check' label='Salva nuovi servizi pratica' style={{ width: 250, marginBottom: 40, padding: 10, height: 40, cursor: 'pointer' }} onClick={handleAddExtraTour} />
            </Flex>}
        </div>
      </>)
}

export default AtlanteTours
