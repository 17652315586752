import React from 'react'
import _ from 'lodash'

import { Spinner, Flex, Card, LogsView } from '@Common/Components'
import { useCQuery } from '../../Services'
import state from '../../State'
import TourPreview from '../TourPreview'

export default function Footer ({ tourId }) {
  const { data: tour = {}, isSuccess: isSuccessTour } = useCQuery(['tour', tourId])
  const [tourData] = state.tourData.useState()

  if (!isSuccessTour) return <Spinner />
  return (
    <Flex row as js fw>
      {!(!tour || _.isEmpty(tour)) &&
        <Card collapsable collapsed style={{ width: '49%', marginRight: '1%', marginTop: 20, marginBottom: 40 }} title='Anteprima visualizzazione app'>
          <TourPreview tour={tour} />
        </Card>}
      <Card title='Stato di attività' collapsable collapsed style={{ marginBottom: 20, marginTop: 20, width: (!tour || _.isEmpty(tour)) ? '100%' : '50%' }}>
        <Flex>
          <LogsView title='Attivita del viaggio' value={tourData.logs || []} extraText='il viaggio' />
        </Flex>
      </Card>
    </Flex>
  )
}
