import React from 'react'
import PraDisplay from './PraDisplay'
import BgtDisplay from './BgtDisplay'
import VouDisplay from './VouDisplay'
import TcDisplay from './TcDisplay'

function DisplayHeaderAtlModern ({ type, atlData }) {
  const renderType = () => {
    const config = {
      pra: <PraDisplay data={atlData} />,
      bgt: <BgtDisplay data={atlData} />,
      vou: <VouDisplay data={atlData} />,
      tc: <TcDisplay data={atlData} />
    }

    return config[type] || null
  }

  return <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>{renderType()}</div>
}

export default DisplayHeaderAtlModern
