import moment from 'moment'
import _ from 'lodash'

import state from '../State'
import { invalidateQuery } from '../Services'
import { FC, readFile } from '@Common/Services'
import { showGrowl } from './Common'

export const getUpdatedLogs = (user, action, pastLogs) => {
  const log = {
    user: user._id,
    lastUpdate: moment(),
    operator: `${user.name} ${user.lastName}`,
    action
  }
  const logs = pastLogs ? Array.isArray(pastLogs) ? [log, ...pastLogs] : [log, pastLogs] : [log]
  return logs
}

const uploadFileOnAWS = async ({ _id, refFile }, offerId, offerStatus) => {
  if (!refFile) return _id
  try {
    if (refFile.size > 4000 * 1024) throw new Error('File too big')
    const uri = await readFile(refFile)
    const uploadedFile = await FC.client.service('upload').create({ uri }, { query: { entity: 'offers', id: offerId } })
    const patchPayload = {
      file: refFile.name,
      format: refFile.type,
      from: 'BACKEND_APPTOUR',
      status: 1
    }
    await FC.client.service('documents').patch(uploadedFile.documentId, patchPayload)
    return uploadedFile.documentId
  } catch (e) {
    showGrowl('error', 'Errore inserimento immagine', e.message === 'File too big' ? 'File troppo grande' : 'Errore caricamento immagine. Riprova')
  }
  return null
}

const uploadFiles = async (offerStatus, offerId) => {
  const mapFunctionUpload = (status, id) => async (document, index) => {
    if (!document?.needUpload) return { ...document, order: index }
    const documentId = await uploadFileOnAWS(document, id, status)
    return { ...document, _id: documentId, needUpload: false, order: index }
  }
  return Promise.all(state.offerDocuments.state.map(mapFunctionUpload(offerStatus, offerId)))
}

const modifyOffer = async (offer, user) => {
  try {
    const offerFiles = await uploadFiles(offer.status, offer._id)
    const documentsOrder = offerFiles.filter((doc) => doc).sort((d1, d2) => d1.order - d2.order).map((doc) => doc._id)
    offer.documentsOrder = [...documentsOrder]

    const logs = getUpdatedLogs(user, 'Ha modificato l\'offerta', offer?.logs)

    const res = await FC.service('offers').patch(offer._id, { ...offer, logs })
    if (!res) throw new Error('Errore modifica offerta')
    state.offerDocuments.setState(offerFiles)

    showGrowl('success', 'Modifica offerta', 'Offerta modificata correttamente.')
    return offer?._id
  } catch {
    return false
  }
}

const createOffer = async ({ _id, ...offer }, status, user) => {
  try {
    const logs = getUpdatedLogs(user, 'Ha creato l\'offerta', offer?.logs)
    offer.logs = [...logs]

    const createdOffer = await FC.service('offers').create({
      ...offer,
      opUnit: state.auth.state.opUnit
    })

    if (!createdOffer) throw new Error('Errore creazione offerta')

    const offerDocuments = await uploadFiles(offer.status, createdOffer._id)
    const documentsOrder = offerDocuments.filter((doc) => doc).sort((d1, d2) => d1.order - d2.order).map((doc) => doc._id)
    const res = await FC.service('offers').patch(createdOffer._id, { documentsOrder })
    if (!res) throw new Error('Errore creazione offerta')

    state.offerMainData.setState({ ...state.offerMainData.state, _id: createdOffer?._id })
    state.offerDocuments.setState(offerDocuments)

    showGrowl('success', 'Creazione offerta', 'Offerta creata correttamente.')
    return createdOffer?._id
  } catch (e) {
    return false
  }
}

/*  */
const createOfferObject = (user, type) => {
  return {
    status: type,
    agency: user.agency || state.auth.state.userAgency,
    createdAt: moment(),
    category: { ...state.offerCategories.state },
    logs: [...state.offerLogs.state],
    ...state.offerMainData.state,
    ...state.offerDetails.state
  }
}

/* Error functions */

const checkErrorsForDraft = () => {
  const checkError = !state.offerMainData.state.title || !state.offerMainData.state.price || !state.offerMainData.state.publish || !state.offerMainData.state.deadline
  return { error: checkError, ...(checkError ? { message: 'Compilare tutti i campi obbligatori' } : {}) }
}

export const checkCategoriesExistence = () => {
  if (_.isEmpty(state.offerCategories.state)) return true
  const keysList = Object.keys(state.offerCategories.state)
  const foundCategory = keysList.find((item) => state.offerCategories.state[item] === 1)
  if (foundCategory) return false
  return true
}

const checkErrosForPublish = () => {
  const checkError = !state.offerMainData.state.title ||
    !state.offerMainData.state.publish ||
    !state.offerMainData.state.deadline ||
    !state.offerMainData.state.price ||
    !state.offerDetails.state.description ||
    !state.offerDocuments.state.length ||
    checkCategoriesExistence()

  return { error: checkError, ...(checkError ? { message: 'Compilare tutti i campi obbligatori' } : {}) }
}

const checkErrorsForDate = () => {
  const { deadline, publish } = state?.offerMainData?.state

  if (!deadline || !publish) return true

  if (moment(deadline).diff(moment(), 'days') < 0) return { error: true, message: 'La data di scadenza deve essere più alta della data odierna' }
  if (moment(publish).diff(moment(deadline), 'days') > 0) return { error: true, message: 'La data di scadenza deve essere più alta della data di pubblicazione' }
  return { error: false }
}

const checkSaveErrors = (type) => {
  if (type === 0) return checkErrorsForDraft()

  const isError = checkErrosForPublish().error || checkErrorsForDate().error
  return { error: isError, ...(isError ? { message: checkErrosForPublish().message || checkErrorsForDate().message } : {}) }
}

/* Funzione di salvataggio */
export const saveOffer = async (user, type) => {
  if (checkSaveErrors(type).error) {
    showGrowl('error', 'Attenzione errore', checkSaveErrors(type).message)
    return false
  }

  const offer = createOfferObject(user, type)
  let id = ''
  if (offer?._id && !state.offerActions.state.copied) id = await modifyOffer(offer, user)
  else id = await createOffer(offer, type, user)

  state.startCheckErrorsGlobal.setState(false)
  state.startCheckErrorsSyncAppGlobal.setState(false)
  invalidateQuery(['offers', 'offer'])
  return id
}
