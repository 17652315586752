import React from 'react'
import { Input, Text, NumberPrefix } from '@Common/Components'
import state from '../State'
import { useWindowSize } from 'react-use'

const GRID_SMALL =
  `
'. . image .'
'name name name name'
'lastName lastName lastName lastName'
'email email email email'
'address address address address'
'city city city city'
'cityCode cityCode cityCode cityCode'
'zipNumber zipNumber zipNumber zipNumber'
'phone phone phone phone'
'ssn ssn ssn ssn'
'vat vat vat vat'
`

const GRID_NORMAL =
  `
'name name name lastName lastName lastName'
'email email email address address address'
'city city cityCode cityCode zipNumber zipNumber'
'phone phone phone phone phone phone'
'ssn ssn ssn vat vat vat'
`

export const ClientFormData = ({
  client,
  prefixCountry,
  onChangeState,
  onChangeSelectedCountry,
  phone,
  colorRed
}) => {
  const { width: screenWidth } = useWindowSize()
  const config = state.config.state

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text
        upCase
        value={'Informazioni principali dell\'utente'}
        style={{ marginTop: 15, marginBottom: 10 }}
      />
      <div style={{ display: 'flex' }}>
        <div
          style={{
            padding: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 120,
            height: 100,
            backgroundColor: 'white',
            marginRight: 20
          }}
        >
          <img
            src={
              client.avatar
                ? client.avatar.includes(config.awsBaseURL)
                    ? client.avatar
                    : config.awsBaseURL + client.avatar
                : 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'
            }
            alt='NO LOGO'
            style={{
              width: '100%',
              height: '100%',
              backgroundSize: 'cover',
              margin: 'auto'
            }}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateAreas: screenWidth > 800 ? GRID_NORMAL : GRID_SMALL,
            gridGap: '9px 9px',
            width: '100%',
            height: 'max-content'
          }}
        >
          <Input
            id='name'
            error={!client.name && colorRed}
            style={{ gridArea: 'name', height: 35 }}
            label='Nome'
            value={client.name || ''}
            onChange={(e) => onChangeState(e)}
          />
          <Input
            id='lastName'
            error={!client.lastName && colorRed}
            style={{ gridArea: 'lastName', height: 35 }}
            label='Cognome'
            value={client.lastName || ''}
            onChange={(e) => onChangeState(e)}
          />
          <Input
            id='address'
            style={{ gridArea: 'address', height: 35 }}
            label='Via'
            value={client.address || ''}
            onChange={(e) => onChangeState(e)}
          />
          <Input
            id='city'
            style={{ gridArea: 'city', height: 35 }}
            label='Città'
            value={client.city || ''}
            onChange={(e) => onChangeState(e)}
          />
          <Input
            id='cityCode'
            style={{ gridArea: 'cityCode', height: 35 }}
            label='Provincia'
            value={client.cityCode || ''}
            onChange={(e) => onChangeState(e)}
          />
          <Input
            id='zipNumber'
            style={{ gridArea: 'zipNumber', height: 35 }}
            label='CAP'
            value={client.zipNumber || ''}
            onChange={(e) => onChangeState(e)}
          />
          <Input
            id='email'
            error={!client.email && colorRed}
            style={{
              gridArea: 'email',
              height: 35,
              marginRight: 5,
              marginTop: screenWidth > config.smallScreen ? 0 : 5
            }}
            label='Email'
            value={client.email || ''}
            onChange={(e) => onChangeState(e)}
          />
          <div
            style={{
              gridArea: 'phone',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <NumberPrefix
              style={{ marginRight: 5 }}
              onChangeSelectedCountry={onChangeSelectedCountry}
              prefixCountry={prefixCountry}
            />
            <Input
              error={!client._id && colorRed}
              keyfilter='pint'
              disabled={!!client._id}
              id='phone'
              style={{
                gridArea: 'id',
                width: '80%',
                height: 35,
                marginTop: screenWidth > config.smallScreen ? 0 : 10
              }}
              label='Cellulare / telefono'
              value={phone || ''}
              onChange={(e) => onChangeState(e)}
            />
          </div>
          <Input
            id='SSN'
            style={{ height: 35, marginRight: 5, gridArea: 'ssn' }}
            label='Codice fiscale'
            value={client.SSN || ''}
            onChange={(e) => onChangeState(e)}
          />
          <Input
            id='VAT'
            style={{ height: 35, gridArea: 'vat' }}
            label='Partita IVA'
            value={client.VAT || ''}
            onChange={(e) => onChangeState(e)}
          />
        </div>
      </div>
    </div>
  )
}
