import React, { useState } from 'react'
import moment from 'moment'
import { Text, Input, Spinner, Flex, Card } from '@Common/Components'
import { FC, useHandleNavigate, Themes } from '@Common/Services'
import { prefetchQuery, invalidateQuery, useCInfiniteQuery } from '../Services/QueryCache'
import { Chat } from '../Components/Chat'
import { ConversationInList } from '../Components/ConversationInList'
import { useParams } from 'react-router-dom'

const Conversations = () => {
  const [searchBar, setSearchBar] = useState('')
  const navigate = useHandleNavigate()
  const selectedConversation = useParams().selectedConversation
  const { data: conversations = [], isSuccess, isFetchingNextPage: isStillLoading } = useCInfiniteQuery('conversations')

  const handleSelectConversation = async (currentCV) => {
    if (selectedConversation === currentCV._id) return false
    if (currentCV.nextActor === 'agency') {
      FC.client.service('conversations').patch(currentCV._id, { nextActor: 'none', newAgency: 0 })
        .then(() => invalidateQuery(['conversations', 'badges']))
    }
    prefetchQuery(['messages', currentCV._id])
    navigate('/messages/' + currentCV._id.toString())
  }

  const sortConversations = (a, b) => moment(b.lastUpdate).diff(moment(a.lastUpdate))

  const processConversation = (ele, i) => ({
    type: 'chat',
    icon: 'account',
    isCurrent: selectedConversation === ele._id,
    action: () => handleSelectConversation(ele),
    highlited: ele.nextActor === 'agency',
    date: ele.lastUpdate,
    subject: ele.subject || ele.lastMessage,
    user: ele.userId,
    userData: ele.userData,
    badge: ele.nextActor === 'agency' ? ele.newAgency : 0,
    groupChat: !!(ele?.groupChat),
    ...(ele?.groupChat?.avatar ? { groupChatAvatar: ele.groupChat.avatar } : {})
  })

  const searchConversations = (ele) => !searchBar ||
    (!ele.subject || (ele?.subject.toLocaleLowerCase().search(searchBar.toLocaleLowerCase()) > -1)) ||
    (!ele.userData?.length || (ele?.userData.map(({ name }) => name?.toLocaleLowerCase() || '').join(' ').search(searchBar.toLocaleLowerCase()) > -1)) ||
    (!ele.userData?.length || (ele?.userData.map(({ phone }) => phone?.toLocaleLowerCase() || '').join(' ').search(searchBar.toLocaleLowerCase()) > -1))

  const noMessageConversations = (ele) => ele.subject

  const displayConversations = conversations.sort(sortConversations).map(processConversation).filter(searchConversations).filter(noMessageConversations)

  const renderEmptyPage = () => (
    <Card
      style={{
        width: '100%',
        marginBottom: 20,
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Text value='Seleziona una conversazione' size={18} />
    </Card>
  )

  return (
    <>
      <Flex js style={styles.sidebar}>
        <Input
          id='searchBar' icon='lens' placeholder='Cerca'
          value={searchBar} onChange={({ searchBar }) => setSearchBar(searchBar)}
        />
        <Text title bold upCase style={{ marginTop: 20, marginBottom: 10 }} value='Conversazioni recenti' />
        {!isSuccess
          ? <Spinner />
          : (
            <>
              <div style={{ overflow: 'auto', overflowX: 'hidden', scrollbarWidth: 'none', width: '100%' }}>
                {displayConversations.map((ele, i) => <ConversationInList key={i} ele={ele} />)}
                {isStillLoading && <Flex fw><Spinner /></Flex>}
              </div>
            </>)}
      </Flex>
      <Flex row fw fh>
        <Flex style={{ width: 410 }} />
        <Flex fw fh js style={{ width: '100%', overflow: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}>
          {selectedConversation === 'chat' ? renderEmptyPage() : <Chat />}
        </Flex>
      </Flex>

    </>
  )
}

export default Conversations

const styles = {
  sidebar: {
    padding: 10,
    position: 'fixed',
    top: 50,
    left: 0,
    bottom: 0,
    backgroundColor: Themes.colors.translucent,
    width: 320,
    minWidth: 320,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbars: 'none'
  }
}
