import _ from 'lodash'
import moment from 'moment'

const toCamelCase = (string) => _.startCase(_.toLower(string))

const getStartAndEndTime = (startHour, endHour) => {
  const formatCheck = (data) => !!Date.parse(data)

  const startTime = formatCheck(startHour) ? moment(startHour).format('HH:mm') : startHour
  const endTime = formatCheck(endHour) ? moment(endHour).format('HH:mm') : endHour

  return {
    startTime,
    endTime
  }
}

export const getTimelineList = (tour) => {
  let events = []
  tour.hotels.forEach((hotel) =>
    events.push({
      type: 'hotel',
      title: `Hotel ${toCamelCase(hotel?.name?.toLowerCase()?.replace('hotel', '')) || ''}`,
      details: [{
        text: hotel.description || ''
      }
      ],
      date: getDayObj(moment(hotel.startDate))
    })
  )
  tour.flights.forEach((flight) => {
    const { startTime, endTime = '' } = getStartAndEndTime(flight.startHour, flight.endHour)
    const startAirport = flight.startAirport || ''
    const endAirport = flight.endAirport || ''
    const code = flight.code || ''
    let endObj = {}
    if (endTime !== '') {
      endObj = {
        text: `A ${endAirport}`,
        time: endTime
      }
    }
    events.push({
      type: 'flight',
      title: flight.description ? `${flight.description}` : 'Volo',
      details: [{
        text: flight.startAirport ? `Da ${startAirport}` : 'Partenza',
        time: startTime
      },
      endObj,
      {
        text: flight.code && `Numero del volo ${code}`
      }],
      date: getDayObj(moment(flight.startDate))
    })
  }
  )
  tour.trains.forEach((train) => {
    const { startTime, endTime = '' } = getStartAndEndTime(train.startHour, train.endHour)
    const endStation = train.endStation || ''
    let endObj = {}
    if (endTime !== '') {
      endObj = {
        text: `A ${endStation}`,
        time: endTime
      }
    }
    events.push({
      type: 'train',
      title: train.description,
      details: [
        {
          text: train.startStation ? `Da ${train.startStation}` : 'Partenza',
          time: startTime
        },
        endObj,
        (train.company ? { text: `Compagnia: ${train.company}` } : {}),
        (train.code ? { text: `Codice treno: ${train.code}` } : {})
      ],
      date: getDayObj(moment(train.startDate))
    })
  }
  )
  tour.transfers.forEach((transfer) => events.push({
    type: 'transfer',
    title: 'Transfer',
    details: [{
      text: transfer.description
    },
    {
      text: transfer.company && `'Company': ${transfer.company}`
    }],
    date: getDayObj(moment(transfer.startDate))
  }))

  tour.cruises.forEach((cruise) => {
    const { startTime, endTime = '' } = getStartAndEndTime(cruise.startHour, cruise.endHour)
    const startPort = cruise.startPort ? `${cruise.startPort}` : ''
    const endPort = cruise.endPort ? `${cruise.endPort}` : ''
    let endObj = {}
    if (endTime !== '') {
      endObj = {
        text: `A ${endPort}`,
        time: endTime
      }
    }
    events.push({
      type: 'cruise',
      title: cruise.description,
      details: [{
        text: cruise.startPort ? `Da ${startPort}` : 'Partenza',
        time: startTime
      }],
      date: getDayObj(moment(cruise.startDate))
    })
    if (cruise.endDate && cruise.startDate !== cruise.endDate) {
      events.push({
        type: 'cruise',
        title: cruise.description,
        details: [endObj],
        date: getDayObj(moment(cruise.startDate))
      })
    }
  })

  tour.services.forEach((service) => {
    const { startTime, endTime = '' } = getStartAndEndTime(service.startHour, service.endHour)
    events.push({
      type: 'service',
      title: service.description || 'Servizi Richiesti',
      details: [{
        text: `${service.address || ''} ${service.num || ''} ${service.city || ''}${(service.address || service.num || service.city) ? '\n' : ''}${service.cap || ''} ${service.province || ''}${(service.cap || service.province) ? '\n' : ''}${service.telephone || ''}${service.telephone ? '\n' : ''}${(startTime ? 'Orario: ' + startTime : '')}${startTime ? '\n' : ''}${(endTime ? 'Ora fine: ' + endTime : '')}`,
        icon: service.icon || 'ticket'
      }],
      date: getDayObj(moment(service.startDate))
    })
  }
  )

  events = _.sortBy(events, 'date.timestamp')
  const timeline = []
  let currentDay = 0
  for (let i = 0; i < events.length; i++) {
    if (currentDay !== events[i].date.date) {
      timeline.push({
        date: events[i].date,
        events: [],
        singleTicket: tour.type.includes('BGT')
      })
      currentDay = events[i].date.date
    }
    timeline[timeline.length - 1].events.push(events[i])
  }
  return timeline
}

export const getDayObj = (dateSt) => {
  const date = dateSt === undefined || dateSt === '' ? moment() : moment(dateSt, 'DD/MM/YYYY')
  const dateObj = date.toObject()
  dateObj.longMonth = date.format('MMMM')
  dateObj.shortMonth = date.format('MMM')
  dateObj.shortYear = date.format('YY')
  dateObj.dayName = date.format('dddd')
  dateObj.dayShortName = date.format('ddd')
  dateObj.timestamp = date.unix()
  dateObj.moment = date
  return dateObj
}
