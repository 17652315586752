import { FC } from '@Common/Services'
import state from '../State'
import { saveFunction } from './Save'

export const showGrowl = (severity, summary, detail, sticky = false, life = 3000) => {
  return (
    window.growl.show({
      severity,
      summary,
      detail,
      sticky,
      life
    })
  )
}

export const clearAll = () => {
  // Di tourData faccio il clear solo dei dati compilabili

  if (state?.tourData?.state?._id) state.tourActions.setState({ ...state.tourActions.state, saved: false })
  else state.tourActions.setState({ saved: true, copied: false, financeData: false })

  state.tourData.setState({ ...state.tourData.state, startDate: '', endDate: '', tripDays: '', tripNights: '', description: '', note: '', status: 0 })
  state.toursDocuments.setState([])
  state.clientData.setState({})
  state.cruiseForm.setState([])
  state.flightForm.setState([])
  state.hotelForm.setState([])
  state.serviceForm.setState([])
  state.trainForm.setState([])
  state.carForm.setState([])
  state.moneyForm.setState([])
  state.passengers.setState([])
}

export const countDaysNight = (day1, day2) => {
  if (!day1 || !day2) return null
  const t2 = day2.getTime()
  const t1 = day1.getTime()
  if (!t1 || !t2) return null
  const nights = parseInt((t2 - t1) / (24 * 3600 * 1000))
  return [nights.toString(), (nights + 1).toString()]
}

export const printPDF = async (user) => {
  const { status, _id, tourResolver } = state.tourData.state

  if (status !== 1) {
    showGrowl('error', 'Impossibile generare PDF!', `Sincronizzare il viaggio ${_id ? _id.split('@').pop() : ''}  per poter stampare`)
    return false
  } else {
    // Controllare gli errori.
    await saveFunction(user, status)
    const qrCode = await FC.client.service('res').get(tourResolver)
    const downloadLink = qrCode.qrcode || qrCode.link

    if (!downloadLink) showGrowl('error', 'Impossibilie generare PDF!', 'Si è verificato un problema, il viaggio non dispone dei dati necessari, contattare il supporto')
    else {
      // showGrowl('info', growlContent, '')
      const a = document.createElement('a')
      a.href = downloadLink
      a.download = `${tourResolver}-qrcode.pdf`
      a.target = '_blank'
      a.click()
    }
    return true
  }
}

/**
 * Compare two JSON return TRUE if there are no differences
 * @param id, tourID of the tour i need to compare with the newJson
 * @param newJson the new values
 * I need to check if the values of:
      - cruises: PropTypes.array,
      - description: PropTypes.string,
      - endDate: PropTypes.string,
      - flights: PropTypes.array,
      - hotels: PropTypes.array,
      - money: PropTypes.array,
      - passengers: PropTypes.array,
      - services: PropTypes.array,
      - startDate: PropTypes.string,
      - status: PropTypes.number,
      - trains: PropTypes.array,
      - transfers: PropTypes.array,
      - tripDays: PropTypes.string,
      - tripNights: PropTypes.string
    in newJson are differents from the values in tour.
    If some of this fields if differents the function will return true.
    Else the function will return false
 */
export const comparatorJSON = async (id, newJson) => {
  try {
    const tour = await FC.service('tours').get(id)
    if (!arraysEqual(newJson.cruises, tour.cruises)) return false
    if (!arraysEqual(newJson.flights, tour.flights)) return false
    if (!arraysEqual(newJson.hotels, tour.hotels)) return false
    if (!arraysEqual(newJson.transfers, tour.transfers)) return false
    if (!arraysEqual(newJson.money, tour.money)) return false
    if (!arraysEqual(newJson.passengers, tour.passengers)) return false
    if (!arraysEqual(newJson.services, tour.services)) return false
    if (!arraysEqual(newJson.trains, tour.trains)) return false
    if (!arraysEqual(newJson.documentsOrder, tour.documentsOrder)) return false

    if (newJson.endDate !== tour.endDate) return false
    if (newJson.description !== tour.description) return false
    if (newJson.startDate !== tour.startDate) return false
    if (newJson.tripDays !== tour.tripDays) return false
    if (newJson.tripNights !== tour.tripNights) return false
    if (newJson.note !== tour.note) return false
    if (newJson.status !== tour.status) return false

    return true
  } catch (e) {
    return false
  }
}

const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2

const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
