import React from 'react'

import { Card, LogsView } from '@Common/Components'
import state from '../../State'

export default function Activities () {
  const [offerLogs] = state.offerLogs.useState()
  return (
    <Card title='Stato di attività' collapsed collapsable style={{ marginBottom: 50, marginTop: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <LogsView
          title={'Attivita dell\'offerta'}
          value={offerLogs || []}
          extraText={'l\'offerta'}
        />
      </div>
    </Card>
  )
}
