import React, { useState } from 'react'
import moment from 'moment'
import { Input, Button, FadeIn } from '@Common/Components'
import state from '../../../../State'
import { motion } from 'framer-motion'

const GRID_NORMAL = [
  `'startAirport startAirport startAirport endAirport endAirport endAirport code code'
    'endDate endDate endDate endHour endHour endHour company company'
  `,
  '\' startDate startHour description description  \''
]
const GRID_SMALL = [
  `'startAirport startAirport startAirport startAirport endAirport endAirport endAirport endAirport  '
  'endDate endDate endDate endDate endHour endHour endHour endHour'
  'code code code code company company company company'
`,
  `'startDate startDate startDate'
'startHour startHour startHour'
'description description description'
`
]

const Volo = ({ item = {}, onDelete, index, onUpdate, price }) => {
  const [showOptional, setShowOptional] = useState(false)

  const updateItem = (update) => {
    const { _id = '' } = item
    onUpdate('flightForm', { _id, ...update })
  }

  const { main, grid } = styles
  const config = state.config.state
  const screenWidth = 1500

  const renderContent = () => (
    <>
      <div style={main}>
        <div
          style={{
            display: 'grid',
            width: '100%',
            gridTemplateAreas: screenWidth > config.mediumScreen + 150 ? GRID_NORMAL[1] : GRID_SMALL[1],
            gridGap: '9px'
          }}
        >
          <Input
            date
            id='startDate'
            label='Data Partenza'
            style={{ width: '100%', marginRight: 5, gridArea: 'startDate' }}
            onChange={(e) => updateItem(e)}
            value={item.startDate ? moment(item.startDate).toDate() : ''}
          />
          <Input
            time
            id='startHour'
            label='Ora partenza'
            style={{
              width: '100%',
              marginRight: 5,
              gridArea: 'startHour',
              marginLeft: screenWidth > config.mediumScreen + 250 ? 5 : 0
            }}
            onChange={(e) =>
              updateItem({
                startHour: e.startHour !== null ? moment(e.startHour).format('HH:mm') : null
              })}
            value={
              item.startHour
                ? item.startHour.length > 5
                    ? moment(item.startHour).toDate()
                    : moment(item.startHour, 'HH:mm').toDate()
                : ''
            }
          />
          {price &&
            (
              <Input
                keyfilter='Money'
                label='Prezzo'
                id='price'
                style={{
                  width: '100%',
                  gridArea: 'price',
                  marginLeft: screenWidth > config.mediumScreen + 250 ? 5 : 0
                }}
                value={item.price || ''}
                onChange={(e) => updateItem(e)}
              />
            )}
          <Input
            id='description'
            label='Descrizione:'
            style={{
              width: '100%',
              gridArea: 'description',
              marginLeft: screenWidth > config.mediumScreen + 250 ? 5 : 0
            }}
            onChange={(e) => updateItem(e)}
            value={item.description || ''}
          />
        </div>
        <motion.div
          variants={{
            start: { display: 'none', opacity: 0, height: 0, transition: { default: { duration: 0.5 } } },
            end: { display: 'grid', opacity: 1, height: 'auto', transition: { duration: 0.5 } }
          }}
          initial='start' animate={showOptional ? 'end' : 'start'}
          style={{ ...grid, gridTemplateAreas: screenWidth > config.smallScreen + 450 ? GRID_NORMAL[0] : GRID_SMALL[0] }}
        >
          <Input
            id='startAirport'
            label='Aeroporto partenza:'
            style={{ gridArea: 'startAirport' }}
            onChange={(e) => updateItem(e)}
            value={item.startAirport || ''}
          />
          <Input
            id='endAirport'
            label='Aeroporto arrivo:'
            style={{ gridArea: 'endAirport' }}
            onChange={(e) => updateItem(e)}
            value={item.endAirport || ''}
          />
          <Input
            date
            id='endDate'
            label='Data arrivo'
            style={{ width: '100%', gridArea: 'endDate' }}
            onChange={(e) => updateItem(e)}
            value={item.endDate ? moment(item.endDate).toDate() : ''}
          />
          <Input
            time
            id='endHour'
            label='Ora arrivo'
            style={{ width: '100%', gridArea: 'endHour' }}
            onChange={(e) =>
              updateItem({ endHour: e.endHour !== null ? moment(e.endHour).format('HH:mm') : null })}
            value={item.endHour ? item.endHour.length > 5 ? moment(item.endHour).toDate() : moment(item.endHour, 'HH:mm').toDate() : ''}
          />
          <Input
            id='code'
            label='Cod. volo'
            style={{ gridArea: 'code' }}
            onChange={(e) => updateItem(e)}
            value={item.code || ''}
          />
          <Input
            id='company'
            label='Compagnia aerea'
            style={{ height: 33, gridArea: 'company' }}
            onChange={(e) => updateItem(e)}
            value={item.company || ''}
          />
        </motion.div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          marginLeft: 15
        }}
      >
        <Button
          rich
          icon='bin'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => onDelete('flightForm', item._id)}
        />
        <Button
          rich
          icon='details'
          round
          style={{ margin: 3 }}
          width={30}
          onClick={() => setShowOptional(!showOptional)}
        />
      </div>
    </>)

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {index > 0 && (
        <hr
          style={{
            height: 2,
            width: '100%',
            backgroundColor: 'white',
            border: 'none'
          }}
        />
      )}
      {index > 0
        ? (
          <FadeIn style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </FadeIn>)
        : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {renderContent()}
          </div>)}

    </div>
  )
}

const styles = {
  main: {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: 5,
    display: 'grid',
    gridGap: '9px 9px',
    width: '100%',
    height: 'max-content'
  }
}

export default Volo
