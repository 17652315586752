import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import { CheckRegex } from '@Common/Services'
import { Offer, OffersList } from './Pages'

export const Offers = (
  <>
    <Route path='/offers/*' element={<Navigate to='/offers/list' />} />
    <Route path='/offers/list' element={<OffersList />} />
    <Route path='/offers/new' element={<Offer />} />
    <Route path='/offers/:offerId' element={<Offer />} />
  </>
)
// element={<CheckRegex regex='^[0-9a-fA-F]{24}$' ifNotExistsNavigate='/offers/list'><Offer /></CheckRegex>} />
