import { getMergedState } from '@Common/State'
import { invalidateQuery } from '../Services'

const sessionState = {
  startCheckErrorsGlobal: false,
  startCheckErrorsSyncAppGlobal: false,
  tourData: {},
  toursDocuments: [],
  clientData: {},
  cruiseForm: [],
  flightForm: [],
  hotelForm: [],
  serviceForm: [],
  trainForm: [],
  carForm: [],
  moneyForm: [],
  passengers: [],
  tourActions: {
    saved: true,
    copied: false,
    financeData: false
  }
}
const storedState = {
}

const state = getMergedState(sessionState, storedState)

export const initiTourState = (type, tour) => {
  // Type è il tipo di tour che clicchiamo. PRA-VIP-(BGT-A)-(BGT-V)-VOU
  let tourData = {}
  let clientData = { phone: '', surname: '', name: '', email: '' }
  let startDate, endDate, tripDays, tripNights, description, phone, surname, name, email, itinerary
  const tourResolver = tour.resolver
  let flightForm,
    hotelForm,
    serviceForm,
    trainForm,
    passengers,
    carForm,
    cruiseForm,
    moneyForm,
    toursDocuments
  switch (type) {
    case 'VIP':
    case 'PRA':
      // const { startDate, endDate, tripDays, tripNights, description } = tour
      ; ({ startDate, endDate, tripDays, tripNights, description } = {
        startDate: tour.startDate,
        endDate: tour.endDate,
        tripDays: tour.tripDays,
        tripNights: tour.tripNights,
        description: tour.description
      })
      tourData = {
        startDate,
        endDate,
        tripDays,
        tripNights,
        description,
        tourResolver
      }
      if (tour.passengers.length > 0) {
        ; ({ phone, surname, name, email } = {
          phone: tour.passengers[0].phone,
          surname: tour.passengers[0].surname,
          name: tour.passengers[0].name,
          email: tour.passengers[0].email
        })
        // const { phone, surname, name, email } = tour.passengers[0]
        clientData = {
          phone: phone || '',
          surname: surname || '',
          name: name || '',
          email: email || ''
        }
      }
      state.clientData.setState(clientData)
      state.tourData.setState(tourData)
      break
    case 'BGT-V':
    case 'BGT-A':
      ; ({ startDate, endDate, itinerary } = {
        startDate: tour.startTrip,
        endDate: tour.endTrip,
        itinerary: tour.itinerary
      })
      tourData = {
        startDate,
        endDate,
        tripDays: '0',
        tripNights: '0',
        tourResolver,
        description:
          type === 'BGT-A'
            ? `Biglietto Aereo: ${itinerary || 'Itinerario non disponibile'}`
            : `Biglietto: ${itinerary || 'Itinerario non disponibile'}`
      }
      if (tour.passengers.length > 0) {
        ; ({ phone, surname, name, email } = {
          phone: tour.passengers[0].phone,
          surname: tour.passengers[0].surname,
          name: tour.passengers[0].name,
          email: tour.passengers[0].email
        })
        clientData = {
          phone: phone || '',
          surname: surname || '',
          name: name || '',
          email: email || ''
        }
      }
      state.clientData.setState(clientData)
      state.tourData.setState(tourData)
      break
    case 'VOU':
      ; ({ startDate, endDate, tripDays, tripNights, description } = {
        startDate: tour.startDate,
        endDate: tour.endDate,
        tripDays: tour.days,
        tripNights: tour.nights
      })
      tourData = {
        startDate,
        endDate,
        tripDays,
        tripNights,
        tourResolver,
        description: 'Voucher'
      }
      if (tour.passenger.length > 0) {
        ; ({ phone, surname, name, email } = {
          phone: tour.passenger[0].phone,
          surname: tour.passenger[0].surname,
          name: tour.passenger[0].name,
          email: tour.passenger[0].email
        })
        clientData = {
          phone: phone || '',
          surname: surname || '',
          name: name || '',
          email: email || ''
        }
      }
      state.clientData.setState(clientData)
      state.tourData.setState(tourData)
      break
    case 'APP':
      ; ({
        flightForm,
        hotelForm,
        serviceForm,
        trainForm,
        passengers,
        carForm,
        cruiseForm,
        moneyForm,
        tourData,
        toursDocuments
      } = {
        flightForm: tour.flights,
        hotelForm: tour.hotels,
        serviceForm: tour.services,
        trainForm: tour.trains,
        passengers: tour.passengers,
        carForm: tour.transfers,
        cruiseForm: tour.cruises,
        moneyForm: tour.money,
        tourData: {
          note: tour.note,
          endDate: tour.endDate,
          type: tour.type,
          startDate: tour.startDate,
          description: tour.description,
          status: tour.status,
          _id: tour._id,
          tripDays: tour.tripDays,
          tripNights: tour.tripNights,
          tourResolver: tourResolver,
          logs: tour.logs
        },
        toursDocuments: tour.documents?.filter((doc) => doc.status === 1)
      })
      clientData = tour.passengers[0]
      passengers = tour.passengers.filter((item, i) => i > 0)
      state.toursDocuments.setState(toursDocuments)
      state.cruiseForm.setState(cruiseForm)
      state.flightForm.setState(flightForm)
      state.hotelForm.setState(hotelForm)
      state.serviceForm.setState(serviceForm)
      state.trainForm.setState(trainForm)
      state.carForm.setState(carForm)
      state.moneyForm.setState(moneyForm)
      state.passengers.setState(passengers)
      state.tourData.setState(tourData)
      state.clientData.setState(clientData)
      invalidateQuery('tour')
      break
    default:
      state.clientData.setState(clientData)
      state.tourData.setState(tourData)
      state.toursDocuments.setState([])
      state.cruiseForm.setState([])
      state.flightForm.setState([])
      state.hotelForm.setState([])
      state.serviceForm.setState([])
      state.trainForm.setState([])
      state.carForm.setState([])
      state.moneyForm.setState([])
      state.passengers.setState([])
      break
  }
}

export default state
