import React from 'react'
import { Text, TextareaAuto } from '@Common/Components'
import { useCQuery } from '../Services'

export const NoteClient = ({ client, onChangeState }) => {
  const { data: agency = {}, isSuccess: isSuccessAgency } = useCQuery('agency')
  if (!isSuccessAgency) return null
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
        <Text title bold style={{ marginRight: 5 }} value={'NOTE PER L\'AGENZIA'} />
        <Text value={'(Note visibili solamente dall\'agenzia)'} />
      </div>
      <TextareaAuto
        placeholder={'Note visibili dall\'agenzia'}
        value={client.note ? client.note[agency._id] : ''}
        onChange={(e) => onChangeState({ note: e.target.value })}
      />
    </>
  )
}
