import React from 'react'

import { Flex, Text, SelectSingleSquare } from '@Common/Components'
import state from '../../State'
import { checkCategoriesExistence } from '../../Functions'
import { useCQuery } from '@Common/Services'

export default function Categories () {
  const { data: agency } = useCQuery('agency')
  const [offerCategories, setOfferCategories] = state.offerCategories.useState()
  const [offerActions, setOfferActions] = state.offerActions.useState()

  const [startCheckErrorsSyncAppGlobal, setStartCheckErrorsSyncAppGlobal] = state.startCheckErrorsSyncAppGlobal.useState()

  const updateCategories = (update) => {
    setStartCheckErrorsSyncAppGlobal(false)
    setOfferActions({ ...offerActions, saved: false })
    setOfferCategories({ ...offerCategories, ...update })
  }
  return (
    <Flex fw js as style={{ marginTop: 10 }}>
      <Text bold title upCase value='Categorie' />
      <Flex row js as wrap style={{ marginTop: 10, border: startCheckErrorsSyncAppGlobal && checkCategoriesExistence() ? '2px solid red' : '' }}>
        {([...ICON_LIST, ...agency?.customCategories || []]).map((icon) => {
          return (
            <SelectSingleSquare
              key={icon.id}
              icon={icon?.icon || icon.id}
              text={icon.text}
              type={icon.id}
              style={styleSelect}
              value={offerCategories[icon.id]}
              onChange={updateCategories}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

const ICON_LIST = [
  { id: 'tent', text: 'Natura' },
  { id: 'cocktail', text: 'Intrattenimento' },
  { id: 'beach', text: 'Mare' },
  { id: 'extreme', text: 'Avventura' },
  { id: 'climbing', text: 'Sport' },
  { id: 'culture', text: 'Cultura' },
  { id: 'cruise', text: 'Crociera' },
  { id: 'family', text: 'Famiglia' },
  { id: 'gourmet', text: 'Gourmet' },
  { id: 'mountain', text: 'Montagna' },
  { id: 'camper', text: 'Camper' },
  { id: 'motorbike', text: 'Moto' }
]

const styleSelect = {
  off: {
    icon: 'white',
    text: 'white',
    backgroundColor: 'transparent'
  },
  on: {
    icon: '#32324e',
    text: '#32324e',
    backgroundColor: 'white'
  }
}
