import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useWindowSize } from 'react-use'
import moment from 'moment'

import { useHandleNavigate, FC } from '@Common/Services'
import { Flex, Button, Modal } from '@Common/Components'
import { showGrowl } from '../../Functions'
import state from '../../State'
import { invalidateQuery } from '../../Services'

export default function Table ({ tours, tourSelected, setTourSelected, showDeleteAll, sortDate, buttonProps }) {
  const { width: screenWidth } = useWindowSize()
  const navigate = useHandleNavigate()

  const columnProps = (field, header, filterPlaceholder, filter, filterMatchMode, showClearButton, showFilterMenu, sortable, style) => {
    return {
      ...(field ? { field } : {}),
      header,
      filterPlaceholder,
      filter: true,
      showClearButton: false,
      showFilterMenu: false,
      filterMatchMode: 'contains',
      sortable: true,
      style: { textAlign: 'center', border: 'none' }
    }
  }

  const onClickEditAction = (tour) => (ev) => {
    ev.stopPropagation()
    setTourSelected([tour])
    const foundTour = tours?.find((t) => t._id === tour?._id)
    if (!foundTour?._id) showGrowl('error', 'Attenzione', 'Si è verificato un errore. Ricarca la pagina e riprova.')
    else navigate('/tours/' + foundTour?._id)
  }

  const onClickDeleteAction = (tour) => (ev) => {
    ev.stopPropagation()
    setTourSelected([tour])
    showDeleteAll?.current?.show()
  }

  const columnActionsBody = (tour) => {
    return (
      <Flex row style={{ width: 140 }}>
        <Button
          {...buttonProps(true, true, 'edit', 'Modifica', { height: 30, marginRight: 5 })}
          onClick={onClickEditAction(tour)}
        />
        <Button
          {...buttonProps(true, true, 'bin', 'Elimina', { height: 30, marginLeft: 5 })}
          onClick={onClickDeleteAction(tour)}
        />
      </Flex>
    )
  }

  const columnDateBody = (tour) => moment(tour.creationDate).format('DD/MM/YYYY')

  const getLogsForDelete = (pastLogs) => {
    const log = {
      user: state.auth.state.userId,
      lastUpdate: moment(),
      operator: state.auth.userFullName,
      action: 'Ha cancellato il viaggio'
    }

    if (!pastLogs) return [log]
    return Array?.isArray(pastLogs) ? [log, ...pastLogs] : [log, pastLogs]
  }

  const deleteItems = async () => {
    try {
      for (const t of tourSelected) {
        await FC.service('tours').patch(t._id, { deleted: true, logs: getLogsForDelete(t.logs) })
      }
      invalidateQuery('tours')
      setTourSelected([])
    } catch (error) {
      if (error?.message === 'noSelected') showGrowl('error', 'Attenzione selezionare almeno un viaggio', 'Selezionane uno dalla tabella')
      else showGrowl('error', 'Attenzione, errore connessione, impossibile eliminare viaggio/i selezionate', 'Riprovare')
      setTourSelected([])
    }
  }

  return (
    <>
      <Modal
        ref={showDeleteAll}
        header={`Sicuro di voler cancellare ${tourSelected.length === 1 ? 'il' : 'i'} viagg${tourSelected.length === 1 ? 'io' : 'i'
          } selezionat${tourSelected.length === 1 ? 'o' : 'i'}?`}
        action={deleteItems}
      />
      <DataTable
        value={tours}
        paginator
        rows={10}
        responsiveLayout='scroll'
        emptyMessage='Nessuna pratica da visualizzare'
        className='TableList'
        selection={tourSelected}
        onSelectionChange={(e) => setTourSelected(e.value)}
        filterDisplay='row'
      >
        <Column selectionMode='multiple' style={{ width: '5px', height: '5px', marginTop: '10px' }} />
        <Column {...columnProps('_id', 'Titolo', '🔍   titolo')} />
        <Column {...columnProps('description', 'Descrizione', '🔍   descrizione')} />
        <Column {...columnProps('status', 'Status', '🔍   stato')} />
        <Column body={columnDateBody} {...columnProps('creationDate', 'Data creazione', '🔍   data creaz.')} sortFunction={sortDate} />
        <Column {...columnProps('resolver', 'Codice pratica', '🔍   cod. pra')} />
        <Column {...columnProps('operator', screenWidth > 1138 ? 'Modificato da' : 'Modificato', '🔍   modificato da')} />
        <Column body={columnActionsBody} style={{ border: 'none', width: '120px', height: '15px', textAlign: 'center' }} />
      </DataTable>
    </>
  )
}
