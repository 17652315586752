import React from 'react'
import { Text, SelectSingleSquare } from '@Common/Components'

export const CategoryClient = ({ client, iconList, updateCategory }) => {
  const styleSelect = {
    off: {
      icon: 'white',
      text: 'white',
      backgroundColor: 'transparent'
    },
    on: {
      icon: '#32324e',
      text: '#32324e',
      backgroundColor: 'white'
    }
  }
  return (
    <>
      <Text
        title
        style={{ marginTop: 20, marginBottom: 8 }}
        value='PREFERENZE DI VIAGGIO'
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: 5,
          marginBottom: 20
        }}
      >
        {iconList.map((icon) => (
          <SelectSingleSquare
            key={icon.id}
            icon={icon.id}
            text={icon.text}
            type={icon.id}
            style={styleSelect}
            value={client.tripType ? client.tripType[icon.id] : ''}
            onChange={(update) => updateCategory(update)}
          />
        ))}
      </div>
    </>
  )
}
