import React, { useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'

import { Flex, Button, Text, Slider, Spinner } from '@Common/Components'
import state from '../../../State'
import { useCQuery } from '../../../Services'
import { saveFunction } from '../../../Functions'
import { useHandleNavigate } from '@Common/Services'

export default function Actions () {
  const { data: user = {} } = useCQuery('user')
  const navigate = useHandleNavigate()

  const [isSaving, setIsSaving] = useState(false)

  const [tourData, setTourData] = state.tourData.useState()
  const [tourActions, setTourActions] = state.tourActions.useState()
  // Error trigger
  const setStartCheckErrorsGlobal = state.startCheckErrorsGlobal.useState()[1]
  const setStartCheckErrorsSyncAppGlobal = state.startCheckErrorsSyncAppGlobal.useState()[1]

  useEffect(() => {
    return () => {
      setStartCheckErrorsGlobal(false)
      setStartCheckErrorsSyncAppGlobal(false)
    }
  }, [setStartCheckErrorsGlobal, setStartCheckErrorsSyncAppGlobal])

  const onChangeFinanceData = (e) => {
    setTourActions({ ...tourActions, financeData: e.checked, saved: !tourActions.saved })
  }

  const onClickSave = async () => {
    setStartCheckErrorsGlobal(true)
    setIsSaving(true)
    const id = await saveFunction(user, tourData?.status === 1 ? 1 : 0)
    setIsSaving(false)
    id && navigate('/tours/' + id)
  }

  const onChangeSlider = async (value) => {
    setStartCheckErrorsGlobal(true)
    setStartCheckErrorsSyncAppGlobal(true)
    setIsSaving(true)
    setTourData({ ...tourData, status: value ? 1 : 0 })
    const id = await saveFunction(user, value ? 1 : 0)
    setIsSaving(false)
    id && navigate('/tours/' + id)
  }

  return (
    <Flex wrap fw ae>
      <Flex row>
        <Flex>
          <Slider style={{ justifyContent: 'none', marginTop: 5, marginRight: 20 }} value={tourData?.status === 1} onChange={onChangeSlider} />
        </Flex>
        {isSaving
          ? <Spinner />
          : <Button rich icon='check' label='Salva' style={{ width: 150, margin: 5, padding: 10, height: 30, cursor: 'pointer' }} onClick={onClickSave} />}
      </Flex>
      <Flex row style={{ marginTop: 10 }}>
        <Text value='Visualizza i dati finanziari della pratica (occhio ai regali!)' style={{ marginRight: 5 }} />
        <Checkbox checked={tourActions?.financeData} onChange={onChangeFinanceData} />
      </Flex>
    </Flex>
  )
}
