import { Card } from '@Common/Components'
import React from 'react'

import state from '../../../State'
import { Money } from './Elements'
import { deleteService, updateService } from '../../../Functions'

export default function MoneyForm () {
  const [moneyForm] = state.moneyForm.useState()

  if (!moneyForm?.length) return null
  return (
    <Card key='money' title='Contabilità' collapsable style={{ width: '100%', marginBottom: '20px' }}>
      {moneyForm.map((item, index) =>
        <Money key={index} item={item} onUpdate={updateService} onDelete={deleteService} />)}
    </Card>
  )
}
