
import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import { Home as HomePage } from './Pages/Home'

export const Home = (
  <>
    <Route path='*' element={<Navigate to='/' />} />
    <Route path='/' element={<HomePage />} />
  </>
)
