import React from 'react'
import { Input, Text } from '@Common/Components'

const GRID_NORMAL = `
  'labelBiglietto labelBiglietto ticketCode ticketCode ticketCode ticketNumber ticketNumber ticketNumber'
  'labelPartenza labelPartenza emissionDate1 emissionDate1 emissionDate1 emissionDate2 emissionDate2 emissionDate2'
  'labelReg labelReg registrationDate1 registrationDate1 registrationDate1 registrationDate2 registrationDate2 registrationDate2'
  'labelBolla labelBolla ticketBolla ticketBolla ticketBolla ticketBolla ticketBolla ticketBolla'
  `
function SearchBgt({ updateState, data }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateAreas: GRID_NORMAL,
        gridGap: '20px 9px',
        width: '100%'
      }}
    >
      <Text
        value='Biglietto:'
        style={{
          gridArea: 'labelBiglietto',
          marginRight: 10,
          marginTop: 4
        }}
      />
      <Input
        id='ticketCode'
        label='Tipo:'
        style={{ gridArea: 'ticketCode' }}
        onChange={(e) => updateState('bgt', e)}
        value={data.ticketCode || ''}
      />
      <Input
        keyfilter='int'
        id='ticketNumber'
        label='Numero:'
        style={{ gridArea: 'ticketNumber' }}
        onChange={(e) => updateState('bgt', e)}
        value={data.ticketNumber || ''}
      />

      {/* SECONDA PARTE */}
      <Text value='Data Partenza:' style={{ marginRight: 20, marginTop: 4, gridArea: 'labelPartenza' }} />
      <Input
        date
        id='emissionDate1'
        label='Da:'
        style={{ gridArea: 'emissionDate1' }}
        onChange={(e) => updateState('bgt', e)}
        value={data.emissionDate1 || ''}
      />
      <Input
        date
        id='emissionDate2'
        label='A:'
        style={{ gridArea: 'emissionDate2' }}
        onChange={(e) => updateState('bgt', e)}
        value={data.emissionDate2 || ''}
      />

      {/* TERZA PARTE */}
      <Text value='Registrazione:' style={{ marginRight: 10, marginTop: 4, gridArea: 'labelReg' }} />
      <Input
        date
        id='registrationDate1'
        label='Da:'
        style={{ gridArea: 'registrationDate1' }}
        onChange={(e) => updateState('bgt', e)}
        value={data.registrationDate1 || ''}
      />
      <Input
        date
        id='registrationDate2'
        label='A:'
        style={{ gridArea: 'registrationDate2' }}
        onChange={(e) => updateState('bgt', e)}
        value={data.registrationDate2 || ''}
      />
      {/* QUARTA PARTE */}
      <Text value='Ticket bolla:' style={{ marginRight: 10, marginTop: 4, gridArea: 'labelBolla' }} />
      <Input
        keyfilter='int'
        id='ticketBolla'
        label='N. bolla'
        style={{ gridArea: 'ticketBolla' }}
        onChange={(e) => updateState('bgt', e)}
        value={data.ticketBolla || ''}
      />
    </div>
  )
}

export { SearchBgt }
