import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import moment from 'moment'
import { FC, useHandleNavigate } from '@Common/Services'
import { Button, Text, Spinner, Modal } from '@Common/Components'
import SearchAtlList from './SearchAtlList'
import SearchDataRenderPra from './ViewSearchDataComponent/SearchDataRenderPra'
import SearchDataRenderBgt from './ViewSearchDataComponent/SearchDataRenderBgt'
import SearchDataRenderVou from './ViewSearchDataComponent/SearchDataRenderVou'

const ListAtl = ({ pra, bgt, vou, label }) => {
  const showSearch = useRef(true)
  const navigate = useHandleNavigate()
  const [praList, setPraList] = useState([])
  const [bgtList, setBgtList] = useState([])
  const [vouList, setVouList] = useState([])
  const [tourSelected, setToursSelected] = useState([])
  const [searchDataState, setSearchDataState] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)

  const callApiAtl = useCallback(async () => {
    setLoading(true)
    const [newDataPra = [], newDataBgt = [], newDataVou = []] = await Promise.all([
      await FC.service('atlante').get('tours'),
      await FC.service('atlante').get('tickets'),
      await FC.service('atlante').get('vouchers')
    ])

    const praList = newDataPra.map((tour) => ({
      ...tour,
      endDate: moment(tour.endDate, 'YYYYMMDD').format('DD/MM/YYYY'),
      startDate: moment(tour.startDate, 'YYYYMMDD').format('DD/MM/YYYY'),
      registrationDate: moment(tour.registrationDate, 'YYYYMMDD').format('DD/MM/YYYY')
    }))
    const bgtList = newDataBgt.map((bgtEl) => ({
      ...bgtEl,
      endDate: moment(bgtEl.endDate).format('DD/MM/YYYY'),
      startDate:
        bgtEl.startDate !== '0' ? moment(bgtEl.startDate).format('DD/MM/YYYY') : 'Data non disponibile',
      registrationDate: moment(bgtEl.registrationDate).format('DD/MM/YYYY')
    }))

    const vouList = newDataVou.map((vouEl) => ({
      ...vouEl,
      type: vouEl.type === 'H' ? 'Hotel' : vouEl.type === 'N' ? 'Noleggio' : 'Servizi',
      startDate: moment(vouEl.startDate).format('DD/MM/YYYY')
    }))

    setPraList(praList)
    setBgtList(bgtList)
    setVouList(vouList)
    setLoading(false)
    setLoadingTable(false)
  }, [])

  useEffect(() => {
    callApiAtl()
  }, [callApiAtl])

  const howToSearch = async (searchData) => {
    if (pra) {
      if (
        !searchData.type &&
        !searchData.year &&
        !searchData.number &&
        !searchData.startDate1 &&
        !searchData.startDate2 &&
        !searchData.registrationDate1 &&
        !searchData.registrationDate2
      ) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore ricerca',
          detail: 'Inserire almeno un dato di '
        })
        return
      }
      setSearchDataState(searchData)
      showSearch.current.hide()
      setLoadingTable(true)
      const res = await FC.service('atlante').get('tours', {
        query: { ...searchData }
      })
      if (!res) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore ricerca',
          detail: 'Ricerca non andata a buon fine'
        })
        setPraList([])
        setLoadingTable(false)
        return false
      }
      const praList = res.map((tour) => ({
        ...tour,
        endDate: moment(tour.endDate, 'YYYYMMDD').format('DD/MM/YYYY'),
        startDate: moment(tour.startDate, 'YYYYMMDD').format('DD/MM/YYYY'),
        registrationDate: moment(tour.registrationDate, 'YYYYMMDD').format('DD/MM/YYYY')
      }))
      setPraList(praList)
      setLoadingTable(false)
      return true
    }
    if (bgt) {
      if (
        !searchData.ticketCode &&
        !searchData.ticketNumber &&
        !searchData.ticketBolla &&
        !searchData.emissionDate1 &&
        !searchData.emissionDate2 &&
        !searchData.registrationDate1 &&
        !searchData.registrationDate2
      ) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore ricerca',
          detail: 'Inserire almeno un dato di ricerca'
        })
        return
      }

      setSearchDataState(searchData)
      showSearch.current.hide()
      setLoadingTable(true)
      const res = await FC.service('atlante').get('tickets', {
        query: { ...searchData }
      })
      if (res) {
        const bgtList = res.map((bgtEl) => ({
          ...bgtEl,
          endDate: moment(bgtEl.endDate).format('DD/MM/YYYY'),
          startDate:
            bgtEl.startDate !== '0' ? moment(bgtEl.startDate).format('DD/MM/YYYY') : 'Data non disponibile',
          registrationDate: moment(bgtEl.registrationDate).format('DD/MM/YYYY')
        }))
        setBgtList(bgtList)
      }
      setLoadingTable(false)
    }

    if (vou) {
      if (
        !searchData.type &&
        !searchData.year &&
        !searchData.number &&
        !searchData.dataIn1 &&
        !searchData.dataIn2 &&
        !searchData.regDate1 &&
        !searchData.regDate2
      ) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore ricerca',
          detail: 'Inserire almeno un dato di ricerca'
        })
        return
      }
      showSearch.current.hide()
      setLoadingTable(true)
      const res = await FC.service('atlante').get('vouchers', {
        query: { ...searchData }
      })

      if (res) {
        const vouList = res.map((vouEl) => ({
          ...vouEl,
          startDate: moment(vouEl.startDate).format('DD/MM/YYYY')
        }))
        setVouList(vouList)
      }
      setLoadingTable(false)
    }
  }
  const praSearch = () => <SearchDataRenderPra data={searchDataState} />
  const bgtSearch = () => <SearchDataRenderBgt data={searchDataState} />
  const vouSearch = () => <SearchDataRenderVou data={searchDataState} />

  const renderSearchData = () => {
    if (pra) return praSearch()
    if (bgt) return bgtSearch()
    if (vou) return vouSearch()
  }

  const redirectToSync = (data) => {
    navigate(`/atlTour/atlante?id=${data.idAtlante}&type=${pra ? 'pra' : bgt ? 'bgt' : 'vou'}`)
  }
  // RENDER
  return loading
    ? <Spinner />
    : (
      <>
        <Modal
          ref={showSearch}
          header={`Ricerca ${label}`}
          content={<SearchAtlList pra={pra} bgt={bgt} vou={vou} howToSearch={howToSearch} />}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {searchDataState
            ? (
              <div style={{ width: '100%', marginTop: 10 }}>
                <Text bold value='Dati della ricerca: ' />
                {renderSearchData()}
              </div>)
            : null}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              justifyContent: 'flex-end',
              marginTop: 10,
              marginBottom: 10,
              paddingRight: 2
            }}
          >
            <Button
              rich
              icon='lens'
              label={`Ricerca ${label}`}
              style={{ marginRight: 16 }}
              onClick={() => showSearch.current.show()}
            />
            <Button
              rich
              icon='bin'
              label='Cancella ricerca'
              style={{ marginRight: 16 }}
              onClick={() => {
                setSearchDataState(null)
                callApiAtl()
              }}
            />
          </div>
        </div>

        {loadingTable
          ? <Spinner />
          : (
            <DataTable
              value={pra ? praList : bgt ? bgtList : vouList}
              paginator
              rows={10}
              responsiveLayout='scroll'
              emptyMessage='Nessuna pratica da visualizzare'
              className='TableList'
              selectionMode='single'
              selection={tourSelected}
              filterDisplay='row'
              onSelectionChange={(e) => {
                setToursSelected(e.value)
                // redirect
                redirectToSync(e.value)
              }}
            >
              {!vou && (
                <Column
                  field='registrationDate'
                  header='Data registrazione'
                  filterPlaceholder='🔍   Data reg'
                  sortable
                  filter
                  showClearButton={false}
                  showFilterMenu={false}
                  filterMatchMode='contains'
                  style={{ textAlign: 'center', border: 'none' }}
                />
              )}
              {pra &&
                (
                  <Column
                    field='description'
                    header='Descrizione'
                    filterPlaceholder='🔍   Descrizione'
                    sortable
                    filter
                    showClearButton={false}
                    showFilterMenu={false}
                    filterMatchMode='contains'
                    style={{ textAlign: 'center', border: 'none' }}
                  />
                )}
              {vou && (
                <Column
                  field='customer'
                  header='Cliente'
                  filterPlaceholder='🔍   Cliente'
                  sortable
                  filter
                  showClearButton={false}
                  showFilterMenu={false}
                  filterMatchMode='contains'
                  style={{ textAlign: 'center', border: 'none' }}
                />
              )}
              <Column
                field='idAtlante'
                header='Codice Atlante'
                filterPlaceholder='🔍   Codice Atlante'
                sortable
                filter
                showClearButton={false}
                showFilterMenu={false}
                filterMatchMode='contains'
                style={{ textAlign: 'center', border: 'none' }}
              />
              {vou && (
                <Column
                  field='businessName'
                  header='Fornitore'
                  filterPlaceholder='🔍   Fornitore'
                  sortable
                  filter
                  showClearButton={false}
                  showFilterMenu={false}
                  filterMatchMode='contains'
                  style={{ textAlign: 'center', border: 'none' }}
                />
              )}
              {vou &&
                (
                  <Column
                    field='type'
                    header='Tipo Voucher'
                    filterPlaceholder='🔍   Tipo Voucher'
                    sortable
                    filter
                    showClearButton={false}
                    showFilterMenu={false}
                    filterMatchMode='contains'
                    style={{ textAlign: 'center', border: 'none' }}
                  />
                )}
              <Column
                field='startDate'
                header='Inizio viaggio'
                filterPlaceholder='🔍   inizio viaggio'
                sortable
                filter
                showClearButton={false}
                showFilterMenu={false}
                filterMatchMode='contains'
                style={{ textAlign: 'center', border: 'none' }}
              />
              {!vou && (
                <Column
                  field='endDate'
                  header='Fine viaggio'
                  filterPlaceholder='🔍   fine viaggio'
                  sortable
                  filter
                  showClearButton={false}
                  showFilterMenu={false}
                  filterMatchMode='contains'
                  style={{ textAlign: 'center', border: 'none' }}
                />
              )}
              {bgt &&
                (
                  <Column
                    field='itinerary'
                    header='Itinerario'
                    filterPlaceholder='🔍   Itinerario'
                    sortable
                    filter
                    showClearButton={false}
                    showFilterMenu={false}
                    filterMatchMode='contains'
                    style={{ textAlign: 'center', border: 'none' }}
                  />
                )}
              {pra && (
                <Column
                  field='passengers.length'
                  header='Passeggeri'
                  filterPlaceholder='🔍   Passeggeri'
                  sortable
                  filter
                  showClearButton={false}
                  showFilterMenu={false}
                  filterMatchMode='contains'
                  style={{ textAlign: 'center', border: 'none' }}
                />
              )}
              <Column
                field='statusAtl'
                header={`Stato ${label.charAt(0).toUpperCase() + label.slice(1)} in Atlante`}
                filterPlaceholder='🔍   stato'
                sortable
                filter
                showClearButton={false}
                showFilterMenu={false}
                filterMatchMode='contains'
                style={{ textAlign: 'center', border: 'none' }}
              />
            </DataTable>)}
      </>)
}

export default ListAtl
