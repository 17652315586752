import React, { useState } from 'react'
import { Spinner, Input, Text, Flex, Card } from '@Common/Components'
import { useCQuery, invalidateQuery, useCInfiniteQuery } from '../Services'
import state from '../State'
import { FC, useHandleNavigate, Themes } from '@Common/Services'
import { UserInList } from '../Components/UserInList'

const NewMessage = () => {
  const { data: users, isSuccess: isSuccessUsers } = useCQuery('allClients')
  const { data: cconversations = [], isSuccess: isSuccessConversations } = useCInfiniteQuery('conversations')
  const [searchBar, setSearchBar] = useState('')
  const navigate = useHandleNavigate()

  const startNewConversation = async (user) => {
    const agency = state.auth.state?.userAgency
    const conversation = {
      agency: agency._id,
      user: user._id,
      nextActor: 'user',
      lastUpdate: new Date()
    }
    const conversationExist = cconversations.find(convUser => convUser.user === user._id)
    let conversationResponse
    let conversationID = ''
    if (!conversationExist) {
      conversationResponse = await FC.service('conversations').create(conversation)
      if (!conversationResponse) return false
      conversationID = conversationResponse._id
    } else {
      conversationID = conversationExist._id
    }
    invalidateQuery(['conversations', 'messages'])
    navigate('/messages/' + conversationID)
  }

  if (!isSuccessUsers || !isSuccessConversations) return <Spinner />

  const listElements = users.filter((ele) =>
    searchBar === '' || (ele.name && ele.name.toLocaleLowerCase().search(searchBar.toLocaleLowerCase()) > -1) ||
    (ele.lastName && ele.lastName.toLocaleLowerCase().search(searchBar.toLocaleLowerCase()) > -1) ||
    (ele._id && ele._id.toLocaleLowerCase().search(searchBar.toLocaleLowerCase()) > -1)
  ).sort((a, b) => a.lastName > b.lastName ? 1 : -1)

  return (
    <>
      <Flex js style={styles.sidebar}>
        <Input
          id='searchBar' icon='lens' placeholder='Cerca' style={{ height: 'auto' }}
          value={searchBar} onChange={({ searchBar }) => { setSearchBar(searchBar) }}
        />
        <Text title bold upCase style={{ marginTop: 20, marginBottom: 10 }} value='Utenti' />
        <div style={{ overflow: 'auto', overflowX: 'hidden', scrollbarWidth: 'none', width: '100%' }}>
          {listElements.map((user, i) => <UserInList key={i} user={user} onClick={() => startNewConversation(user)} />)}
        </div>
      </Flex>
      <Card style={styles.contentCard}>
        <Text value='Seleziona un utente per inziarne una conversazione.' />
      </Card>
    </>
  )
}

export default NewMessage

const styles = {
  sidebar: {
    padding: 10,
    position: 'fixed',
    top: 50,
    left: 0,
    bottom: 0,
    backgroundColor: Themes.colors.translucent,
    width: 320,
    minWidth: 320,
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbars: 'none'
  },
  contentCard: {
    width: '100%',
    marginLeft: 320,
    paddingTop: 20
  }
}
