import { getMergedState } from '@Common/State'

const sessionState = {

}
const storedState = {
}

const state = getMergedState(sessionState, storedState)

export default state
