import _ from 'lodash'
import moment from 'moment'
export * from './QueryCache'

export const getDayId = (date) => moment(date).diff(moment('2020-06-01'), 'days')
export const getTodayId = () => getDayId(moment())
export const getDateFromDayID = (dateId) => moment('2020-06-01').add(dateId, 'd')

const mappingState = {
  cancelled: 'Cancellato',
  booked: 'Prenotato'
}

export const filterAppointments = (appointments, agency, type) => {
  const todayId = getTodayId()
  let filtered = []
  switch (type) {
    case 'd':
      filtered = appointments.filter((item) => item.dayId === todayId)
      break
    case 'w':
      filtered = appointments.filter((item) => item.dayId > todayId && item.dayId <= todayId + 7)
      break
    default:
      filtered = appointments.filter((item) => item.dayId > todayId)
      break
  }

  let filteredBooked = filtered.filter((item) => item.state === 'booked')
  let filteredCancelled = filtered.filter((item) => item.state === 'cancelled')
  filteredBooked = _.sortBy(filteredBooked, 'from')
  filteredCancelled = _.sortBy(filteredCancelled, 'from')
  filtered = [...filteredBooked, ...filteredCancelled]

  return filtered.map((item) => {
    const dayString = getDateFromDayID(item.dayId).format('DD/MM/YYYY')
    const noCalendar = { name: 'Calendario non trovato', service: '' }
    const noOperator = 'Sconosciuto'
    const calendar =
      agency.appointments && agency.appointments.calendars
        ? agency.appointments.calendars.find((cal) => cal.id === item.calId) || noCalendar
        : noCalendar
    console.log(calendar)
    const operator = calendar.operator || noOperator
    return {
      ...item,
      dayString,
      client: `${item.user.name} ${item.user.surname}`,
      infoCalendar: `${calendar.name} ${calendar.service}`,
      stateString: mappingState[item.state],
      operator
    }
  })
}
