import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const TableDocuments = ({ documents, editable, emptyMessage, editorFunction, renderActions, renderStatus }) => {
  return (
    <DataTable
      value={documents}
      className='TableDocuments'
      responsiveLayout='scroll'
      resizableColumns
      editable={editable}
      emptyMessage={emptyMessage}
      paginator
      rows={10}
    >
      <Column field='file' header='Nome' headerStyle={{ textAlign: 'left' }} />
      <Column field='title' editor={editorFunction} header='Titolo' style={{ textAlign: 'center' }} />
      <Column field='date' header='Data' style={{ textAlign: 'center' }} />

      <Column header='Stato documento' body={renderStatus} />
      <Column style={{ width: 120 }} header='Azioni' body={renderActions} />
    </DataTable>
  )
}

export default TableDocuments
