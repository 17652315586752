import React from 'react'

import { Card, TextareaAuto } from '@Common/Components'
import state from '../../State'

export default function Note () {
  const [tourData, setTourData] = state.tourData.useState()
  const [tourActions, setTourActions] = state.tourActions.useState()

  const onChangeNote = (e) => {
    setTourData({ ...tourData, note: e.target.value })
    setTourActions({ ...tourActions, saved: false })
  }

  return (
    <Card key='note' title='Note sul viaggio' style={{ marginBottom: '20px', width: '100%' }}>
      <TextareaAuto
        placeholder='Note visibili anche al cliente'
        value={tourData.note}
        style={{ marginTop: 20 }}
        onChange={onChangeNote}
      />
    </Card>
  )
}
