import React from 'react'

import { Card, UploadFile } from '@Common/Components'
import state from '../../State'

export default function Documents () {
  const [toursDocuments, setToursDocuments] = state.toursDocuments.useState()
  const [tourActions, setTourActions] = state.tourActions.useState()

  const updateFiles = (updatedFiles) => {
    setToursDocuments(updatedFiles)
    setTourActions({ ...tourActions, saved: false })
  }

  // Faccio un set dei tourDocuments. Quando vado a fare il salvataggio mi salvo anche l'ordine dei file: equivale all'index dell'array. Idelamente meetere un campo documentsOrder: [idDoc, idDoc, idDoc].
  const onReorder = (reorderedFiles) => {
    setToursDocuments(reorderedFiles)
  }

  return (
    <Card title='Documenti allegati' style={{ width: '100%', marginBottom: 20 }}>
      <UploadFile
        files={toursDocuments || []}
        updateFiles={updateFiles}
        accept={'application/pdf, text/*, image/png,image/jpeg,image/jpg,image/bmp'}
        title='Scegli i documenti da caricare'
        titleTypeOfFile='formati supportati: .pdf, .html, .png, .jpeg, .jpg, .bmp'
        onReorder={onReorder}
      />
    </Card>
  )
}
