import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Card, Button, Flex, Spinner } from '@Common/Components'
import { useHandleNavigate } from '@Common/Services'
import { useCQuery, invalidateQuery } from '../Services'
import state from '../State'
import { clearState, initOfferState, saveOffer } from '../Functions'
import { Categories, MainData, Documents, Details, Activities, Header } from '../Components/Offer'
import { useUnmount } from 'react-use'

// Tour sembra ok
// Offer invce da verificare errori e navigazione in caso di errore.

const Offer = () => {
  const offerId = useParams()?.offerId || ''

  const navigate = useHandleNavigate()

  const { data: user = {} } = useCQuery('user')
  const { data: offer = {}, isSuccess: isSuccessOffer } = useCQuery(['offer', offerId])

  const [offerActions, setOfferActions] = state.offerActions.useState()
  const setStartCheckErrorsGlobal = state.startCheckErrorsGlobal.useState()[1]
  const setStartCheckErrorsSyncAppGlobal = state.startCheckErrorsSyncAppGlobal.useState()[1]

  useEffect(() => {
    if (!offerId) initOfferState({})
    if (isSuccessOffer) initOfferState(offer)
  }, [isSuccessOffer, offerId])

  useUnmount(() => {
    setStartCheckErrorsGlobal(false)
    setStartCheckErrorsSyncAppGlobal(false)
    setOfferActions({ ...offerActions, saved: true })
    clearState()
    invalidateQuery('offers')
  })

  const onClickSave = async () => {
    setStartCheckErrorsGlobal(true)
    const id = await saveOffer(user, 0)
    if (id) {
      setOfferActions({ ...offerActions, saved: true })
      navigate('/offers/' + id)
    }
  }

  const onClickPublish = async () => {
    setStartCheckErrorsGlobal(true)
    setStartCheckErrorsSyncAppGlobal(true)
    const id = await saveOffer(user, 1)
    if (id) {
      setOfferActions({ ...offerActions, saved: true, status: 1 })
      navigate('/offers/' + id)
    }
  }

  if (!isSuccessOffer) return <Spinner />

  return (
    <>
      <Header />
      <Card fadeIn>
        <MainData />
        <Categories />
        <Documents />
        <Details />
        <Flex fw row style={{ marginBottom: 10, marginTop: 10 }}>
          <Button icon='edit-2' label='Salva in bozza' onClick={onClickSave} style={{ marginRight: 5 }} />
          <Button icon='go' label='Pubblica' onClick={onClickPublish} style={{ marginLeft: 5 }} />
        </Flex>
        <Activities />
      </Card>
    </>
  )
}

export default Offer
