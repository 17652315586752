import { FC, useCQuery as CommonUseCQuery, useCInfiniteQuery as CommonUseCInfiniteQuery, prefetchQueries as CommonPrefetchQueries, prefetchQuery as CommonPrefetchQuery, invalidateQuery as CommonInvalidateQuery } from '@Common/Services'
import state from '../State'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
}

const queries = {
  groupChatConversations: {
    config: queryConfig.rare,
    queryFn: () => FC.service('conversations').find({ query: { groupChat: { $exists: true } } }),
    noInitalPrefetch: true
  },
  mergedConversations: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, tourId] }) => FC.client.service('info').get('getMergedConversations', { query: { _id: tourId } }),
    noInitalPrefetch: true
  },
  messages: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, conversationId] }) => conversationId ? FC.service('messages').find({ query: { conversationId } }) : [],
    noInitalPrefetch: true
  },
  extraUsers: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, listUsr] }) => listUsr ? FC.client.service('users').find({ query: { _id: { $in: listUsr } } }) : '...',
    noInitalPrefetch: true
  },
  broadcastMessages: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, agencyId] }) => state.auth.state.userAgency ? FC.service('broadcastMessages').find({ query: { agency: agencyId || state.auth.state.userAgency } }) : {}
  }
}

// Infinite queries

const infiniteQueries = {
  conversations: {
    config: {
      ...queryConfig.autoFetchFrequent,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1 }) =>
      // Definisco la dimensione della prima pagina e delle successive, in modo da avere una risposta velocissima per la prima pagina
      FC.client.service('conversations').find({
        query:
        {
          $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
          $limit: pageParam === 1 ? firstPageLength : pageLength,
          $sort: { lastUpdate: -1 }
        }
      }),
    noInitalPrefetch: true
  }
}

export const useCQuery = (queryKey) => CommonUseCQuery(queryKey, queries)

export const useCInfiniteQuery = (queryKey) => CommonUseCInfiniteQuery(queryKey, infiniteQueries)

export const prefetchQuery = (queryKey) => CommonPrefetchQuery(queryKey, queries)

export const prefetchQueries = (queries) => CommonPrefetchQueries(queries)

export const invalidateQuery = (queryKey) => CommonInvalidateQuery(queryKey)
