import moment from 'moment'
import _ from 'lodash'

import {
  getHotel,
  getFlight,
  getTrain,
  getTransfer,
  getCruise,
  getService,
  getRental,
  getInsurance
} from './AppTours'
import {
  getDayObj,
  hasFlights,
  getHotelInfoDP,
  getNotVipServices,
  getDateForDP,
  filterRoutes,
  getTicketFlyObj,
  getTicketRouteObj,
  getFlightObj,
  getTicketGenericObj,
  getHotelObj,
  getTransferObj,
  getTrainObj,
  getNolObject,
  getVoucherObj,
  getGenericProdObj
} from './Tours'

function TimelineVip (tour) {
  let events = []
  const tourPrefix = tour._id.split('@')[1].split('-')[0]
  if (tour.type === 'APP' || tour.type === 'DP' || tour.type === 'TC') {
    tour.hotels.forEach((hotel) =>
      events.push({
        ...getHotel(hotel),
        date: getDayObj(moment(hotel.startDate))
      })
    )
    tour.flights.forEach((flight) =>
      events.push({
        ...getFlight(flight),
        date: getDayObj(moment(flight.startDate))
      })
    )
    tour.trains &&
      tour.trains.forEach((train) =>
        events.push({
          ...getTrain(train),
          date: getDayObj(moment(train.startDate))
        })
      )
    if (tour.type === 'APP' || tour.type === 'TC') {
      tour.transfers.forEach((transfer) => events.push(...getTransfer(transfer)))
    }
    if (tour.type === 'DP' || tour.type === 'TC') tour.rental.forEach((rent) => events.push(...getRental(rent)))
    tour.type === 'APP' && tour.cruises.forEach((cruise) => events.push(...getCruise(cruise)))
    if (tour.type === 'APP') {
      tour.services.forEach((service) =>
        events.push({
          ...getService(service),
          date: getDayObj(moment(service.startDate))
        })
      )
    }
    if (tour.type === 'DP') {
      if (tourPrefix === '74W') {
        events.push(getHotelInfoDP(tour))
        events.push(getNotVipServices(tour.services, getDateForDP(tour), tour))
      }
      if (tourPrefix === '74K') {
        tour.services.forEach((service) => {
          if (service.startDate) {
            events.push({
              ...getService(service),
              date: getDayObj(moment(service.startDate))
            })
          } else {
            events.push({ ...getService(service), date: getDateForDP(tour) })
          }
        })
      }
    }
    if (tour.type === 'TC') {
      tour.insurances &&
        tour.insurances.forEach((insurance) =>
          events.push({
            ...getInsurance(insurance),
            date: getDayObj(moment(insurance.startDate))
          })
        )
    }
  } else {
    // Handling Flights
    if (hasFlights(tour)) {
      const filteredRoutes = filterRoutes(tour)
      for (let i = 0; i < filteredRoutes.length; i++) {
        if (tour.type === 'BGT-A') {
          events.push(getTicketFlyObj(filteredRoutes[i]))
        } else if (tour.type === 'BGT-V') {
          events.push(getTicketRouteObj(filteredRoutes[i]))
        } else {
          events.push(getFlightObj(filteredRoutes[i], tour.type))
        } // al posto di true tour.notVIP
      }
    } else if (tour.type === 'BGT-A') {
      events.push(getTicketGenericObj(tour))
    } else if (tour.type === 'BGT-V') {
      events.push(getTicketGenericObj(tour))
    }

    // Handling Products
    if (tour && tour.products) {
      for (let i = 0; i < tour.products.length; i++) {
        switch (tour.products[i].serviceType) {
          case 'HOT':
            events.push(getHotelObj(tour.products[i]))
            break
          case 'TRS':
            events.push(...getTransferObj(tour.products[i]))
            break
          case 'TRE':
            events.push(...getTrainObj(tour.products[i]))
            break
          case 'NOL': // Noleggio
            events.push(getNolObject(tour.products[i]))
            break
          default:
            if (tour.type === 'VOU') {
              events.push(...getVoucherObj(tour.products[i]))
            } else {
              events.push(...getGenericProdObj(tour.products[i]))
            }
        }
      }
    }
  }

  events = _.sortBy(events, 'date.timestamp')
  let timeline = []
  let currentDay = 0
  for (let i = 0; i < events.length; i++) {
    if (currentDay !== events[i].date.date) {
      timeline.push({
        date: events[i].date,
        events: [],
        singleTicket: tour.type.includes('BGT')
      })
      currentDay = events[i].date.date
    }
    timeline[timeline.length - 1].events.push(events[i])
  }

  if (timeline.length === 0) {
    if (tour.type === 'APP') {
      timeline.push({
        date: getDayObj(moment(tour.startDate)),
        events: [
          {
            date: getDayObj(moment(tour.startDate)),
            title: 'Questa pratica contiene solo documenti',
            actions: [
              {
                type: 'document',
                payload: 'Documents'
              }
            ]
          }
        ]
      })
      return timeline
    }
    // Caso di una pratica non VIP
    if (tour?.extraServices) {
      tour?.extraServices?.hotels?.forEach((hotel) =>
        events.push({
          ...getHotel(hotel),
          date: getDayObj(moment(hotel.startDate))
        })
      )
      tour?.extraServices?.flights?.forEach((flight) =>
        events.push({
          ...getFlight(flight),
          date: getDayObj(moment(flight.startDate))
        })
      )
      tour?.extraServices?.trains?.forEach((train) =>
        events.push({
          ...getTrain(train),
          date: getDayObj(moment(train.startDate))
        })
      )
      tour?.extraServices?.cars?.forEach((car) => {
        if (car.checkTypeCar === 'NOL') {
          events.push(
            ...getRental(car)
          )
        } else {
          events.push(
            ...getTransfer(car)
          )
        }
      }
      )
      tour?.extraServices?.services?.forEach((service) =>
        events.push({
          ...getService(service),
          date: getDayObj(moment(service.startDate))
        })
      )
      tour?.extraServices?.cruises?.forEach((cruise) =>
        events.push(
          ...getCruise(cruise)
        )
      )
    }
    events.push(getNotVipServices(tour.services, getDayObj(tour.startDate)))
    events = _.sortBy(events, 'date.timestamp')
    let currentDay = 0
    for (let i = 0; i < events.length; i++) {
      if (currentDay !== events[i].date.date) {
        timeline.push({
          date: events[i].date,
          events: [],
          singleTicket: tour.type.includes('BGT')
        })
        currentDay = events[i].date.date
      }
      timeline[timeline.length - 1].events.push(events[i])
    }
  }
  if (tour.type === 'DP') {
    timeline = timeline.map((day) => ({
      ...day,
      events: day.events.sort((a, b) => b.order < a.order)
    }))
  }
  return timeline
}

export default TimelineVip
