import React from 'react'
import AtlanteTours from './Pages/AtlanteTours'
import { Navigate, Route } from 'react-router-dom'
import TabsAtl from './Pages/TabsAtl'

export const AtlTour = (
  <>
    <Route path='/atlTour/*' element={<Navigate to='/atlTour/list' />} />
    <Route path='/atlTour/list' element={<TabsAtl />} />
    <Route path='/atlTour/:id' element={<AtlanteTours />} />
  </>
)
