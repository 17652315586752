import state from '../State'

export const initOfferState = (offer) => {
  const mainData = {
    title: offer?.title || '',
    publish: offer?.publish || '',
    deadline: offer?.deadline || '',
    price: offer?.price || '',
    ...(offer?._id ? { _id: offer?._id } : {})
  }

  const details = {
    description: offer?.description || '',
    serviceIncluded: offer?.serviceIncluded || '',
    serviceExcluded: offer?.serviceExcluded || '',
    note: offer?.note || ''
  }

  const actions = {
    saved: true,
    copied: false,
    status: offer?.status
  }

  state.offerMainData.setState(mainData)
  state.offerDetails.setState(details)
  state.offerCategories.setState(offer?.category || {})
  state.offerDocuments.setState(offer?.photos || [])
  state.offerActions.setState(actions)
  state.offerLogs.setState(offer?.logs || [])
}

export const clearState = () => {
  if (state?.offerMainData?.state?._id) state.offerActions.setState({ ...state.offerActions.state, saved: false })
  else state.offerActions.setState({ saved: true, copied: false, status: 0 })

  state.offerMainData.setState({})
  state.offerDetails.setState({})
  state.offerCategories.setState({})
  state.offerDocuments.setState([])
  state.offerLogs.setState([])
}

export const showGrowl = (severity, summary, detail, sticky = false, life = 3000) => {
  return (
    window.growl.show({
      severity,
      summary,
      detail,
      sticky,
      life
    })
  )
}
