import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import { ToursList, Tour } from './Pages'

export const Tours = (
  <>
    <Route path='/tours/*' element={<Navigate to='/tours/list' />} />
    <Route path='/tours/list' element={<ToursList />} />
    <Route path='/tours/new' element={<Tour />} />
    <Route path='/tours/:tourId' element={<Tour />} />
  </>
)
