import React, { useState } from 'react'
import { Tabs, TabHeader } from '@Common/Components'
import ListMessage from '../Components/ListMessage'

const tabs = [{
  id: 0, icon: 'chat', title: 'CONVERSAZIONI', disabled: false, content: <ListMessage />
}]

const AllMessages = () => {
  const [currentTab, setCurrentTab] = useState(0)
  return (
    <div style={styles.containerStyle}>
      <Tabs
        onTabSelection={setCurrentTab}
        currentTab={currentTab}
      >
        {tabs?.filter(({ disabled }) => !disabled).map((tab, index) => (
          <TabHeader
            title={tab?.title}
            key={index}
            index={index}
            icon={tab?.icon}
          >
            {tab?.content}
          </TabHeader>
        ))}
      </Tabs>
    </div>
  )
}

export default AllMessages

const styles = {
  containerStyle: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none'
  }
}
