import { getMergedState } from '@Common/State'

const sessionState = {
  offerSelected: [],
  startCheckErrorsGlobal: false,
  startCheckErrorsSyncAppGlobal: false,
  offerMainData: {}, // title, publish, deadline, price, _id
  offerDetails: {}, // description, serviceIncluded, serviceExcluded, note
  offerCategories: {},
  offerDocuments: [],
  offerActions: {}, // saved, copied, status
  offerLogs: []

}
const storedState = {

}

const state = getMergedState(sessionState, storedState)

export default state
