import React from 'react'
import moment from 'moment'
import { Text } from '@Common/Components'

function SearchDataRenderBgt ({ data }) {
  return (
    <>
      {data.ticketCode
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Tipo Biglietto:' style={{ marginRight: 5 }} />
            <Text bold value={data.ticketCode} />
          </div>)
        : null}

      {data.ticketNumber
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Numero: ' style={{ marginRight: 5 }} />
            <Text bold value={data.ticketNumber} />
          </div>)
        : null}

      {data.ticketBolla
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Bolla: ' style={{ marginRight: 5 }} />
            <Text bold value={data.ticketBolla} />
          </div>)
        : null}

      {data.emissionDate1
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Partenza da: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.emissionDate1, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
      {data.emissionDate2
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Partenza a: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.emissionDate2, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
      {data.registrationDate1
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Registrazione da: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.registrationDate1, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
      {data.registrationDate2
        ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text value='Data Registrazione a: ' style={{ marginRight: 5 }} />
            <Text bold value={moment(data.registrationDate2, 'YYYYMMDD').format('DD/MM/YYYY')} />
          </div>)
        : null}
    </>
  )
}

export default SearchDataRenderBgt
