import React from 'react'
import moment from 'moment'

import { Flex, Input, Text } from '@Common/Components'
import state from '../../State'

const MainData = () => {
  const [offerMainData, setOfferMainData] = state.offerMainData.useState()
  const [offerActions, setOfferActions] = state.offerActions.useState()

  const [startCheckErrorsGlobal, setStartCheckErrorsGlobal] = state.startCheckErrorsGlobal.useState()

  const updateData = (update) => {
    setStartCheckErrorsGlobal(false)
    setOfferActions({ ...offerActions, saved: false })
    setOfferMainData({ ...offerMainData, ...update })
  }

  return (
    <Flex fw as js>
      <Text bold title upCase value='Dati principali' />
      <Flex fw as js style={{ marginTop: 10 }}>
        <Input
          error={startCheckErrorsGlobal ? !offerMainData.title : false}
          id='title'
          label='Titolo Offerta:'
          value={offerMainData?.title || ''}
          onChange={updateData}
        />
      </Flex>
      <Flex fw row style={{ marginTop: 10 }}>
        <Input
          error={startCheckErrorsGlobal ? !offerMainData.publish : false}
          date
          id='publish'
          label='Pubblicazione:'
          style={{ marginRight: 5 }}
          value={offerMainData.publish ? moment(offerMainData.publish).toDate() : ''}
          onChange={updateData}
        />
        <Input
          error={startCheckErrorsGlobal ? !offerMainData.deadline : false}
          date
          id='deadline'
          label='Scadenza:'
          style={{ marginRight: 5 }}
          value={offerMainData.deadline ? moment(offerMainData.deadline).toDate() : ''}
          onChange={updateData}
        />
        <Input
          error={startCheckErrorsGlobal ? !offerMainData.price : false}
          keyfilter='money'
          id='price'
          label='Prezzo:'
          value={offerMainData.price || ''}
          onChange={updateData}
        />
      </Flex>
    </Flex>
  )
}

export default MainData
