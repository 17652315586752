import React from 'react'
import moment from 'moment'
import { Text } from '@Common/Components'

const SIZE_TEXT = 15
function BgtDisplay ({ data }) {
  const renderDate = (date) => (
    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
      <Text size={50} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('DD')} />
      <div style={{ marginTop: 14, marginLeft: 5 }}>
        <Text size={17} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('MMMM')} />
        <Text size={17} value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('YYYY')} />
      </div>
    </div>
  )
  const renderType = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={15} value='Tipo biglietto:' style={{ marginRight: 20 }} />
      <div style={{ ...Styles.divDescription }}>
        <Text size={15} bold value={data.ticketType} style={{ marginRight: 20, width: '100%' }} />
      </div>
    </div>
  )
  const renderStartDate = () =>
    data.startTrip
      ? (
        <div
          style={{
            ...Styles.divContainer
          }}
        >
          <Text size={SIZE_TEXT} value='Inizio viaggio:' style={{ marginRight: 20 }} />

          <div style={{ ...Styles.divDescription }}>{renderDate(data.startTrip)}</div>
        </div>)
      : null
  const renderItinerary = () =>
    data.itinerary
      ? (
        <div
          style={{
            ...Styles.divContainer
          }}
        >
          <Text size={SIZE_TEXT} value='Itinerario:' style={{ marginRight: 20 }} />
          <div style={{ ...Styles.divDescription }}>
            <Text size={15} bold value={data.itinerary} style={{ marginRight: 20, width: '100%' }} />
          </div>
        </div>)
      : null
  const renderDataReg = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={SIZE_TEXT} value='Data registrazione:' style={{ marginRight: 20 }} />
      <div style={{ ...Styles.divDescription }}>{renderDate(data.registrationDate)}</div>
    </div>
  )
  const renderIATA = () =>
    data.iata
      ? (
        <div
          style={{
            ...Styles.divContainer
          }}
        >
          <Text size={SIZE_TEXT} value='IATA:' style={{ marginRight: 20 }} />

          <div style={{ ...Styles.divDescription }}>
            <Text size={24} bold value={data.iata} style={{ marginRight: 20, width: '100%' }} />
          </div>
        </div>)
      : null
  const renderPNR = () =>
    data.pnr
      ? (
        <div
          style={{
            ...Styles.divContainer
          }}
        >
          <Text size={SIZE_TEXT} value='PNR:' style={{ marginRight: 20 }} />

          <div style={{ ...Styles.divDescription }}>
            <Text size={24} bold value={data.pnr} style={{ marginRight: 20, width: '100%' }} />
          </div>
        </div>)
      : null
  const renderToPay = () => (
    <div
      style={{
        ...Styles.divContainer
      }}
    >
      <Text size={SIZE_TEXT} value='Costo totale:' style={{ marginRight: 20 }} />

      <div style={{ ...Styles.divDescription }}>
        <Text size={24} bold value={`${data.totalCustomer} €`} style={{ marginRight: 20, width: '100%' }} />
      </div>
    </div>
  )

  const renderDivision = () => (
    <div
      style={{
        width: 2,
        height: '100%',
        backgroundColor: 'white',
        border: '1px solid #32324e',
        maxHeight: 97
      }}
    />
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
      {renderType()}
      {renderDivision()}
      {renderStartDate()}
      {data.startTrip ? renderDivision() : null}
      {renderItinerary()}
      {data.itinerary ? renderDivision() : null}
      {renderDataReg()}
      {renderDivision()}
      {renderIATA()}
      {data.iata ? renderDivision() : null}
      {renderPNR()}
      {data.pnr ? renderDivision() : null}
      {renderToPay()}
    </div>
  )
}
const Styles = {
  divContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    align: 'center',
    marginLeft: 20
  },
  divDescription: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export default BgtDisplay
