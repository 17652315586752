import React from 'react'
import state from '../../../State'
import { Card } from '@Common/Components'
import { Car } from './Elements'
import PlusButton from '../PlusButton'
import { updateService, deleteService } from '../../../Functions'

export default function CarForm () {
  const [carForm] = state.carForm.useState()
  if (!carForm?.length) return null
  return (
    <Card
      key='car'
      title='AUTO'
      collapsable={carForm.length > 0}
      style={{ marginBottom: '20px', width: '100%' }}
    >
      <PlusButton type='carForm' />
      {carForm.map((item, index) => (
        <Car
          key={index}
          index={index}
          item={item}
          onUpdate={updateService}
          onDelete={deleteService}
        />
      ))}
    </Card>
  )
}
