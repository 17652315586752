import React, { useState } from 'react'
import moment from 'moment'
import { Card, Button } from '@Common/Components'
import { SearchBgt, SearchPra, SearchVou } from './ModalSearchComponent'
import { useCQuery } from '../Services'

function SearchAtlList ({ pra, bgt, vou, howToSearch }) {
  const label = pra ? 'pratica' : bgt ? 'biglietto' : 'voucher'
  const [dataPra, setDataPra] = useState({})
  const [dataBgt, setDataBgt] = useState({})
  const [dataVou, setDataVou] = useState({})
  const { data: agency = {}, isSuccess: isSuccessAgency } = useCQuery('agency')
  if (!isSuccessAgency) return null

  const updateState = (type, data) => {
    type === 'pra' && setDataPra({ ...dataPra, ...data })
    type === 'bgt' && setDataBgt({ ...dataBgt, ...data })
    type === 'vou' && setDataVou({ ...dataVou, ...data })
  }
  const cleanState = () => {
    pra && setDataPra({})
    bgt && setDataBgt({})
    vou && setDataVou({})
  }

  const renderFind = () => (
    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          rich
          icon='lens'
          style={{ width: 250 }}
          label={`Cerca ${label}`}
          onClick={() => findDataATL()}
        />
        <Button
          rich
          icon='bin'
          style={{ width: 250, marginTop: 10 }}
          label='Pulisci ricerca'
          onClick={() => {
            cleanState()
          }}
        />
      </div>
    </div>
  )

  const renderSearchPra = () => (
    <div style={{ width: 800, height: 500 }}>
      <Card collapsable={false}>
        <SearchPra updateState={updateState} data={dataPra} />
      </Card>
      {renderFind()}
    </div>
  )

  const renderSearchBgt = () => (
    <div style={{ width: 800, height: 500 }}>
      <Card collapsable={false}>
        <SearchBgt updateState={updateState} data={dataBgt} />
      </Card>
      {renderFind()}
    </div>
  )

  const renderSearchVou = () => (
    <div style={{ width: 800, height: 500 }}>
      <Card collapsable={false}>
        <SearchVou updateState={updateState} data={dataVou} />
      </Card>
      {renderFind()}
    </div>
  )

  const checkPraData = (finalData) => {
    finalData.startDate1 = finalData.startDate1 ? moment(finalData.startDate1).format('YYYYMMDD') : null
    finalData.startDate2 = finalData.startDate2 ? moment(finalData.startDate2).format('YYYYMMDD') : null
    finalData.registrationDate1 = finalData.registrationDate1
      ? moment(finalData.registrationDate1).format('YYYYMMDD')
      : null
    finalData.registrationDate2 = finalData.registrationDate2
      ? moment(finalData.registrationDate2).format('YYYYMMDD')
      : null
    return finalData
  }

  const checkBgtData = (finalData) => {
    finalData.emissionDate1 = finalData.emissionDate1
      ? moment(finalData.emissionDate1).format('YYYYMMDD')
      : null
    finalData.emissionDate2 = finalData.emissionDate2
      ? moment(finalData.emissionDate2).format('YYYYMMDD')
      : null
    finalData.registrationDate1 = finalData.registrationDate1
      ? moment(finalData.registrationDate1).format('YYYYMMDD')
      : null
    finalData.registrationDate2 = finalData.registrationDate2
      ? moment(finalData.registrationDate2).format('YYYYMMDD')
      : null
    return finalData
  }

  const checkVouData = (finalData) => {
    finalData.dataIn1 = finalData.dataIn1 ? moment(finalData.dataIn1).format('YYYYMMDD') : null
    finalData.dataIn2 = finalData.dataIn2 ? moment(finalData.dataIn2).format('YYYYMMDD') : null
    finalData.regDate1 = finalData.regDate1 ? moment(finalData.regDate1).format('YYYYMMDD') : null
    finalData.regDate2 = finalData.regDate2 ? moment(finalData.regDate2).format('YYYYMMDD') : null
    return finalData
  }
  const findDataATL = () => {
    let finalData = pra ? { ...dataPra } : bgt ? { ...dataBgt } : { ...dataVou }
    const opUnitGlb = agency.opUnit
    const isArr = opUnitGlb && Array.isArray(opUnitGlb)
    const opUnit = opUnitGlb // se esiste opUnit
      ? opUnitGlb === 'none' // se esiste e è uguale a NONE allora vuoto
          ? []
          : isArr // se è unarray metto opUnit normale altrimenti se non lo è allora lo metto nella cella 0
            ? agency.opUnit
            : [agency.opUnit]
      : [] // se non esiste opUnit, metto sempre arr vuoto

    finalData.opUnit = opUnit
    if (pra) finalData = checkPraData(finalData)
    if (bgt) finalData = checkBgtData(finalData)
    if (vou) finalData = checkVouData(finalData)
    finalData.codSociety = agency.codSociety
    // chiamata con i campi di ricerca
    howToSearch(finalData)
  }

  return (
    <>
      {pra && renderSearchPra()}
      {bgt && renderSearchBgt()}
      {vou && renderSearchVou()}
    </>
  )
}

export default SearchAtlList
