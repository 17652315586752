import { FC, useCQuery } from '@Common/Services'
import { useState } from 'react'
import { Button, Flex, Text } from '@Common/Components'
import { RadioButton } from 'primereact/radiobutton'

export const UserNotification = ({ client, showModalNotification }) => {
  const [notificationType, setNotificationType] = useState({})
  const { data: agency } = useCQuery('agency')

  const onChangeRadio = (e) =>
    setNotificationType({ ...notificationType, ...e })

  const generateClient = async (notifications = {}) => {
    const userC = await FC.service('info').create({ type: 'generateNewClient', ...client, notifications })
    window.growl.show({
      severity: userC ? 'success' : 'error',
      summary: userC ? 'Utente creato correttamente' : 'Errore nella creazione, riprovare'
    })
    showModalNotification.current.hide()
  }

  return (
    <Flex as js>
      <Text style={{ width: '100%', textAlign: 'center' }} color='white' size={18} value={`Vuoi invitare il cliente ${client.name} ${client.lastName} a scaricare l'app? Seleziona le modalità di invio dell'invito o scegli di non inviare alcun messaggio`} />
      {(client._id && agency?.network !== 'gattinoni') &&
        <Flex row as js style={{ padding: 10 }}>
          <RadioButton
            style={{ marginRight: 20 }} name='sms'
            onChange={(e) => { onChangeRadio({ [e.target.name]: e.target.checked }) }}
            checked={notificationType.sms}
          />
          <Text value={`Invito tramite SMS (al numero ${client._id})`} color='white' />
        </Flex>}
      {client.email &&
        <Flex row as js style={{ padding: 10 }}>
          <RadioButton
            style={{ marginRight: 20 }} name='email' checked={notificationType.email}
            onChange={(e) => { onChangeRadio({ [e.target.name]: e.target.checked }) }}
          />
          <Text value={`Invito tramite E-mail (alla email ${client.email})`} color='white' />
        </Flex>}
      <Flex fw row>
        <Button label='Non mandare invito' style={{ margin: 10 }} onClick={() => generateClient()} />
        <Button label='Manda invito al cliente' style={{ margin: 10 }} onClick={() => generateClient(notificationType)} />
      </Flex>
    </Flex>
  )
}
