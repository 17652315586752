import React from 'react'
import { Flex, Icon, Text } from '@Common/Components'

const MapTypeToIcon = (type) => {
  if (!type) return null
  switch (type) {
    case 'document':
      return 'documents'
    case 'position':
      return 'pointer'
    case 'website':
      return 'site'
    case 'flight':
      return 'plane'
    case 'hotel':
      return 'hotel'
    case 'event':
      return 'ticket'
    case 'car':
      return 'car'
    case 'train':
      return 'ticket'
    case 'share':
      return 'share'
    case 'phone':
      return 'telephone'
    case 'cruise':
      return 'cruise'
    default:
      return null
  }
}

const renderDetail = ({ time, text, icon }, notVip, ind) =>
  text
    ? (
      <Flex row style={{ marginBottom: 2 }} fw as key={ind}>
        <Text
          style={{
            marginRight: 3
          }}
          size={16}
          bold
          value={time}
        />
        <Flex fw as>
          <Text
            value={text}
            size={16}
          />
        </Flex>
      </Flex>
      )
    : null

const ItemDescription = ({ event: item }) => {
  return (
    <Flex style={{ flex: 1 }} as row>
      <Flex style={{ width: 80, display: 'flex', flexDirection: 'row', paddingLeft: 15 }} as>
        <Flex style={{ display: 'flex', flexDirection: 'row', marginLeft: 30 }} as>
          <Flex style={{ height: '100%', justifyContent: 'center', marginLeft: -54, marginRight: 35 }}>
            <div style={{ borderRadius: 14, width: 14, height: 14, border: '2px solid black', marginTop: 15, backgroundColor: 'rgba(231, 231, 231, 1)' }} />
          </Flex>
          <div style={{ height: '100%' }}> <Icon name={MapTypeToIcon(item.type) || item?.details[0]?.icon || 'ticket'} size={40} /></div>
        </Flex>
      </Flex>

      <Flex style={{ flex: 1, marginLeft: 10 }} as>
        <Text
          style={{
            marginBottom: 2
          }}
          size={20}
          bold
          value={item.title}
        />
        {item.details &&
          item.details.length > 0 &&
          item.details.map((details, ind) => renderDetail(details, item.notVip, ind))}
      </Flex>
    </Flex>
  )
}

export default ItemDescription
