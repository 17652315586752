import React from 'react'
import { Tabs, TabHeader, Spinner } from '@Common/Components'
import { useCQuery } from '../Services/QueryCache'
import moment from 'moment'
import { getUrlParams, useHandleNavigate } from '@Common/Services'
import TabOffers from '../Components/TabOffers'

const OffersList = () => {
  const navigate = useHandleNavigate()

  const tab = getUrlParams('tab')
  const currentTab = parseInt(tab) || 0

  const { data: offers = [], isSuccess: isSuccessOffers } = useCQuery('offers')

  const publishedOffers = offers.filter((ele) => ele.status === 1 || ele.status === 'published')
  const draftedOffers = offers.filter((ele) => ele.status === 0)
  const expiredOffers = offers.map((ele) => {
    const diff = ele.deadline
      ? moment(ele.deadline).diff(moment())
      : moment(ele.deadline)
        .add(1, 'M')
        .diff(moment())
    const daysDiff = moment.duration(diff, 'milliseconds').asDays()
    if (ele.status === -1 || daysDiff < 0) {
      return ele
    }
    return null
  }).filter((item) => item !== null)

  const TABS = (published, drafted, expired) => [
    { icon: 'star', title: 'PUBBLICATE', content: <TabOffers offers={published} /> },
    { icon: 'edit-2', title: 'BOZZE SALVATE', content: <TabOffers offers={drafted} drafted /> },
    { icon: 'import', title: 'SCADUTE', id: 2, content: <TabOffers offers={expired} /> }
  ]

  if (!isSuccessOffers) return <Spinner />

  return (
    <Tabs
      onTabSelection={(e) => navigate('/offers/list?tab=' + e)}
      currentTab={currentTab}
    >
      {TABS(publishedOffers, draftedOffers, expiredOffers)?.filter(({ disabled }) => !disabled).map((tab, index) => (
        <TabHeader
          title={tab?.title}
          key={index}
          index={index}
          icon={tab?.icon}
        >
          {tab.content}
        </TabHeader>
      ))}
    </Tabs>
  )
}

export default OffersList
