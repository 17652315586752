import moment from 'moment'
import _ from 'lodash'
import {
  getHotel,
  getFlight,
  getTrain,
  getTransfer,
  getCruise,
  getService,
  getRental,
  getInsurance
} from './AppTours'
import state from '../../State'
import airports from './airports.json'

export const getTimelineList = (tour, language) => {
  moment.locale(langMap[language])
  let events = []
  const tourPrefix = tour._id.split('@')[1].split('-')[0]
  if (tour.type === 'APP' || tour.type === 'DP' || tour.type === 'TC') {
    tour.hotels.forEach((hotel) =>
      events.push({
        ...getHotel(hotel),
        date: getDayObj(moment(hotel.startDate))
      })
    )
    tour.flights.forEach((flight) =>
      events.push({
        ...getFlight(flight),
        date: getDayObj(moment(flight.startDate))
      })
    )
    tour.trains &&
      tour.trains.forEach((train) =>
        events.push({
          ...getTrain(train),
          date: getDayObj(moment(train.startDate))
        })
      )
    if (tour.type === 'APP' || tour.type === 'TC') {
      tour.transfers.forEach((transfer) => events.push(...getTransfer(transfer)))
    }
    if (tour.type === 'DP' || tour.type === 'TC') tour.rental.forEach((rent) => events.push(...getRental(rent)))
    tour.type === 'APP' && tour.cruises.forEach((cruise) => events.push(...getCruise(cruise)))
    if (tour.type === 'APP') {
      tour.services.forEach((service) =>
        events.push({
          ...getService(service),
          date: getDayObj(moment(service.startDate))
        })
      )
    }
    if (tour.type === 'DP') {
      if (tourPrefix === '74W') {
        events.push(getHotelInfoDP(tour))
        events.push(getNotVipServices(tour.services, getDateForDP(tour), tour))
      }
      if (tourPrefix === '74K') {
        tour.services.forEach((service) => {
          if (service.startDate) {
            events.push({
              ...getService(service),
              date: getDayObj(moment(service.startDate))
            })
          } else {
            events.push({ ...getService(service), date: getDateForDP(tour) })
          }
        })
      }
    }
    if (tour.type === 'TC') {
      tour.insurances &&
        tour.insurances.forEach((insurance) =>
          events.push({
            ...getInsurance(insurance),
            date: getDayObj(moment(insurance.startDate))
          })
        )
    }
  } else {
    // Handling Flights
    if (hasFlights(tour)) {
      const filteredRoutes = filterRoutes(tour)
      for (let i = 0; i < filteredRoutes.length; i++) {
        if (tour.type === 'BGT-A') {
          events.push(getTicketFlyObj(filteredRoutes[i]))
        } else if (tour.type === 'BGT-V') {
          events.push(getTicketRouteObj(filteredRoutes[i]))
        } else {
          events.push(getFlightObj(filteredRoutes[i], tour.type))
        } // al posto di true tour.notVIP
      }
    } else if (tour.type === 'BGT-A') {
      events.push(getTicketGenericObj(tour))
    } else if (tour.type === 'BGT-V') {
      events.push(getTicketGenericObj(tour))
    }

    // Handling Products
    if (tour && tour.products) {
      for (let i = 0; i < tour.products.length; i++) {
        switch (tour.products[i].serviceType) {
          case 'HOT':
            events.push(getHotelObj(tour.products[i]))
            break
          case 'TRS':
            events.push(...getTransferObj(tour.products[i]))
            break
          case 'TRE':
            events.push(...getTrainObj(tour.products[i]))
            break
          case 'NOL': // Noleggio
            events.push(getNolObject(tour.products[i]))
            break
          default:
            if (tour.type === 'VOU') {
              events.push(...getVoucherObj(tour.products[i]))
            } else {
              events.push(...getGenericProdObj(tour.products[i]))
            }
        }
      }
    }
  }

  events = _.sortBy(events, 'date.timestamp')
  let timeline = []
  let currentDay = 0
  for (let i = 0; i < events.length; i++) {
    if (currentDay !== events[i].date.date) {
      timeline.push({
        date: events[i].date,
        events: [],
        singleTicket: tour.type.includes('BGT')
      })
      currentDay = events[i].date.date
    }
    timeline[timeline.length - 1].events.push(events[i])
  }

  if (timeline.length === 0) {
    if (tour.type === 'APP') {
      timeline.push({
        date: getDayObj(moment(tour.startDate)),
        events: [
          {
            date: getDayObj(moment(tour.startDate)),
            title: 'Questa pratica contiene solo documenti',
            actions: [
              {
                type: 'document',
                payload: 'Documents'
              }
            ]
          }
        ]
      })
      return timeline
    }
    // Caso di una pratica non VIP
    events.push(getNotVipServices(tour.services, getDayObj(tour.startDate)))
    timeline.push({ date: getDayObj(tour.startDate), events: events })
  }
  if (tour.type === 'DP') {
    timeline = timeline.map((day) => ({
      ...day,
      events: day.events.sort((a, b) => b.order < a.order)
    }))
  }

  return timeline
}
export const getCorrectPlace = (pro) => {
  const stringa =
    pro.supplier.supplierAddress.length > 0 && pro.supplier.supplierCity.length > 0
      ? `${pro.supplier.supplierAddress}, ${pro.fornitore.supplierCity}`
      : pro.supplier.supplierAddress.length > 0 && !(pro.supplier.supplierCity.length > 0)
        ? pro.supplier.supplierAddress
        : !(pro.supplier.supplierAddress.length > 0) && pro.supplier.supplierCity.length > 0
            ? pro.supplier.supplierCity
            : ''
  return stringa
}

export const getVoucherName = (vou) => {
  if (vou.products.length === 1) {
    return mapTypeVoucherToIcon(vou.products[0]).title
  } else return 'Voucher'
}

export const getNotVipServices = (services, date) => ({
  notVip: true,
  order: 7,
  date: date,
  title: services ? 'Servizi Richiesti' : 'No services',
  details:
    services &&
    services.map((service) => ({
      text: service.description
      //  icon: service.type || 'business'
    }))
})

export const getHotelObj = (pro) => {
  const hotelName = pro.hotelBusinessName ? pro.hotelBusinessName : 'hotel'
  return {
    date: getDayObj(pro.startDate),
    type: 'hotel',
    title: `Hotel ${toCamelCase(hotelName.toLowerCase().replace('hotel', ''))}`,
    details: [
      {
        text: `${pro.nights} Notti`
      },
      {
        text: getCorrectPlace(pro)
      }
    ],
    obj: pro
  }
}

export const getTransferObj = (pro) => {
  const transfer = []

  transfer.push({
    date: getDayObj(pro.startDate),
    type: 'car',
    title: 'Transfer',
    details: [
      {
        text: pro.productDesc || 'Descrizione non disponibile'
      },
      {
        text: pro.description || 'Informazioni non disponibili'
      }
    ],
    obj: pro
  })
  if (pro.startDate !== pro.endDate) {
    transfer.push({
      date: getDayObj(pro.endDate),
      type: 'car',
      title: 'Transfer',
      details: [
        {
          text: pro.productDesc || 'Descrizione non disponibile'
        },
        {
          text: pro.description || 'Informazioni non disponibili'
        }
      ],
      obj: pro
    })
  }
  return transfer
}

export const getTrainObj = (pro) => {
  const trains = []
  trains.push({
    date: getDayObj(pro.startDate),
    type: 'train',
    title: pro.productDesc,
    obj: pro
  })
  return trains
}

export const getVoucherObj = (pro) => {
  const tourSelected = state.tourSelected.state
  const voucherProd = []
  voucherProd.push({
    date: getDayObj(pro.startDate || tourSelected.startDate),
    type: mapTypeVoucherToIcon(pro).name,
    title: mapTypeVoucherToIcon(pro).title,
    details: [
      {
        text: pro.description
      },
      {
        text:
          pro.type === 'H'
            ? `Num. notti: ${pro.nights}`
            : pro.type === 'S'
              ? pro.description && pro.description
              : ''
      }
    ],
    obj: pro
  })
  return voucherProd
}

export const getGenericProdObj = (pro) => {
  const genericProd = []
  genericProd.push({
    date: getDayObj(pro.startDate),
    type: pro.serviceType,
    title: pro.description,
    details: [
      {
        text: pro.productDesc
      }
    ],
    obj: pro
  })
  return genericProd
}

export const getTicketGenericObj = (tour) => ({
  date: getDayObj(tour.startTrip),
  type: 'GEN',
  title: 'Biglietto',
  details: [
    {
      text: tour.itinerary ? `Itinerario: ${tour.itinerary}` : ''
    }
  ]
})

export const getTicketRouteObj = (route) => ({
  date: getDayObj(route.departureDate),
  title: `Viaggio ${route.departure} - ${route.arrival}`,
  details: [
    {
      text: `Da ${getAirport(route.departure)}`,
      time: route.departureTime
    },
    {
      text: `A ${getAirport(route.arrival)}`,
      time: route.arrivalTime
    }
  ]
})

export const getTicketFlyObj = (route) => {
  const dep =
    route.departure.toUpperCase() === getAirport(route.departure)
      ? route.departure
      : getAirport(route.departure)
  const ar =
    route.arrival.toUpperCase() === getAirport(route.arrival) ? route.arrival : getAirport(route.arrival)
  return {
    date: getDayObj(route.departureDate),
    type: 'flight',
    title: `Volo ${route.departureCode || route.departure} - ${route.arrivalCode || route.arrival}`,
    details: [
      {
        text: `Volo da ${dep}`,
        time: route.departureTime
      },
      {
        text: `Arrivo a ${ar}`,
        time: route.arrivalTime
      },
      {
        text: `Volo numero: ${route.flightNumber}`
      }
    ]
  }
}

export const getFlightObj = (volo, type) => {
  const isVip = type === 'VIP' // Capire quali altri potrebbero essere.
  const datiVolo = Array.isArray(volo) ? (volo.length > 1 ? volo[1] : {}) : volo
  return {
    date: getDayObj(datiVolo.departureDate),
    type: 'flight',
    title: `Volo ${datiVolo.departureCode} - ${datiVolo.routeCode}`,
    details: [
      {
        text: `Volo da ${getAirport(datiVolo.departureCode)}`,
        time: datiVolo.departureTime
      },
      {
        text: `Arrivo a ${getAirport(isVip ? datiVolo.routeCode : datiVolo.routeCode)}`,
        time: datiVolo.arrivalTime
      },
      {
        text: `Volo numero ${datiVolo.flightNumber}`
      }
    ]
  }
}

export const getNolObject = (transfer) => ({
  date: getDayObj(transfer.startDate),
  type: 'car',
  title: transfer.description,
  details: [
    {
      text: transfer.supplier.supplierBusinessName
    }
  ],
  obj: transfer
})

export const getDayObj = (dateSt) => {
  const date = dateSt === undefined || dateSt === '' ? moment() : moment(dateSt, 'DD/MM/YYYY')
  const dateObj = date.toObject()
  dateObj.longMonth = toCamelCase(date.format('MMMM'))
  dateObj.shortMonth = toCamelCase(date.format('MMM'))
  dateObj.shortYear = date.format('YY')
  dateObj.dayName = toCamelCase(date.format('dddd'))
  dateObj.dayShortName = toCamelCase(date.format('ddd'))
  dateObj.timestamp = date.unix()
  dateObj.moment = date
  return dateObj
}

const langMap = {
  french: 'fr',
  german: 'de',
  english: 'en-gb',
  italian: 'it',
  spanish: 'es',
  dutch: 'nl'
}

export const getAirport = (iata) => {
  if (!iata) return ''
  const airport = airports.find((airp) => airp.code === iata.toUpperCase())
  if (!airport) return iata.toUpperCase()
  return airport.name
}

export const toCamelCase = (string) => _.startCase(_.toLower(string))

export const hasFlights = (tour) => {
  if (tour.type === 'BGT-A' && tour.routes.length > 0) return true
  const routesAvailable = tour && tour.routes && tour.routes.length > 0
  if (routesAvailable && Array.isArray(tour.routes[0])) return false // Workaround pratica strana FIMA
  return routesAvailable
}

export const mapTypeVoucherToIcon = (pro) => {
  let icon = {}
  switch (pro.type) {
    case 'H':
      icon = {
        name: 'hotel',
        title: pro.hotelBusinessName || 'Hotel'
      }
      return icon
    case 'N':
      icon = {
        name: 'car',
        title: 'Noleggio auto'
      }
      return icon
    case 'D':
      icon = {
        name: 'business',
        title: 'Diritti di Agenzia'
      }
      return icon
    case 'S':
      icon = {
        name: 'hotel',
        title: 'Supplementi Hotel'
      }
      return icon
    default:
      break
  }
}

export const getDateForDP = (tour) => {
  const productsDate = []
  tour.flights &&
    tour.flights.forEach((flight) => flight.startDate && productsDate.push(moment(flight.startDate)))
  tour.trains && tour.trains.forEach((train) => train.startDate && productsDate.push(moment(train.startDate)))
  tour.hotels && tour.hotels.forEach((hotel) => hotel.startDate && productsDate.push(moment(hotel.startDate)))
  tour.rental && tour.rental.forEach((rent) => rent.startDate && productsDate.push(moment(rent.startDate)))
  tour.services &&
    tour.services.forEach((service) => service.startDate && productsDate.push(moment(service.startDate)))
  tour.cruises &&
    tour.cruises.forEach((cruise) => cruise.startDate && productsDate.push(moment(cruise.startDate)))
  let sorted = []
  if (productsDate.length > 0) {
    sorted = productsDate.sort((a, b) => b < a)
  }
  let first, last
  if (sorted.length === 0) {
    first = moment()
    last = moment()
  } else {
    first = sorted[0]
    last = sorted[sorted.length - 1]
  }
  const dateStamp =
    moment(first).diff(moment(), 'days') > 0 ? first : moment().diff(last, 'days') < 0 ? moment() : last

  return getDayObj(moment(dateStamp).add(1, 's'))
}

export const filterRoutes = (tour) => {
  if (tour.type === 'VIP') return tour.routes
  const filtered = tour.routes.filter((route) => route.departure && route.arrival && route.departureDate)
  return filtered
}

export const getDescriptionForDp = (tour) => {
  if (tour._id.split('@')[1].split('-')[0] === '74W' && tour.destination) return tour.destination
  if (!toCamelCase(tour.description).includes('Pratica Del') && tour.description !== '') {
    return tour.description
  }

  if (tour.hotels.length > 0) return tour.hotels[0].name

  if (tour.flights.length > 0) {
    // Ottengo le info x capire il tipo di volo: A, A/R, Scalo.
    const routes = tour.flights.map((flight) => flight.description)
    const flightType = getFlightType(routes)
    return getFlightsDescription(tour, flightType)
  }

  if (tour.rental.length > 0) {
    if (toCamelCase(tour.rental[0].pickUpPoint) === toCamelCase(tour.rental[0].dropOffPoint)) {
      return `Autonoleggio: ${tour.rental[0].pickUpPoint}`
    }
    return `Autonoleggio: ${tour.rental[0].pickUpPoint} - ${tour.rental[0].dropOffPoint}`
  }
  const date = getDateForDP(tour)
  return `Pratica Del ${moment(date).format('DD-MM-YYYY')}`
}

// Determino il tipo effettivo di volo. A, A/R, Scalo.
export const getFlightType = (routes) => {
  if (routes.length === 1) return { type: 0, text: 'Volo solo A' }

  if (routes.length === 2) {
    if ((routes[0] === '1' || routes[0] === 'Andata') && (routes[1] === '2' || routes[1] === 'Ritorno')) {
      return { type: 1, text: 'Volo A/R' }
    }

    if (routes[0] === 'ScaloAndata1' && routes[1] === 'ScaloAndata2') {
      return { type: 2, text: 'Volo solo A con scalo' }
    }
  }

  if (routes.length === 3) {
    if (routes[0] === '1' && routes[1] === '2' && routes[2] === '3') {
      return { type: 3, text: 'Volo solo A con scalo' }
    }
  }

  if (routes.length === 4) {
    if (
      routes[0] === 'ScaloAndata1' &&
      routes[1] === 'ScaloAndata2' &&
      routes[2] === 'ScaloRitorno1' &&
      routes[3] === 'ScaloRitorno2'
    ) {
      return { type: 4, text: 'Volo A/R con scali' }
    }
  }
}

export const getFlightsDescription = (tour, flightType) => {
  if (flightType.type === 0 || flightType.type === 1) {
    return `Prenotazione volo ${toCamelCase(tour.flights[0].startAirport)} - ${toCamelCase(
      tour.flights[0].endAirport
    )}, ${flightType.text}`
  }
  if (flightType.type === 2 || flightType.type === 4) {
    return `Prenotazione volo ${toCamelCase(tour.flights[0].startAirport)} - ${toCamelCase(
      tour.flights[1].endAirport
    )}, ${flightType.text}`
  }
  if (flightType.type === 3) {
    return `Prenotazione volo ${toCamelCase(tour.flights[0].startAirport)} - ${toCamelCase(
      tour.flights[2].endAirport
    )}, ${flightType.text}`
  }
}

export const getHotelInfoDP = (tour) => ({
  notVip: true,
  order: 2,
  date: getDateForDP(tour),
  title: tour.treatment ? `Trattamento ${tour.treatment}` : 'Trattamento',
  details: [
    {
      text: tour.roomType && tour.roomType.toUpperCase() !== 'NO HOTEL' ? tour.roomType : ''
    }
  ]
})
