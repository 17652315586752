import React from 'react'

import { Card } from '@Common/Components'
import state from '../../../State'
import { Cruise } from './Elements'
import PlusButton from '../PlusButton'
import { updateService, deleteService } from '../../../Functions'

export default function CruiseForm () {
  const [cruiseForm] = state.cruiseForm.useState()

  if (!cruiseForm?.length) return null

  return (
    <Card
      key='cruise'
      title='CROCIERA'
      collapsable={cruiseForm.length > 0}
      style={{ marginBottom: '20px', width: '100%' }}
    >
      <PlusButton type='cruiseForm' />
      {cruiseForm.map((item, index) => (
        <Cruise
          key={index}
          index={index}
          item={item}
          onUpdate={updateService}
          onDelete={deleteService}
        />
      ))}
    </Card>
  )
}
