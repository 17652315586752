export const getHomeCards = (agency) => [
  {
    cardTitle: 'Messaggi',
    isVisible: (service) => (service && service[0] ? !!service[0].value : true),
    isEnabled: (license) => !!license.chat,
    buttons: [
      { title: 'Tutti i Messaggi', icon: 'chat2', target: '/messages/chat' },
      { title: 'Nuovo', icon: 'add', target: '/messages/new' }
    ],
    targetServices: '/newservices/chat'
  },
  {
    cardTitle: 'Offerte',
    isVisible: (service) => (service && service[1] ? !!service[1].value : true) && agency._id !== 'mydigitaltravelagency',
    isEnabled: (license, service) => !!license.offers,
    buttons: [
      { title: 'Tutte le Offerte', icon: 'star', target: '/offers/list' },
      { title: 'Bozze', icon: 'import', target: '/offers/list?tab=1' },
      { title: 'Nuovo', icon: 'add', target: '/offers/new' }
    ],
    targetServices: '/newservices/offers'
  },
  {
    cardTitle: 'Viaggi',
    isVisible: (service) => (service && service[2] ? !!service[2].value : true),
    isEnabled: (license, service) => !!license.app,
    buttons: [
      { title: 'Tutti i Viaggi', icon: 'star', target: '/tours/list?tab=0' },
      { title: 'Bozze', icon: 'import', target: '/tours/list?tab=1' },
      { title: 'Nuovo', icon: 'add', target: '/tours/new' }
    ],
    targetServices: '/newservices/tours'
  },
  {
    cardTitle: agency.hasWelfare ? 'Preventivi e Ordini Welfare' : 'Preventivi frontoffice ADV',
    isVisible: (service) => (service && service[3] ? !!service[3].value : true),
    isEnabled: (license) => !!license.quotes,
    buttons: [
      { title: 'Preventivi', icon: 'icon icon-estimate', target: '/quotes/list?tab=0' },
      { title: 'In scadenza', icon: 'expired', target: `/quotes/list?tab=${agency.hasWelfare ? 2 : 1}` },
      ...(agency.hideRequests ? [] : [{ title: 'Richieste in arrivo', icon: 'sheet-new', target: `/quotes/list?tab=${agency.hasWelfare ? 3 : 2}` }]),
      { title: 'Nuovo', icon: 'add', target: '/quotes/new' },
      ...(agency.hasWelfare ? [{ title: 'Ordini Welfare', icon: 'paperwork-2', target: '/quotes/list?tab=1' }] : [])
    ],
    targetServices: '/newservices/quotes'
  },
  {
    cardTitle: 'Atlante',
    isVisible: (service, hasAtl, hasApp) => hasAtl && hasApp,
    isEnabled: (license) => !!license.atl && !!license.app,
    buttons: [
      { title: 'Pratiche', icon: 'sincronize-siap', target: '/atlTour/list?tab=0' },
      { title: 'Biglietti', icon: 'ticket', target: '/atlTour/list?tab=1' },
      { title: 'Voucher', icon: 'paperwork-2', target: '/atlTour/list?tab=2' }
    ],
    targetServices: '/newservices/quotes'
  },
  {
    cardTitle: 'Anagrafica clienti',
    isVisible: (service) => (service && service[4] ? !!service[4].value : true),
    isEnabled: (license) => true,
    buttons: [
      { title: 'Tutti i Clienti', icon: 'account', target: '/clients/list' },
      { title: 'Nuovo', icon: 'add', target: '/clients/new' }
    ]
  }
]
