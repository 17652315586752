import React, { useEffect, useRef, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { Icon, Modal, Text } from '../'
import imagePdf from '../../../Apps/quotes/Resources/Images/pdfFile.png'
import imagePhoto from '../../../Apps/quotes/Resources/Images/picture.png'
import state from '@Common/State'
import { FC } from '@Common/Services'

const DIMENSION = 80

export const ReorderFiles = ({ files = [], onDelete, onReorder = false }) => {
  const [filesState, setFilesState] = useState(files || [])
  const [fileToDelete, setFileToDelete] = useState(null)

  const showDeleteAll = useRef(null)

  useEffect(() => setFilesState(files), [files])

  const renderActions = (file) => {
    return (
      <div
        style={styles.actionStyle}
        className='p-grid p-nogutter p-align-center p-justify-between'
        onClick={() => {
          setFileToDelete(file)
          showDeleteAll?.current?.show()
        }}
      >
        <Icon name='bin' size={12} style={{ cursor: 'pointer' }} />
      </div>
    )
  }

  const deleteElement = async () => {
    const copy = [...files]
    const updatedFiles = copy.filter((element) => element.uri !== fileToDelete.uri)

    // SE il file è caricato su AWS, per rimuoverlo metto status a 0.
    if (fileToDelete?.uri?.includes(state.config.state.awsBaseURL)) {
      try {
        await FC.client.service('documents').patch(fileToDelete?._id, { status: 0 })
      } catch { }
    }

    setFilesState(updatedFiles)
    setFileToDelete(null)
    // OnDelete è la funzione che ricevo dalle props. che si occupa di ad esempio de-sincronizzare il file da DB. Riceve come parametro l'elenco aggiornato dei file.
    if (onDelete) onDelete(updatedFiles)
  }

  const onClickFile = (file) => () => {
    if (!file?.uri) return
    window.open().document.write('<iframe src="' + file?.uri + '" frameBorder="0" style="border:0px; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;position:absolute" allowfullscreen></iframe>')
  }

  const onErrorImg = (file) => ({ currentTarget }) => {
    // Entra qui nel caso di file che non possono essere direttamente renderizzati a partire da b64 o url. (esempio .txt)
    currentTarget.onerror = null // prevents looping
    if (file?.refFile) currentTarget.src = file?.refFile?.type === 'application/pdf' ? imagePdf : imagePhoto
    else currentTarget.src = file?.uri?.split('.')[file?.uri?.split('.')?.length - 1] === 'pdf' ? imagePdf : imagePhoto
  }

  const setList = (orderedFiles) => {
    setFilesState(orderedFiles)
    onReorder && onReorder(orderedFiles)
  }

  if (!files) return null

  return (
    <>
      <ReactSortable
        style={{
          ...styles.containerStyle,
          display: 'flex',
          flexWrap: 'wrap',
          marginRight: 0,
          marginLeft: 0,
          marginTop: 0,
          justifyContent: 'flex-start'
        }}
        list={filesState}
        setList={setList}
        swap
      >
        {filesState.map((file = {}, i) => (
          <div key={i} style={styles.cardStyle}>

            <img
              // X i file .pdf faccio vedere l'immagine base x problemi di CORS.
              src={file?.uri?.split('.')[file?.uri?.split('.')?.length - 1] === 'pdf' ? imagePdf : file?.uri}
              width={DIMENSION * 1.2}
              height={DIMENSION}
              style={{ maxWidth: 86, minWidth: 80, borderRadius: 4, cursor: 'pointer' }}
              onError={onErrorImg(file)}
              onClick={onClickFile(file)}
            />

            <Text size={12} value={file?.refFile?.name || file?.title || ''} style={{ margin: 'auto', wordBreak: 'break-all' }} />
            {renderActions(file)}
          </div>
        ))}
      </ReactSortable>
      <Modal
        ref={showDeleteAll}
        header={'Si desidera cancellare l\'elemento?'}
        action={deleteElement}
      />
    </>
  )
}

const styles = {
  containerStyle: {
    marginTop: 10,
    height: DIMENSION + 200,
    display: 'grid'
  },
  cardStyle: {
    position: 'relative',
    marginRight: 10,
    width: DIMENSION * 1.2 + 4,
    height: DIMENSION + 4,
    backgroundColor: 'rgba(224, 224, 224, 1)',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 6,
    borderColor: 'rgba(0, 0, 0, 0)',
    display: 'flex',
    flexDirection: 'column'
  },
  actionStyle: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    minHeight: 18,
    paddingLeft: 5,
    paddingRight: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderBottomRightRadius: 4
  }
}
