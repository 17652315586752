import React from 'react'
import { useUnmount } from 'react-use'

import { Card, Flex } from '@Common/Components'
import state from '../../../State'
import { clearAll } from '../../../Functions'
import { invalidateQuery } from '../../../Services'
import Information from './Information'
import Actions from './Actions'

export default function Header () {
  const [tourActions, setTourActions] = state.tourActions.useState()

  useUnmount(() => {
    setTourActions({ ...tourActions, saved: true })
    state.tourData.setState({})
    invalidateQuery('tour')
    clearAll()
  })

  return (

    <Card
      style={{
        width: '100%',
        marginBottom: 20,
        backgroundColor: 'rgba(255,255,255,0.8)'
      }}
    >
      <Flex>
        <Information />
        <Actions />
      </Flex>

    </Card>

  )
}
