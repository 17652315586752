import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Carousel } from 'primereact/carousel'

import { Text, Icon, Spinner, Flex } from '@Common/Components'
/* import { Themes } from '../../../../Services' */

function TimelineVisionCarousel ({ tour = [] }) {
  const [loadingService, setLoadingService] = useState(true)
  const [service, setService] = useState([])

  const translatorIcon = (type) => {
    const config = {
      document: 'documents',
      position: 'pointer',
      website: 'site',
      flight: 'plane',
      hotel: 'hotel',
      event: 'ticket',
      car: 'car',
      train: 'ticket',
      share: 'share',
      phone: 'telephone',
      cruise: 'cruise',
      VEP: 'hotel',
      SOT: 'hotel',
      RIS: 'gourmet',
      CAT: 'gourmet',
      PUL: 'car',
      GUI: 'binoculars',
      ESC: 'culture',
      ASS: 'business',
      insurance: 'business',
      CHA: 'plane',
      TRA: 'cruise',
      ANI: 'emoticons'
    }
    return config[type] || null
  }

  useEffect(() => {
    setService(tour)
    setLoadingService(false)
  }, [tour])

  const renderDate = (date) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: 20,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Text
        upCase
        size={40}
        value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('DD')}
        style={{ marginRight: 10 }}
      />
      <Text
        size={40}
        upCase
        value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('MMMM')}
        style={{ marginRight: 10 }}
      />
      <Text
        size={40}
        value={moment(moment(date, 'DD/MM/YYYY').toDate()).format('YYYY')}
        style={{ marginRight: 10 }}
      />
    </div>
  )

  const itemTimeline = (item) => (
    <Flex fw style={{ minHeight: '100%' }}>
      <div
        style={{
          backgroundColor: 'rgba(255,255,255,0.6)',
          borderRadius: 20,
          padding: 20,
          boxShadow:
            '0px 4px 33px -40px rgba(100,100,100,0.2),0px 4px 10px -14px rgba(100,100,100,0.24), 0px 4px 13px -2px rgba(100,100,100,0.2)',
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        {item.date.moment && item.date.moment._i ? renderDate(item.date.moment) : ''}
        {itemEvent(item.events)}
      </div>
    </Flex>
  )
  const itemEvent = (events = []) => (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        paddingRight: 20,
        marginTop: 25
      }}
    >
      <div
        style={{
          width: '100%',
          marginLeft: 40
        }}
      >
        {events.map((elem, index) => (
          <div key={index}>
            <div
              style={{ display: 'flex', flexDirection: 'row', marginTop: -10, borderLeft: '4px solid white' }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 30 }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 55,
                    height: 55,
                    marginLeft: -30,
                    marginBottom: 40,
                    marginTop: 10,
                    backgroundColor: 'white',
                    border: '3px solid white',
                    borderRadius: 50
                  }}
                >
                  <Icon color={elem.value ? 'white' : '#32324e'} name={translatorIcon(elem.type)} size={40} />
                </div>
              </div>
              <div style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text
                    size={20}
                    bold
                    value={elem.title === 'Requested_Services' ? 'Servizi Richiesti' : 'Servizio: '}
                    style={{ marginRight: 5 }}
                  />
                  <Text
                    size={20}
                    value={
                      elem.title ? (elem.title === 'Requested_Services' ? '' : elem.title) : 'NESSUN TITOLO'
                    }
                  />
                </div>
                {itemDetails(elem?.details)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const itemDetails = (details) => (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
        <Text size={20} bold value='Dettagli: ' style={{ marginRight: 5 }} />
        <div>
          {details?.map((det, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
              {det.time ? <Text size={20} value={det.time} style={{ marginRight: 5 }} /> : null}
              {det.text ? <Text size={20} value={det.text} /> : null}
            </div>
          ))}
        </div>
      </div>
      {renderDivision()}
    </div>
  )

  const renderDivision = () => (
    <hr
      style={{
        height: 2,
        width: '100%',
        backgroundColor: 'white',
        border: 'none',
        marginBottom: 10,
        marginTop: 10
      }}
    />
  )
  const respondiveModule = [
    {
      breakpoint: '1550px',
      numVisible: 1,
      numScroll: 1
    }
  ]
  // RENDER //
  return loadingService
    ? (
      <Spinner />
      )
    : (
      <Carousel
        orientation='vertical'
        value={service}
        itemTemplate={itemTimeline}
        numVisible={1}
        numScroll={1}
        verticalViewPortHeight='420px'
        responsiveOptions={respondiveModule}
      />
      )
}

export default TimelineVisionCarousel
