import React from 'react'
import { Input, Text } from '@Common/Components'

const GRID_NORMAL = `
  'labelPratica labelPratica type type year year number number'
  'labelPartenza labelPartenza dataIn1 dataIn1 dataIn1 dataIn2 dataIn2 dataIn2'
  'labelReg labelReg regDate1 regDate1 regDate1 regDate2 regDate2 regDate2'
  `
function SearchVou({ updateState, data }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateAreas: GRID_NORMAL,
        gridGap: '20px 9px',
        width: '100%'
      }}
    >
      <Text
        value='Pratica:'
        style={{
          gridArea: 'labelPratica',
          marginRight: 10,
          marginTop: 4
        }}
      />
      <Input
        id='type'
        label='Tipo:'
        style={{ gridArea: 'type' }}
        onChange={(e) => updateState('vou', e)}
        value={data.type || ''}
      />
      <Input
        id='year'
        label='Anno:'
        keyfilter='int'
        style={{ gridArea: 'year' }}
        onChange={(e) => updateState('vou', e)}
        value={data.year || ''}
      />
      <Input
        id='number'
        label='Numero:'
        keyfilter='int'
        style={{ gridArea: 'number' }}
        onChange={(e) => updateState('vou', e)}
        value={data.number || ''}
      />

      {/* SECONDA PARTE */}
      <Text value='Data Partenza:' style={{ marginRight: 20, marginTop: 4, gridArea: 'labelPartenza' }} />
      <Input
        date
        id='dataIn1'
        label='Da:'
        style={{ gridArea: 'dataIn1' }}
        onChange={(e) => updateState('vou', e)}
        value={data.dataIn1 || ''}
      />
      <Input
        date
        id='dataIn2'
        label='A:'
        style={{ gridArea: 'dataIn2' }}
        onChange={(e) => updateState('vou', e)}
        value={data.dataIn2 || ''}
      />

      {/* TERZA PARTE */}
      <Text value='Registrazione:' style={{ marginRight: 10, marginTop: 4, gridArea: 'labelReg' }} />
      <Input
        date
        id='regDate1'
        label='Da:'
        style={{ gridArea: 'regDate1' }}
        onChange={(e) => updateState('vou', e)}
        value={data.regDate1 || ''}
      />
      <Input
        date
        id='regDate2'
        label='A:'
        style={{ gridArea: 'regDate2' }}
        onChange={(e) => updateState('vou', e)}
        value={data.regDate2 || ''}
      />
    </div>
  )
}

export { SearchVou }
